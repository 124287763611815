import React, { useRef, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import { StudentRegValidations } from '../Pages/FormValidation';
import { Form, Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const MySchoolPopup = () => {
    const { districts, setIsLoading, img_url, stateData, handleStateChange, showSchoolModal, setShowSchoolModal } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const recaptcha = useRef();
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const [verified, setVerified] = useState(false);
    const [school_Code, setSchool_Code] = useState({
        school_code: ""
    });
    const [show, setShow] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showRegModal, setShowRegModal] = useState(false);
    // const [showSchoolModal, setShowSchoolModal] = useState(false);
    const [showReadModal, setShowReadModal] = useState(false);

    const [err, setErr] = useState(null);
    const [schoolData, setSchoolData] = useState(null)
    const { api_url } = useGlobalContext();
    const [stuRegErrors, setStuRegErrors] = useState({})
    const handleChange = (event) => {
        event.preventDefault();
        setSchool_Code({ school_code: "" });
        inputRef2.current.style.display = 'none';
        setShow(false);
        inputRef.current.focus()
    }
    const [studentRegisterData, setStudentRegisterData] = useState({
        'type': 2,
        'school_code': school_Code.school_code,
        'first_name': "",
        'last_name': "",
        'fathers_name': "",
        'phone_no': "",
        'state': "",
        'district': "",
        'password': "",
        'email': "",
        'confirm_password': "",
    })
    const [otpData, setOtpData] = useState({
        "user_id": "",
        "otp": ""
    });

    const handleState = (e) => {
        setStudentRegisterData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        handleStateChange(e);
    }

    // Register Student
    const registerStudent = async (event) => {
        event.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        let errors = StudentRegValidations(studentRegisterData)
        setStuRegErrors(errors);
        if (Object.keys(errors).length === 0) {
            if (!captchaValue) {
                alert('Please verify the reCAPTCHA!')
            }
            else {
                setIsLoading(true);
                try {
                    const res = await axios.post(`${api_url}/registerStudent`, studentRegisterData)
                    if (res.data.success === 1) {
                        toast.success(res.data.message, {
                            autoClose: 1500,
                        });
                        setShowRegModal(false);
                        // setShowOtpModal(true);
                        setOtpData({ ...otpData, user_id: res.data.data.user_id });
                        const timer = setTimeout(() => {
                            navigate('/otpverification', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: location.pathname } });
                        }, 2000)
                        return () => clearTimeout(timer);
                    }
                    else if (res.data.message === 'phone_registered') {
                        toast.warning('Phone Registered, Login Here', {
                            autoClose: 1500,
                            theme: 'colored',
                            style: { color: '#000000' },
                        })
                        setShowRegModal(false);
                        const timer = setTimeout(() => {
                            navigate('/login', { state: { email_phone: res.data.data.phone_no } });
                        }, 2000)
                        return () => clearTimeout(timer);
                    }
                    else if (res.data.message === 'email_registered') {
                        toast.warning('Email Registered, Login Here', {
                            autoClose: 1500,
                            theme: 'colored',
                            style: { color: '#000000' },
                        })
                        setShowRegModal(false);
                        const timer = setTimeout(() => {
                            navigate('/login', { state: { email_phone: res.data.data.email } });
                        }, 2000)
                        return () => clearTimeout(timer);
                    }
                    else {
                        toast.error(res.data.message, {
                            autoClose: 1000,
                        })
                    }
                } catch (error) {
                    toast.error('Axios Error', {
                        autoClose: 1000,
                    })
                    console.log(err)
                } finally {
                    setIsLoading(false)
                }
            }

        }
    }


    // handleSelect
    const handleSelect = (schoolcode) => {
        if (sessionStorage.getItem('token')) {
            setShowSchoolModal(false)
            navigate('/myinformation', { state: { schoolcode: schoolcode } })
        }
        else {
            setShowSchoolModal(false);
            setShowRegModal(true);
        }
    }

    // recaptcha function
    function onClickCaptcha(value) {
        setVerified(!verified);
    };

    // Get School Information
    const getSchoolInfo = (event) => {
        event.preventDefault();
        setIsLoading(true)
        axios.post(`${api_url}/getSchoolInfo`, school_Code)
            .then((res) => {
                if (res.data.success === 1) {
                    setIsLoading(false)
                    console.log(res.data);
                    setSchoolData(res.data.data);
                    setShow(true);
                } else {
                    setIsLoading(false)
                    setErr(res.data.message);
                }
            })
            .catch((err) => {
                setIsLoading(false)
                console.log(err);
            });
    }


    // Handle Inputs
    const handleInputs = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setSchool_Code(prev => ({ ...prev, [key]: value }));
        setStudentRegisterData(prev => ({ ...prev, [key]: value }));
        setOtpData(prev => ({ ...prev, [key]: value }));
    }

    return (
        <>
            <section id="popup">
                {
                    sessionStorage.getItem('school_code') === null ?
                        <button id="myschool_btn" type="button" className="btn btn-pink" onClick={() => setShowSchoolModal(true)}>
                            My School<i className="bi bi-building ms-md-2"></i>
                        </button>
                        :
                        <Link to='/myschool' id="myschool_btn" className='btn btn-pink text-decoration-none text-white'><span>My School</span><i className="bi bi-building ms-md-2"></i></Link>
                }

                {/* <!-- Modal - 1 (My School Modal) --> */}
                <Modal size="sm" centered show={showSchoolModal} onHide={() => setShowSchoolModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>My School</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container d-flex justify-content-center align-items-center">
                            <div className="text-center">
                                <Form>
                                    <div id="school-code" className="mb-3">
                                        <div className="form-floating mb-3">
                                            <input ref={inputRef} type="text" disabled={show} className="form-control" id="school_code" name='school_code' value={school_Code.school_code} onChange={handleInputs} placeholder="" />
                                            <label htmlFor="floatingInput">Enter School Code Here</label>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <button className="btn btn-danger form-control px-4 validate" type="submit" onClick={getSchoolInfo} >
                                            OK
                                        </button>
                                        <div className='p-1'>Already have an Student Account <span onClick={() => setShowSchoolModal(false)}><Link className='text-decoration-none ' to="/login">Login</Link></span> here</div>

                                    </div>
                                    {
                                        show &&
                                            schoolData !== null ?
                                            <>
                                                <div ref={inputRef2} id="school-info" className="card">
                                                    <div className="card-header">
                                                        <h5 id="schoolname" className="text-danger text-center">{schoolData.school_name}</h5>
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            schoolData.school_banner !== null ?
                                                                <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school" className="img-fluid d-block mx-auto" />
                                                                :
                                                                <img src={require('../assets/images/school_banner_2.jpeg')} alt="school" className="img-fluid d-block mx-auto" />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    <button className="btn btn-danger w-100 mb-3" type="button" onClick={() => handleSelect(school_Code.school_code)}>Confirm School</button>
                                                    <button className="btn btn-outline-pink w-100" onClick={(e) => handleChange(e)} type='button'>Change</button>
                                                </div>
                                            </>
                                            :
                                            <h3 id="schoolname" className="text-danger text-center">{err && err}</h3>
                                    }
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* <!-- Modal - 2 (Register model)--> */}
                <Modal backdrop="static" show={showRegModal} onHide={() => setShowRegModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>Register As Student (School Code : {studentRegisterData.school_code})</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form action="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" onChange={handleInputs} name='first_name' value={studentRegisterData.first_name} id="name" placeholder="name" />
                                        <label htmlFor="name">First Name <span className="text-danger">*</span></label>
                                        <div className="text-danger">{stuRegErrors.first_name}</div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" onChange={handleInputs} name='last_name' value={studentRegisterData.last_name} id="name" placeholder="name" />
                                        <label htmlFor="name">Last Name <span className="text-danger"></span></label>
                                        <div className="text-danger">{stuRegErrors.last_name}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating mb-3">
                                        <input type="tel" className="form-control" onChange={handleInputs} maxLength={10} name='phone_no' value={studentRegisterData.phone_no} id="phone" placeholder="phone" />
                                        <label htmlFor="phone"><span>Phone Number for otp verification</span><span className="text-danger">*</span></label>
                                        <div className="text-danger">{stuRegErrors.phone_no}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" onChange={handleInputs} name='fathers_name' value={studentRegisterData.fathers_name} id="father" placeholder="email" />
                                        <label htmlFor="email">Father's Name <span className="text-danger">*</span></label>
                                        <div className="text-danger">{stuRegErrors.fathers_name}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" onChange={handleInputs} name='email' value={studentRegisterData.email} id="email" placeholder="email" />
                                        <label htmlFor="email">Email<span className="text-danger"></span></label>
                                        <div className="text-danger">{stuRegErrors.email}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" onChange={handleInputs} name='password' value={studentRegisterData.password} id="email" placeholder="email" />
                                        <label htmlFor="email">Create a password<span className="text-danger">*</span></label>
                                        <div className="text-danger">{stuRegErrors.password}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" onChange={handleInputs} name='confirm_password' value={studentRegisterData.confirm_password} id="email" placeholder="email" />
                                        <label htmlFor="email">Confirm your password<span className="text-danger">*</span></label>
                                        <div className="text-danger">{stuRegErrors.confirm_password}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <select className="form-select py-3 mb-3" onChange={(e) => handleState(e)} name='state' value={studentRegisterData.state} aria-label="Default select example">
                                        <option value="Select State">--Select State--</option>
                                        {
                                            stateData.map((state, i) => {
                                                return (
                                                    <option key={i} value={state.state_name}>{state.state_name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="text-danger mb-3">{stuRegErrors.state}</div>
                                </div>
                                <div className="col-md-6">
                                    <select className="form-select py-3" onChange={handleInputs} name='district' value={studentRegisterData.district} aria-label="Default select example">
                                        <option >--Select District--</option>
                                        {
                                            districts.map((district, i) => {
                                                return (
                                                    <option key={i} value={district}>{district}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <div className="text-danger mb-3">{stuRegErrors.district}</div>
                                </div>
                            </div>
                        </form>
                        <div className="my-3 d-flex justify-content-center">
                            {/* <ReCAPTCHA
                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                onChange={onClickCaptcha} 
                            /> */}
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" checked type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                I agree to accept the terms and conditions
                                <span className='cursor-pointer text-decoration-none text-danger' onClick={() => setShowReadModal(true)}> READ.</span>
                            </label>
                        </div>
                        <button className="btn btn-pink mt-3 w-100" onClick={(e) => registerStudent(e)} >Register</button>
                    </Modal.Body>
                </Modal>

                {/* <!-- Modal - 3 (Otp modal)--> */}
                <Modal backdrop="static" size="sm" autoFocus={false} centered show={showOtpModal} onHide={() => setShowOtpModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>Enter OTP for Verification</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="form-floating mb-3">
                                <input type="text" autoFocus className="form-control" onChange={handleInputs} name='otp' value={otpData.otp} id="register_otp" placeholder="" />
                                <label htmlFor="register_otp">Enter OTP<span className="text-danger">*</span></label>
                            </div>
                            <button type="button" className="btn btn-pink w-100" >Verify OTP</button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* <!-- Modal - 4 (Read more) --> */}
                <Modal size="lg" autoFocus={false} centered show={showReadModal} onHide={() => setShowReadModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>Terms and Condition</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <p style={{ textAlign: 'left' }}>
                                All information, products and services displayed on the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u>
                                website constitute an invitation to offer. Your order for purchase constitutes your
                                offer which shall be subject to the terms and conditions as listed below.<br />
                                <b>V4 MASTERS e-vyapari</b> has the right to accept or reject your offer without assigning any
                                reason thereof.<br /><br />
                                In order to use the <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b>website belonging to V4 MASTERS e-
                                vyapari., you have accepted the following terms and conditions given below.<br />
                                V4 MASTERS e-vyapari reserves the right to add, delete, alter or modify these terms and
                                conditions at any time.<br /><br />
                                You are therefore advised to read carefully these terms and conditions every time you use
                                the <u><b><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com</a></b></u> website.<br /><br />
                                <b>1.</b> We have made every effort to display the color of our products that appear on the site as
                                accurately as possible. However, as the actual colors you see will depend on your monitor,
                                we cannot guarantee that your monitor's display of color will be accurate.<br /><br />
                                <b>2.</b> The User certifies that he/she is at least 18 (eighteen) years of age <b>OR</b> has the consent of
                                a parent or legal guardian.<br /><br />
                                <b>3. V4 MASTERS e-vyapari</b>  will not be responsible for any damage suffered by users from
                                use of the services on evyapari.com. This without limitation includes loss of
                                revenue or data resulting from delays, non-deliveries, missed deliveries, or service
                                interruptions as may occur because of any act / omission of the vendor. This disclaimer of
                                liability also applies to any damages or injury caused by any failure of performance,
                                negligence, defect, deletion, error, omission, interruption, delay in operation or
                                transmission, computer virus, communication line failure, theft or destruction
                                or unauthorized access to, alteration of, or use of record, whether for breach of contract,
                                tortuous behavior, or under any other cause of action.<br /><br />
                                4. <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> will take "Full Responsibility" of your Order, if the Order has
                                been shipped to any of the major cities, where our sellers use “Courier Companies" (e.g.
                                Trackon, Fed Ex, Delhivery, First Flight, India post or hand to hand pick up delivery) for
                                your order.<br /><br />
                                5. The prices and availability of products are subject to change without prior notice at the
                                sole discretion of <b>V4 MASTERS e-vyapari </b>or the Seller.<br /><br />
                                6. Request for cancellations of orders once placed on www.evyapari.com shall not
                                be entertained.<br /><br />
                                7.  <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a>  reserves the right to refuse or cancel any order placed for a
                                product that is listed at an incorrect price or for any other reason. This shall be regardless.<br /><br />
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            </section >

            <ToastContainer />
        </>
    )
}

export default MySchoolPopup