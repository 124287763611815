import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AddressValidation } from './FormValidation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';
import Loading from '../Components/Loading';
import Copyright from '../Components/Copyright';

const AddAddress = () => {
  const navigate = useNavigate();
  const { isLoading, setIsLoading, onlyNumbers, api_url, handleStateChange, stateData, districts } = useGlobalContext();
  const [addressErrors, setaddressErrors] = useState({});
  const [addressData, setAddressData] = useState({
    "user_id": sessionStorage.getItem('user_id'),
    "address_type": 1,
    "name": "",
    "phone_no": "",
    "alternate_phone": "",
    "village": "",
    "city": "",
    "state": "",
    "district": "",
    "post_office": "",
    "pincode": "",
    "address": "",
  });
  const handleInputs = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setAddressData(prev => ({ ...prev, [key]: value }));
  }

  const handleState = (e) => {
    setAddressData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    handleStateChange(e);
  }

  //Add Address
  const submitHandler = (event) => {
    event.preventDefault();
    let errors = AddressValidation(addressData)
    setaddressErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true)
      axios.post(`${api_url}/addAddress`, addressData,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
        .then(res => {
          setIsLoading(false)
          toast.success(res.data.message, {
            autoClose: 1500,
            theme: 'colored'
          });
          const timer = setTimeout(() => {
            navigate(-1);
          }, 2000);
          return () => clearTimeout(timer);
        })
        .catch(err => {
          toast.error('Something Wrong Happened', {
            autoClose: 1500,
          });
          setIsLoading(false);
        });
    }
  }
  return (
    <>
      {isLoading ? <Loading /> : null}
      <Header />
      <main id="main">
        <section id="new_address">
          <div className="container">
            <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i class="bi bi-chevron-right f-sm"></i> Add Address</span>
            </div>
            <div className="py-lg-5 pb-5">
              <div className="row">
                <div className="col-lg-5">
                  <div className="h-100 d-flex align-items-center">
                    <img src={require("../assets/images/address1.jpg")} className="d-block img-fluid" alt="" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="border f-shadow">
                    <div className="mx-auto">
                      <p className="h4 p-3 bg-pink text-white m-0">Add Your New Address</p>
                    </div>
                    <form action="" className="mx-auto p-4">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='name' onChange={handleInputs} value={addressData.name} id="username" placeholder="username" />
                            <label htmlFor="username">Name<span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.name}</div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='phone_no' max={10} onKeyDown={(e) => onlyNumbers(e)} onChange={handleInputs} value={addressData.phone_no} id="phone" placeholder="phone" />
                            <label htmlFor="phone">Phone Number<span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.phone_no}</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input type="tel" className="form-control" name='alternate_phone' max={10} onKeyDown={(e) => onlyNumbers(e)} onChange={handleInputs} value={addressData.alternate_phone} id="phone2" placeholder="phone" />
                            <label htmlFor="phone2">Alternate Phone Number<span className="text-danger"></span></label>
                            <div className='text-danger s-font2'>{addressErrors.alternate_phone && addressErrors.alternate_phone}</div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <select className="form-select py-3" onChange={(e) => handleState(e)} name='state' value={addressData.state} aria-label="Default select example">
                              <option value="Select State">--Select State--</option>
                              {
                                stateData.map((state, i) => {
                                  return (
                                    <option key={i} value={state.state_name}>{state.state_name}</option>
                                  )
                                })
                              }
                            </select>
                            <div className='text-danger s-font2'>{addressErrors.state}</div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="mb-3">
                            <select name='district' value={addressData.district} onChange={handleInputs} className="form-select py-3" aria-label="Default select example">
                              <option >--Select District--</option>
                              {
                                districts.map((district, i) => {
                                  return (
                                    <option key={i} value={district}>{district}</option>
                                  )
                                })
                              }
                            </select>
                            <div className='text-danger s-font2'>{addressErrors.district}</div>
                          </div>
                        </div>


                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='post_office' onChange={handleInputs} value={addressData.post_office} id="postoffice" placeholder="postoffice" />
                            <label htmlFor="postoffice">Post Office <span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.post_office}</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='address' onChange={handleInputs} value={addressData.address} id="landmark" placeholder="landmark" />
                            <label htmlFor="landmark">Address(Land Mark/House no./Building  name) <span className="text-danger"></span></label>
                            {/* <div className='text-danger s-font2'>{addressErrors.address}</div> */}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='village' onChange={handleInputs} value={addressData.village} id="villa-no" placeholder="villa-no" />
                            <label htmlFor="villa-no">Village/Ward No. <span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.village}</div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-floating mb-3">
                            <input type="text" className="form-control" name='city' onChange={handleInputs} value={addressData.city} id="city" placeholder="city" />
                            <label htmlFor="city">City <span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.city}</div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating mb-4">
                            <input type="text" className="form-control" name='pincode' onChange={handleInputs} value={addressData.pincode} id="pincode" placeholder="pincode" />
                            <label htmlFor="pincode">Pincode <span className="text-danger">*</span></label>
                            <div className='text-danger s-font2'>{addressErrors.pincode}</div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <button onClick={() => navigate(-1)} className="btn btn-outline-pink px-3 px-sm-4">Go Back</button>
                        <button onClick={(e) => submitHandler(e)} className="btn btn-pink px-4 px-sm-5">Save</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Copyright />
      <ToastContainer />
    </>
  )
}
export default AddAddress