import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginValidation } from './FormValidation';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../Components/Header';
import Loading from '../Components/Loading';
import Copyright from '../Components/Copyright';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
    const { isLoading, setIsLoading, api_url, userId } = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [loginErrors, setLoginErrors] = useState({});
    const [loginData, setLoginData] = useState({
        "user_id": userId,
        "email": location.state?.email_phone,
        "password": ""
    });
    const [passShow, setPassShow] = useState(true);
    const [type, setType] = useState('password')
    const recaptcha = useRef(); 

    const togglePassword = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
        setPassShow(!passShow)
    };

    const [showOtp, setShowOtp] = useState(false);
    var phoneRegex = /^\d{10}$/;
    var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/
    var passwordRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");

    const changeHandler = (e) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (emailPhoneRegex.test(loginData.email)) {
            setLoginErrors({ ...loginErrors, email: '' })
        }
    }, [loginData.email]);

    useEffect(() => {
        if (passwordRegex.test(loginData.password) && loginData.password.length >= 8) {
            setLoginErrors({ ...loginErrors, password: '' })
        }
    }, [loginData.password]);

    useEffect(() => {
        if (phoneRegex.test(loginData.email)) {
            setShowOtp(true)
        } else {
            setShowOtp(false)
        }
    }, [loginData.email])


    //handleOtpLogin
    const handleOtpLogin = async (e) => {
        e.preventDefault();

        const data = {
            "user_id": userId,
            "phone_no": loginData.email,
            "otp_type": "login"
        }
        setIsLoading(true)
        try {
            let res = await axios.post(`${api_url}/login`, data);
            if (res.data.success === 1) {
                let loginData = res.data.data;
                toast.success(res.data.message, {
                    theme: "colored",
                    autoClose: 1500,
                });
                const timer = setTimeout(() => {
                    navigate('/otplogin', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: window.location.pathname } });
                }, 2000);
                return () => clearTimeout(timer);
            } else {
                toast.error(res.data.message, {
                    autoClose: 2000,
                });
            }
        } catch (error) {
            toast.error("Something Wrong happened", {
                autoClose: 2000
            });
        } finally {
            setIsLoading(false)
        }
    }

    //Login
    const handleLogin = (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()
        let errors = LoginValidation(loginData);
        setLoginErrors(errors);

        if (Object.keys(errors).length === 0) {
            if (!captchaValue) {
                alert('Please verify the reCAPTCHA!')
            }
            else {
                setIsLoading(true);
                axios.post(`${api_url}/login`, loginData)
                    .then(res => {
                        if (res.data.success === 1) {
                            let loginData = res.data.data;
                            console.log('cfnsjdncskd', res.data.data)
                            if (loginData.status === 0) {
                                setIsLoading(false);
                                toast.error(res.data.message, {
                                    theme: "colored",
                                    autoClose: 2000,
                                });
                                const timer = setTimeout(() => {
                                    navigate('/otplogin', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: window.location.pathname } });
                                }, 3000);
                                return () => clearTimeout(timer);
                            }
                            else {
                                setIsLoading(false);
                                toast.success(res.data.message, {
                                    theme: "colored",
                                    autoClose: 2000,
                                });
                                const timer = setTimeout(() => {
                                    sessionStorage.setItem('token', (loginData.token));
                                    sessionStorage.setItem('username', loginData.name);
                                    loginData.school_code !== null && sessionStorage.setItem('school_code', loginData.school_code);
                                    sessionStorage.removeItem('guest_id')
                                    sessionStorage.setItem('user_id', loginData.user_id);
                                    // navigate(-1);
                                    navigate('/')
                                }, 3000);
                                return () => clearTimeout(timer);
                            }
                        } else {
                            setIsLoading(false)
                            toast.error(res.data.message, {
                                autoClose: 2000,
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        toast.error("axios Error", {
                            autoClose: 2000
                        });
                        setIsLoading(false);
                    })
            }
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Header />
            <main id='main' className=''>
                <section id="login" className='h-100'>
                    <div className="container">
                        <div className="row gx-lg-5 align-items-center">
                            <div className="col-lg-8 d-lg-block d-none">
                                <div className="mb-5 mb-lg-0">
                                    <div className="row align-items-end">
                                        <div className="col-lg-7">
                                            <h1 className="my-5 display-3 fw-bold">
                                                The best offer <br />
                                                <span className="text-danger">for your DEALS!</span>
                                            </h1>
                                        </div>
                                        <div className="col-lg-5">
                                            <img src={require("../assets/images/login.png")} className='img-fluid d-block mx-auto' width='300' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* form starts */}
                            <div className="col-lg-4">
                                {/* <div className="mx-auto py-5"><img src="../assets/images/logo.png" className="d-block mx-auto" width="150" alt="" /></div> */}
                                <div className="mt-4 py-2 rounded bg-white f-shadow px-4">
                                    <h3 className="text-center text-danger fw-bold">Login</h3>
                                    <div className="py-2">
                                        <form action="" onSubmit={handleLogin}>
                                            <div className='mb-3'>
                                                <div className="form-floating">
                                                    <input type="text" className="form-control s-font2" id="email" name="email" onChange={changeHandler} value={loginData.email} placeholder="email or Phone" />
                                                    <label className="form-label s-font2" htmlFor="email">Email Id or Mobile Number</label>
                                                </div>
                                                <div className="text-danger">{loginErrors.email}</div>
                                                {showOtp && <div className="cursor-pointer mt-2 s-font2"><span onClick={handleOtpLogin} className='text-decoration-none text-danger'>Get OTP to Login </span><span className='text-secondary s-font1 ms-4'>(or) enter password below</span></div>}
                                            </div>
                                            <div>
                                                <div className="form-floating position-relative">
                                                    <input type={type} className="form-control s-font2" id="password" name="password" onChange={changeHandler} value={loginData.password} placeholder="Password" />
                                                    <label className="form-label s-font2" htmlFor="password">Password</label>
                                                    <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={togglePassword}>{passShow ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow ? 'Show' : 'Hide'}</span>
                                                </div>
                                                <div className="text-danger">{loginErrors.password}</div>
                                            </div>

                                            <div className='s-font2 cursor-pointer'><Link to='/forgotpassword' className=' text-decoration-none'><strong className='text-dark'>Forgot Password ?</strong></Link></div>
                                            <div className='mt-3'>
                                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                            </div>
                                            <div className="mt-3 clearfix">
                                                <button type='submit' className="btn btn-danger w-100">Login</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="py-lg-0  px-4">
                                    <div className="hr-sect"><strong>New to Evyapari ?</strong></div>
                                    <Link to='/register' className="btn btn-outline-danger d-block mx-auto">Create Your Account</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Copyright />

            <ToastContainer />
        </>
    )
}
// )
// }

export default Login