import React from 'react'

const Review = (props) => {
    const { user_img, review_image, img_url, user_name, review_comment, rating, text, like } = props;
    return (
        <>
            <div>
                <div className='d-flex gap-2 align-items-center'>
                    <img src={require('../assets/images/review_images/user_review_img.png')} height="30" width="30" alt="" />
                    <div>{user_name}</div>
                </div>
                <div className="mt-2 mb-3 d-flex gap-3 align-items-center">
                    <div className="badge bg-success  px-2 "> {rating} <i className="bi bi-star-fill"></i></div>
                    <div>{review_comment}</div>
                </div>
                <div className="d-flex gap-2">
                    <div className="review-img-contain">
                        <img src={`${img_url}/review/${review_image}`} width={100} className="img-fluid rounded" alt="" />
                    </div>
                    <div className="review-img-contain">
                        <img src={require("../assets/images/review_images/img2.jpg")} width={100} className="img-fluid rounded" alt="" />
                    </div>
                    <div className="review-img-contain">
                        <img src={require("../assets/images/review_images/img5.jpg")} width={100} className="img-fluid rounded" alt="" />
                    </div>
                </div>
                {/* <div className="mt-2">
                    <i className="bi bi-hand-thumbs-up-fill"></i> Helpful ({5})
                </div> */}
            </div>
            <hr />
        </>
    )
}

export default Review