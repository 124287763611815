import React from 'react'
import { Link } from 'react-router-dom'

const EmailTemplate = () => {
    return (
        <>
            <center style={{ width: '100%', backgroundColor: '#f1f1f1' }}>
                {/* <div style={{ display: 'none', fontSize: 1, maxHeight: 0, maxWidth: 0, opacity: 0, overflow: 'hidden', msoHide: 'all', fontFamily: 'sans-serif' }}>
                    &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
                </div> */}

                <div style={{ maxWidth: 600, margin: '0 auto' }} className="email-container">
                    {/* BEGIN BODY */}
                    <table align="center" role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%" style={{ margin: 'auto' }}>
                        <tbody><tr>
                            <td className="bg_white logo" style={{ padding: '1em 2.5em', textAlign: 'center' }}>
                                <h1><Link to="#">Evyapari</Link></h1>
                            </td>
                        </tr>{/* end tr */}
                            <tr>
                                <td valign="middle" className="hero" style={{ backgroundImage: 'url(../assets/images/etemp/bg_1.jpg)', backgroundSize: 'cover', height: 400 }}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="text" style={{ padding: '0 3em', textAlign: 'center' }}>
                                                        <h2>We Provide You the Best Books</h2>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                                                        <p><Link to="#" className="btn btn-primary">Get Your Order Here!</Link></p>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>{/* end tr */}
                            <tr>
                                <td className="bg_white">
                                    <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                        <tbody><tr>
                                            <td className="bg_dark email-section" style={{ textAlign: 'center' }}>
                                                <div className="heading-section heading-section-white">
                                                    <span className="subheading">Welcome</span>
                                                    <h2>Welcome To Evyapari</h2>
                                                    <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                                                </div>
                                            </td>
                                        </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_white email-section">
                                                    <div className="heading-section" style={{ textAlign: 'center', padding: '0 30px' }}>
                                                        <span className="subheading">Services</span>
                                                        <h2>Our Services</h2>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                    </div>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="icon">
                                                                            <img src="../assets/images/images/001-diet.png" alt='' style={{ width: 60, maxWidth: 600, height: 'auto', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services">
                                                                                <h3>School Books</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="icon">
                                                                            <img src="../assets/images/images/003-recipe-book.png" alt='' style={{ width: 60, maxWidth: 600, height: 'auto', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services">
                                                                                <h3>School Books</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section" style={{ textAlign: 'center' }}>
                                                    <table>
                                                        <tbody><tr>
                                                            <td className="img">
                                                                <table>
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/images/bg_2.jpg" width={600} height alt='' border={0} style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} className="g-img" />
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                                <div className="icon">
                                                                    <Link to="#">
                                                                        <img src="../assets/images/images/002-play-button.png" alt='' style={{ width: 60, maxWidth: 600, height: 'auto', margin: 'auto', display: 'block' }} />
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                            <tr>
                                                                <td style={{ paddingTop: 20 }}>
                                                                    <h2>Watch Our Video</h2>
                                                                    <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.</p>
                                                                </td>
                                                            </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_white email-section">
                                                    <div className="heading-section" style={{ textAlign: 'center', padding: '0 30px' }}>
                                                        <span className="subheading">Products</span>
                                                        <h2>Our School Books</h2>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                    </div>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td style={{ paddingRight: 10 }}>
                                                                            <img src="../assets/images/etemp/book1.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services" style={{ textAlign: 'left' }}>
                                                                                <h3>Pasta, Sauce Milk</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td style={{ paddingLeft: 10 }}>
                                                                            <img src="../assets/images/etemp/book2.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services" style={{ textAlign: 'left' }}>
                                                                                <h3>Sweetened Fruits</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section" style={{ padding: 0, width: '100%' }}>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="text-services" style={{ textAlign: 'left', padding: '20px 30px' }}>
                                                                            <div className="heading-section">
                                                                                <span className="subheading">Category</span>
                                                                                <h2 style={{ fontSize: 22 }}>A complete Guide to Life</h2>
                                                                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/etemp/book3.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section" style={{ padding: 0, width: '100%' }}>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/etemp/book4.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="text-services" style={{ textAlign: 'left', padding: '20px 30px' }}>
                                                                            <div className="heading-section">
                                                                                <span className="subheading">Category</span>
                                                                                <h2 style={{ fontSize: 22 }}>A complete Guide to Life</h2>
                                                                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section" style={{ padding: 0, width: '100%' }}>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="text-services" style={{ textAlign: 'left', padding: '20px 30px' }}>
                                                                            <div className="heading-section">
                                                                                <span className="subheading">Category</span>
                                                                                <h2 style={{ fontSize: 22 }}>A complete Guide to Life</h2>
                                                                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/etemp/book5.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section" style={{ padding: 0, width: '100%' }}>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/etemp/book9.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="50%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="text-services" style={{ textAlign: 'left', padding: '20px 30px' }}>
                                                                            <div className="heading-section">
                                                                                <span className="subheading">Category</span>
                                                                                <h2 style={{ fontSize: 22 }}>A complete Guide to Life</h2>
                                                                                <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td valign="middle" className="counter" style={{ backgroundImage: 'url(../assets/images/etemp/bnr2.png)', backgroundSize: 'cover', padding: '4em 0' }}>
                                                    <table>
                                                        <tbody><tr>
                                                            <td valign="middle" width="25%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="counter-text">
                                                                            <span className="num">9457</span>
                                                                            <span className="name">Happy Customer</span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="25%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="counter-text">
                                                                            <span className="num">20</span>
                                                                            <span className="name">Years of Experienced</span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="25%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="counter-text">
                                                                            <span className="num">80</span>
                                                                            <span className="name">Branches</span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="middle" width="25%">
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td className="counter-text">
                                                                            <span className="num">980</span>
                                                                            <span className="name">Staff</span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end tr */}
                                            <tr>
                                                <td className="bg_white email-section">
                                                    <div className="heading-section" style={{ textAlign: 'center', padding: '0 30px' }}>
                                                        <span className="subheading">Blog</span>
                                                        <h2>Read Stories</h2>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                    </div>
                                                    <table role="presentation" border={0} cellPadding={0} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td style={{ paddingRight: 10 }}>
                                                                            <img src="../assets/images/etemp/book6.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services" style={{ textAlign: 'left' }}>
                                                                                <p className="meta"><span>Posted on Feb 18, 2019</span> <span>Food</span></p>
                                                                                <h3>Books For Kids</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td style={{ paddingLeft: 10 }}>
                                                                            <img src="../assets/images/etemp/book7.jpg" alt='' style={{ width: '100%', maxWidth: 600, height: '300px', margin: 'auto', display: 'block' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-services" style={{ textAlign: 'left' }}>
                                                                                <p className="meta"><span>Posted on Feb 18, 2019</span> <span>Food</span></p>
                                                                                <h3>New Motivational Books</h3>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                            <tr>
                                                <td className="bg_light email-section">
                                                    <div className="heading-section" style={{ textAlign: 'center', padding: '0 30px' }}>
                                                        <span className="subheading">Says</span>
                                                        <h2>Testimonial</h2>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                    </div>
                                                    <table role="presentation" border={0} cellPadding={10} cellSpacing={0} width="100%">
                                                        <tbody><tr>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/images/person_1.jpg" alt='' style={{ width: 100, maxWidth: 600, height: 'auto', margin: 'auto', marginBottom: 20, display: 'block', borderRadius: '50%' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-testimony" style={{ textAlign: 'center' }}>
                                                                                <h3 className="name">Ronald Tuff</h3>
                                                                                <span className="position">Businessman</span>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                            <td valign="top" width="50%" style={{ paddingTop: 20 }}>
                                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                                    <tbody><tr>
                                                                        <td>
                                                                            <img src="../assets/images/images/person_2.jpg" alt='' style={{ width: 100, maxWidth: 600, height: 'auto', margin: 'auto', marginBottom: 20, display: 'block', borderRadius: '50%' }} />
                                                                        </td>
                                                                    </tr>
                                                                        <tr>
                                                                            <td className="text-testimony" style={{ textAlign: 'center' }}>
                                                                                <h3 className="name">Willam Clarson</h3>
                                                                                <span className="position">Businessman</span>
                                                                                <p>Far far away, behind the word mountains, far from the countries</p>
                                                                                <p><Link to="#" className="btn btn-primary">Read more</Link></p>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody></table>
                                                            </td>
                                                        </tr>
                                                        </tbody></table>
                                                </td>
                                            </tr>{/* end: tr */}
                                        </tbody></table>
                                </td>
                            </tr>{/* end:tr */}
                            {/* 1 Column Text + Button : END */}
                        </tbody></table>
                    <table align="center" role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%" style={{ margin: 'auto' }}>
                        <tbody><tr>
                            <td valign="middle" className="bg_black footer email-section">
                                <table>
                                    <tbody><tr>
                                        <td valign="top" width="33.333%" style={{ paddingTop: 20 }}>
                                            <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                <tbody><tr>
                                                    <td style={{ textAlign: 'left', paddingRight: 10 }}>
                                                        <h3 className="heading">Evyapari</h3>
                                                        <p>A small river named Duden flows by their place and supplies it with the necessary regelialia.</p>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                        <td valign="top" width="33.333%" style={{ paddingTop: 20 }}>
                                            <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                <tbody><tr>
                                                    <td style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 5 }}>
                                                        <h3 className="heading">Contact Info</h3>
                                                        <ul>
                                                            <li><span className="text">203 Fake St. Mountain View, San Francisco, California, USA</span></li>
                                                            <li><span className="text">+2 392 3929 210</span></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                        <td valign="top" width="33.333%" style={{ paddingTop: 20 }}>
                                            <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                <tbody><tr>
                                                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                                                        <h3 className="heading">Useful Links</h3>
                                                        <ul>
                                                            <li><Link to="#">Marketplace</Link></li>
                                                            <li><Link to="#">My Schhol</Link></li>
                                                            <li><Link to="#"></Link></li>
                                                            <li><Link to="#">Dessert</Link></li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                    </tr>
                                    </tbody></table>
                            </td>
                        </tr>{/* end: tr */}
                            <tr>
                                <td valign="middle" className="bg_black footer email-section">
                                    <table>
                                        <tbody><tr>
                                            <td valign="top" width="33.333%">
                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                    <tbody><tr>
                                                        <td style={{ textAlign: 'left', paddingRight: 10 }}>
                                                            <p>© 2023 Evyapari. All Rights Reserved</p>
                                                        </td>
                                                    </tr>
                                                    </tbody></table>
                                            </td>
                                            <td valign="top" width="33.333%">
                                                <table role="presentation" cellSpacing={0} cellPadding={0} border={0} width="100%">
                                                    <tbody><tr>
                                                        <td style={{ textAlign: 'right', paddingLeft: 5, paddingRight: 5 }}>
                                                            <p><Link to="#" style={{ color: 'rgba(255,255,255,.4)' }}>Unsubcribe</Link></p>
                                                        </td>
                                                    </tr>
                                                    </tbody></table>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                        </tbody></table>
                </div>
            </center>
        </>
    )
}

export default EmailTemplate