import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/quantitypicker.css';
import './assets/css/style.css';
import './assets/css/custom_style.css';
import './assets/css/custom_class.css';

import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

import Home from './Pages/Home';
import { Navigate, Route, Routes } from 'react-router-dom';
import MarketPlace from './Pages/MarketPlace';
import MySchool from './Pages/MySchool';
import Cart from './Pages/Cart';
import MyProfile from './Pages/MyProfile';
import MyProfileInformation from './Pages/MyProfileInformation';
import MyOrders from './Pages/MyOrders';
import MyWishlist from './Pages/MyWishlist';
import OrderHistory from './Pages/OrderHistory';
import ChangePassword from './Pages/ChangePassword';
import AddNewAddress from './Pages/AddAddress';
import MyAddress from './Pages/MyAddress';
import GiveReview from './Pages/GiveReview';
import OrderDetails from './Pages/OrderDetails';
import Register from './Pages/Register';
import Login from './Pages/Login';
import VendorShop from './Pages/VendorShop';
import NoMatch from './Pages/NoMatch';
import ForgotPassword from './Pages/ForgotPassword';
import OtpLogin from './Pages/OtpLogin';
import OtpVarification from './Pages/OtpVarification';
import ResetForgotPassword from './Pages/ResetForgotPassword';
import MySchoolPopup from './Components/MySchoolPopup';
import EditAddress from './Pages/EditAddress';
import FileUpload from './Components/FIleUpload';
import EmailTemplate from './Pages/EmailTemplate';
import CheckOut from './Pages/CheckOut';
import SeeAllFeature from './Pages/SeeAllFeature';
import ScrollToTop from './Components/ScrollToTop';
import PlaceOrder from './Pages/PlaceOrder';
import BottomNavigationBar from './Components/BottomNavigationBar';
import PaymentStatus from './Pages/PaymentStatus';
import Pdf from './test/pdfDownload/Pdf';
import UsePdf from './test/pdfDownload/UsePdf';
import JSPDF from './test/pdfDownload/JS Pdf/JSPDF';
import PrintPdf from './test/PrintPdf/PrintPdf';
import ReactPrint from './test/PrintPdf/ReactPrint';
import Jspdf2 from './test/pdfDownload/JS Pdf/Jspdf2';
import UploadAndDisplayImage from './test/Image Upload/UploadAndDisplayImage';
import UploadImage from './test/Image Upload/UploadImage';
import CarouselSlider from './Components/CarouselSlider/CarouselSlider';
import GiveRating from './Components/comps/rating/GiveRating';
import ShowRating from './Components/comps/rating/ShowRating';
import MultiImageUpload from './Components/comps/ImageUpload/MultiImageUpload/MultiImageUpload';

function App() {
  return (
    <>
      <ScrollToTop />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/marketplace' element={<MarketPlace />} />
        <Route path='/myschool' element={<MySchool />} />
        <Route path='/cart' element={<Cart />} />

        {/* <Route path='/seeallfeature' element={<SeeAllFeature />} /> */}
        <Route path='/seeallfeature/:product_id' element={<SeeAllFeature />} />

        <Route path='/checkout' element={<CheckOut />} />
        <Route path='/placeorder/:invoice_number' element={<PlaceOrder />} />
        <Route path='/vendorshop' element={<VendorShop />} />
        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/myinformation' element={<MyProfileInformation />} />
        <Route path='/mywishlist' element={<MyWishlist />} />
        <Route path='/myorders' element={<MyOrders />} />
        <Route path='/paymentstatus/:order_id' element={<PaymentStatus />} />
        <Route path='/orderdetails' element={<OrderDetails />} />
        <Route path='/orderhistory' element={<OrderHistory />} />
        <Route path='/changepassword' element={<ChangePassword />} />
        <Route path='/myaddress' element={<MyAddress />} />
        <Route path='/addaddress' element={<AddNewAddress />} />
        <Route path='/givereview' element={<GiveReview />} />
        <Route path='/editaddress/:address_id' element={<EditAddress />} />
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/otplogin' element={<OtpLogin />} />
        <Route path='/otpverification' element={<OtpVarification />} />
        <Route path='/resetforgotpassword' element={<ResetForgotPassword />} />
        <Route path='/fileupload' element={<FileUpload />} />
        <Route path='/emailtemplate' element={<EmailTemplate />} />
        <Route path='*' element={<Navigate to="/" replace />} />

        {/* ----------------Test Routes------------ */}
        <Route path='/upload_image' element={<UploadAndDisplayImage/>} />
        <Route path='/upload_image2' element={<UploadImage/>} />
        <Route path='/pdf' element={<Pdf/>} />
        <Route path='/usepdf' element={<UsePdf/>} />
        <Route path='/jspdf' element={<JSPDF/>} />
        <Route path='/printpdf' element={<PrintPdf/>} />
        <Route path='/reactprint' element={<ReactPrint/>} />
        <Route path='/jspdf2' element={<Jspdf2/>} />
        <Route path='/slider' element={<CarouselSlider/>} />
        <Route path='/starrating' element={<GiveRating/>} />
        <Route path='/showrating' element={<ShowRating/>} />
        <Route path='/multiimage' element={<MultiImageUpload/>} />
        <Route path='/multiimage2' element={<MultiImageUpload/>} />
      </Routes>

      <MySchoolPopup />
      <BottomNavigationBar/>
    </>
  );
}

export default App;
