import React, { useState } from 'react'

const QuantityPicker = ({min,max,seeAllCallback,cartCallback,default_value}) => {
    const [count, setCount] = useState(default_value);
    const decrement = () => {
        if(count>min) {
            setCount((prev) => (prev-1))
        } else {
            setCount(max)
        }
    }
    const increment = () => {
        if(count<max) {
            setCount((prev) => (prev+1))
        } else {
            setCount(min)
        }
        console.log(typeof(default_value))
    }
    seeAllCallback && seeAllCallback(count);
    cartCallback && cartCallback(count);
    return (
        <>
            <span className="quantity-picker">
                <button className="quantity-modifier modifier-left" onClick={() => decrement()}>&ndash;</button>
                <input className="quantity-display" type="text" value={count} readOnly />
                <button className="quantity-modifier modifier-right" onClick={() => increment()}>&#xff0b;</button>
            </span>
        </>
    )
}

export default QuantityPicker