import React, { useEffect, useState } from 'react'
import ProductCard from '../Components/ProductCard'
import { ToastContainer } from 'react-toastify'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext'
import Footer from '../Components/Footer'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { useInventoryContext } from '../contexts/InventoryContext'

const MarketPlace = () => {
  const { img_url, api_url, convertToPlain } = useGlobalContext();
  const { getPageInventory, pageData, pageInventory, lastCategories, searchParams, setSearchParams, getVendors, vendors, setVendors } = useInventoryContext();
  const [searchVendors, setSearchVendors] = useState([]);
  const navigate = useNavigate()
  // alert(searchKeyword);
  const [marketData, setMarketData] = useState({
    search_place: '',
    vendor: ''
  });
  const location = useLocation();



  const [marketErrors, setMarketErrors] = useState({});

  const cat_id = location.state?.cat_id
  const subcat_id = location.state?.subcat_id;
  const subcat2_id = location.state?.subcat2_id

  useEffect(() => {
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
    getVendors();
  }, []);

  useEffect(() => {
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
  }, [location]);

  //Clear Fiters
  const clearFilters = () => {
    if (searchParams.get('search')) {
      searchParams.delete('search')
    }
    if (searchParams.get('sort')) {
      searchParams.delete('sort')
    }
    setSearchParams(searchParams)

    getPageInventory(`${api_url}/pageInventory`)
  }

  //handlePlaceChange
  const handlePlaceChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
    const filtered_vendors = vendors.filter((vendor) => {
      return (
        vendor.state?.toLowerCase().includes(e.target.value.toLowerCase())
        ||
        vendor.city?.toLowerCase().includes(e.target.value.toLowerCase())
        ||
        vendor.distt?.toLowerCase().includes(e.target.value.toLowerCase())
      )
    });

    if(e.target.value==='')
    {
      setSearchVendors([])
    }
    else{
      setSearchVendors(filtered_vendors)
    }
  }

  //Sort By Price
  const SortByPrice = (order) => {
    searchParams.set('sort', order);
    setSearchParams(searchParams)
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'))
  }

  const handleNextPage = () => {
    getPageInventory(pageData.next_page_url, searchParams.get('search'), cat_id, subcat_id, subcat2_id)
    window.scrollTo(0, 600)
  }

  const handlePreviousPage = () => {
    getPageInventory(pageData.prev_page_url, searchParams.get('search'), cat_id, subcat_id, subcat2_id)
    window.scrollTo(0, 600)
  }

  const handleLastCategory = (subcat3_id) => {
    getPageInventory(`${api_url}/pageInventory`, searchParams.get('search'), null, null, null, subcat3_id)
  }


  const MarketValidation = () => {
    const errors = {};
    const { search_place, vendor } = marketData;

    if (!search_place) {
      errors.search_place = 'Please select city name';
    }
    if (!vendor || vendor==='') {
      errors.vendor = 'Please select vendor';
    }
    return errors;
  }

  //handleVendorSearch
  const handleVendorSearch = (e) => {
    e.preventDefault();
    let errors = MarketValidation();
    setMarketErrors(errors);

    if (Object.keys(errors).length === 0) {
      navigate('/vendorshop', {state: {'vendor_id': marketData.vendor}})
    }
  }

  //handleVendorChange
  const handleVendorChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const handleChange = (e) => {
    setMarketData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  return (
    <>
      <Header />
      <main id='main'>
        {/* <!-----------------------------------Banner Starts---------------------------------> */}
        <section id="banner">
          <div id="carouselExampleCaptions" className="carousel">
            <div className="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className='banner_img_contain'>
                  <img src={`${img_url}/site_img/e-marketplace.jpg`} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-----------------------------------Banner Ends-----------------------------------> */}

        {/* <!-----------------------------------Form Starts-----------------------------------> */}
        <section className="py-5 bg-light">
          <div className="container">
            <form>
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="form-floating mb-4 mb-lg-0">
                    <input type="text" className="b-shadow border-white form-control" onChange={(e) => handlePlaceChange(e)} name='search_place' value={marketData.search_place} id="city" placeholder="" />
                    <label htmlFor="city">Enter state, dist, City <span className="text-danger">*</span></label>
                    <div className="text-danger">{marketErrors.search_place}</div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex">
                    <select className="b-shadow border-white form-select py-3" onChange={handleVendorChange} name='vendor' >
                      <option>Select vendor</option>
                      {
                        searchVendors.map((vendor, i) => {
                          return (
                            <option value={vendor.unique_id}>{vendor.username}</option>
                          )
                        })
                      }
                    </select>
                    <button className="b-shadow btn btn-danger p-3 ms-4" onClick={handleVendorSearch}><i className="bi bi-search"></i></button>
                  </div>
                  <div className="text-danger">{marketErrors.vendor}</div>
                </div>
              </div>
            </form>
          </div>
        </section>
        {/* <!-----------------------------------Form Ends-------------------------------------> */}

        <section>
          <div className="container">
            <div className="d-flex gap-3 justify-content-end mt-3">
              <div>
                <button className='btn btn-pink' onClick={() => clearFilters()}>Clear Filters</button>
              </div>
              {/* <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Sort By
                </button>
                <ul class="dropdown-menu">
                  <li><span class="dropdown-item cursor-pointer" onClick={() => SortByPrice('price_desc')}>Price High to Low</span></li>
                  <li><span class="dropdown-item cursor-pointer" onClick={() => SortByPrice('price_asc')}>Price Low to High</span></li>
                </ul>
              </div> */}
            </div>
          </div>
        </section>

        {/* <!-- product and category section --> */}
        <section id="category-product" className="pt-5">
          <div className="container-fluid">
            <div className="row">
              {/* Categories */}
              {
                lastCategories.length !== 0 &&
                <div className="col-md-3">
                  <div className=''>
                    <h3 className="bg-pink text-center text-white mb-0 p-1">Categories</h3>
                    <div className="accordion border border-3 border-danger" id="accordionExample">
                      {
                        lastCategories.map((cat, i) => {
                          const { id, title } = cat;
                          return (
                            <div className='border-bottom border-bottom-2 border-gray h5 mb-0 py-1 ps-3 '>
                              <span className='cursor-pointer' onClick={() => handleLastCategory(id)}>{title}</span>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              }

              <div className={`${lastCategories.length === 0 ? 'col' : 'col-md-9'}`}>
                <div className="row">
                  {
                    pageInventory.length !== 0 ?
                      <div className="d-flex flex-wrap align-align-items-stretch justify-content-around">
                        {
                          pageInventory &&
                          pageInventory.map((card, i) => {
                            const { id, product_id, last_cat_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
                            return (
                              <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                                <ProductCard
                                  id={id}
                                  product_id={product_id}
                                  image={`${img_url}/${folder}/${image}`}
                                  title={product_name}
                                  mrp={mrp}
                                  discounted_price={discounted_price}
                                  shipping_charges={shipping_charges}
                                  type={type}
                                  text={convertToPlain(description)}
                                  Card={card}
                                  itemExistInCart={itemExistInCart}
                                  itemExistInWishlist={itemExistInWishlist}
                                  last_cat_id={last_cat_id}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                      :
                      <div className='h4 text-danger text-center'>No Item Found</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* Pagination */}
        <section className='pt-3 pb-5'>
          <div className='d-flex justify-content-end me-5'>
            <ul className='pagination'>
              {
                pageData.prev_page_url ? (
                  <li className='page-item'>
                    <button className='page-link' onClick={handlePreviousPage}>
                      <span className='text-danger'>Previous</span>
                    </button>
                  </li>
                ) : null
              }
              {
                pageData.next_page_url ? (
                  <li className='page-item'>
                    <button className='page-link' onClick={handleNextPage}>
                      <span className='text-danger'>Next</span>
                    </button>
                  </li>
                ) : null
              }
            </ul>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </>
  )
}

export default MarketPlace