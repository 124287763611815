const cdata = [
    {
        "id": 1,
        "image": "assets/images/product-card/book1.jpg",
        "title": "Science and Me",
        "price": "$110.00 Only",
        "text2": "This is second text",
        "text": "Murder On A School Night by Kate Weston"
    },
    {
        "id": 2,
        "image": "assets/images/product-card/book2.jpg",
        "title": "English Grammer For You",
        "price": "$210.00 Only",
        "text2": "This is second text",
        "text": "Thirteen Doorways, Wolves Behind Them All by Laura"
    },
    {
        "id": 3,
        "image": "assets/images/product-card/book3.jpg",
        "title": "To Kill a Mockingbird",
        "price": "$760.00 Only",
        "text2": "This is second text",
        "text": "Pride and Premeditation by Tirzah Price"
    },
    {
        "id": 4,
        "image": "assets/images/product-card/book4.jpg",
        "title": "A Confederacy of Dunces",
        "price": "$450.00 Only",
        "text2": "This is second text",
        "text": "How to Excavate a Heart by Jake Maia Arlow"
    },
    {
        "id": 5,
        "image": "assets/images/product-card/book5.jpg",
        "title": "Absalom, Absalom!",
        "price": "$664.00 Only",
        "text2": "This is second text",
        "text": "Lulu and Milagro’s Search for Clarity by Angela Velez"
    },
    {
        "id": 6,
        "image": "assets/images/product-card/book6.jpg",
        "title": "Brave New World",
        "price": "$49.00 Only",
        "text2": "This is second text",
        "text": "Just Your Local Bisexual Disaster by Andrea Mosqueda"
    },
    {
        "id": 7,
        "image": "assets/images/product-card/book7.jpg",
        "title": "Alice's Adventures In Wond",
        "price": "$89.00 Only",
        "text2": "This is second text",
        "text": "Reader, I Murdered Him by Betty Cornwell"
    },
    {
        "id": 8,
        "image": "assets/images/product-card/book8.jpg",
        "title": "Wuthering Heights",
        "price": "$434.00 Only",
        "text2": "This is second text",
        "text": "The Lesbiana’s Guide to Catholic School by Sonora Reyes"
    },
    {
        "id": 9,
        "image": "assets/images/product-card/book9.jpg",
        "title": "Badaesh Ta Mockingbird",
        "price": "$131.00 Only",
        "text2": "This is second text",
        "text": "Dacorta and Premeditation by Tirzah Price"
    },
    {
        "id": 10,
        "image": "assets/images/product-card/book10.jpg",
        "title": "Jane Eyre Woker",
        "price": "$93.00 Only",
        "text2": "This is second text",
        "text": "Puma Men Shoes are there good read more"
    },
    {
        "id": 11,
        "image": "assets/images/product-card/book11.jpg",
        "title": "AAj menu Chak Len De",
        "price": "$100.00 Only",
        "text2": "This is second text",
        "text": "Puma Men Shoes are there good read more"
    },
    {
        "id": 12,
        "image": "assets/images/product-card/book12.jpg",
        "title": "Jake Apna Kam Karna",
        "price": "$654.00 Only",
        "text2": "This is second text",
        "text": "Puma Men Shoes are there good read more"
    }
]

export const pdata = [
    {
        "image": "assets/images/account_img/profile.png",
        "title": "My Personal Information",
        "text": "Name, Contact, Address, Basic Information",
        "link": "myinformation"
    },
    {
        "image": "assets/images/account_img/trolly.png",
        "title": "My Cart",
        "text": "Added item, wishlist Items, place confirmation",
        "link": "cart"
    },
    {
        "image": "assets/images/account_img/my_orders.png",
        "title": "My Orders",
        "text": "purchased items, deliverd items, detail",
        "link": "myorders"
    },
    {
        "image": "assets/images/account_img/wishlist.png",
        "title": "My Wishlist",
        "text": "items selected to buy later",
        "link": "mywishlist"
    },
    {
        "image": "assets/images/account_img/order.png",
        "title": "Order History",
        "text": "purchased items, deliverd items, detail",
        "link": "orderhistory"
    },
    {
        "image": "assets/images/account_img/password.png",
        "title": "Change Password",
        "text": "Current Password, New password, Confirm password",
        "link": "changepassword"
    },
    {
        "image": "assets/images/account_img/home.png",
        "title": "My Addresses",
        "text": "Shivam, Hamirpur, P.O- Landmark- 175036 6284888919",
        "link": "myaddress"
    },
    {
        "image": "assets/images/account_img/home2.png",
        "title": "Add New Shipping address",
        "text": "Edit addresses for orders",
        "link": "newaddress"
    }
]

export const odata = [
    {
        "image": "assets/images/review_images/img1.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 126.00"
    },
    {
        "image": "assets/images/review_images/img2.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 156.00"
    },
    {
        "image": "assets/images/review_images/img3.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 726.00"
    }
]

export const wdata = [
    {
        "image": "assets/images/review_images/img5.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 126.00",
        "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti iusto libero obcaecati excepturi accusantium, ut sunt illo natus, laborum maxime aut!"
    },
    {
        "image": "assets/images/review_images/img10.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 156.00",
        "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti iusto libero obcaecati excepturi accusantium, ut sunt illo natus, laborum maxime aut!"
    },
    {
        "image": "assets/images/review_images/img9.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "price": "₹ 726.00",
        "text": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti iusto libero obcaecati excepturi accusantium, ut sunt illo natus, laborum maxime aut!"
    }
]

export const hdata = [
    {
        "image": "assets/images/review_images/img5.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "class": "7 th Class",
        "price": "₹ 126.00",
        "qty": 2,
        "date": "24-06-21"
    },
    {
        "image": "assets/images/review_images/img5.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "class": "7 th Class",
        "price": "₹ 126.00",
        "qty": 3,
        "date": "24-06-21"
    },
    {
        "image": "assets/images/review_images/img5.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "class": "7 th Class",
        "price": "₹ 126.00",
        "qty": 1,
        "date": "24-06-21"
    },
    {
        "image": "assets/images/review_images/img5.jpg",
        "title": "WORKBOOK SCIENCE CBSE- CLASS 7TH Sold by: GAUTAM ENTERPRISES",
        "class": "7 th Class",
        "price": "₹ 126.00",
        "qty": 1,
        "date": "24-06-21"
    },
]

export const adata = [
    {
        "name": "Shivam Batholiya",
        "home": "Singaji kirana",
        "village": "Bagod",
        "city": "Barwaha, MADHYA PRADESH 451220",
        "country": "India",
        "contact": "9645962826"
    },
    {
        "name": "Shivam Batholiya",
        "home": "Singaji kirana",
        "village": "Bagod",
        "city": "Barwaha, MADHYA PRADESH 451220",
        "country": "India",
        "contact": "9645962826"
    },
    {
        "name": "Shivam Batholiya",
        "home": "Singaji kirana",
        "village": "Bagod",
        "city": "Barwaha, MADHYA PRADESH 451220",
        "country": "India",
        "contact": "9645962826"
    },
]

export const setdata = [
    {
        "title": "Nursery Class Book Set",
        "tdata": [
            {
                "image": "assets/images/books-set/book-1.jpg",
                "title": "SVM SULEKH HINDI-A",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-2.jpg",
                "title": "SULEKH ENGLISH-NUR",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-3.jpg",
                "title": "KALAKRITI-B",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-4.jpg",
                "title": "NUMBER 1-100",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-5.jpg",
                "title": "ALL IN ONE READING BOOK",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
        ]
    },
    {
        "title": "Nursery Class Book Set",
        "tdata": [
            {
                "image": "assets/images/books-set/book-1.jpg",
                "title": "SVM SULEKH HINDI-A",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-2.jpg",
                "title": "SULEKH ENGLISH-NUR",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-3.jpg",
                "title": "KALAKRITI-B",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-4.jpg",
                "title": "NUMBER 1-100",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-5.jpg",
                "title": "ALL IN ONE READING BOOK",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
        ]
    },
    {
        "title": "Nursery Class Book Set",
        "tdata": [
            {
                "image": "assets/images/books-set/book-1.jpg",
                "title": "SVM SULEKH HINDI-A",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-2.jpg",
                "title": "SULEKH ENGLISH-NUR",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-3.jpg",
                "title": "KALAKRITI-B",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-4.jpg",
                "title": "NUMBER 1-100",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
            {
                "image": "assets/images/books-set/book-5.jpg",
                "title": "ALL IN ONE READING BOOK",
                "class": "Nursery",
                "price": 80.00,
                "publisher": "VIDYA BHARTI UTTAR KSHETRA",
                "qty": 1
            },
        ]
    }
]

export const rdata=[
    {
        "user_img" : "./assets/images/review_images/user_review_img.png",
        "user_name": "Rajesh Khanna",
        "text" : "This is worth of cost, pages are good. thankyo very Much.",
        "rating": "2.9",
        "like": "34"
    },
    {
        "user_img" : "./assets/images/review_images/user_review_img.png",
        "user_name": "Rajesh Khanna",
        "text" : "This is worth of cost, pages are good. thankyo very Much.",
        "rating": "2.9",
        "like": "34"
    }
]

export const vdata = [
    {
        "name": "Manish Ke Traders",
        "image": "assets/images/review_images/img1.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Elesha Traders",
        "image": "assets/images/review_images/img2.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "rajesh Prashad Trades",
        "image": "assets/images/review_images/img3.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Asvin Puri Bask",
        "image": "assets/images/review_images/img4.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Rj Markets vala",
        "image": "assets/images/review_images/img5.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Manish Ke Traders",
        "image": "assets/images/review_images/img1.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Elesha Traders",
        "image": "assets/images/review_images/img2.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "rajesh Prashad Trades",
        "image": "assets/images/review_images/img3.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Asvin Puri Bask",
        "image": "assets/images/review_images/img4.jpg",
        "price":"₹130",
        "shipping": "₹20",
    },
    {
        "name": "Rj Markets vala",
        "image": "assets/images/review_images/img5.jpg",
        "price":"₹130",
        "shipping": "₹20",
    }
]


export default cdata;