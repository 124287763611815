import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Components/Header';
import Copyright from '../Components/Copyright';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';

const ForgotPassword = () => {
  const { api_url,setIsLoading } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    phone_no: '',
  });

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const Validation = () => {
    let errors = {};
    var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/

    if (!passData.phone_no) {
      errors.phone_no = 'Please fill the required';
    } else if (!emailPhoneRegex.test(passData.phone_no)) {
      errors.phone_no = 'Wrong Formate'
    }

    return errors;
  }

  var emailPhoneRegex = /^(?:\d{10}|\w+.+@\w+\.\w{2,3})$/

  useEffect(() => {
    if (emailPhoneRegex.test(passData.phone_no)) {
      setPassErrors({ ...passErrors, phone_no: '' })
    }
  }, [passData.phone_no]);

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = Validation();
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = { phone_no: passData.phone_no }
      setIsLoading(true)
      axios.post(`${api_url}/sendPasswordOtp`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message}`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              navigate('/otpverification', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: location.pathname } });
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })
    }
  }

  return (
    <>
      <Header />
      <main id='main' className='forgotmain' >
        <section id="login">
          <div className="container">
            <div className="py-5 px-3  w-100 login-container align-this forgotpass">
              <div className="f-shadow bg-white">
                <div className="mx-auto h6 m-0 p-3 bg-pink text-white">Forgot Password</div>
                <form className="mx-auto border p-4">
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control" onChange={handleChange} name='phone_no' value={passData.phone_no} id="currentPassword" placeholder="Password" />
                    <label htmlFor="currentPassword">Phone Number<span className="text-danger">*</span></label>
                    <div className="text-danger">{passErrors.phone_no}</div>
                  </div>
                  <div className="row">
                    <div className="col"><Link to='/login' className="btn btn-outline-pink">Go Back</Link></div>
                    <div className="col "><button onClick={(e) => handleSubmit(e)} className="btn btn-pink d-block ms-auto">Send OTP</button></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      
      <Copyright />
      <ToastContainer />
    </>
  )
}

export default ForgotPassword