import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Header from '../Components/Header';

import { useEffect } from 'react';
import Footer from '../Components/Footer';
import { useGlobalContext } from '../contexts/GlobalContext';
import ProductDetails from '../Components/ProductDetails';
import QuantityPicker from '../Components/QuantityPicker';
import ProductCard from '../Components/ProductCard';
import { toast } from 'react-toastify';
import axios from 'axios';
import './CSS/SeeAllFeature.css'

import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
} from 'react-share';
import { useInventoryContext } from '../contexts/InventoryContext';
import ViewRating from '../Components/ViewRating';


const SeeAllFeature = (props) => {
  const { setIsLoading, api_url, img_url, convertToPlain, handleAddToCart, handleAddToWishlist, handleBuyNow } = useGlobalContext();
  const { allInventory, getAllInventory, inventoryDetails, setInventoryDetails, getInventoryDetails, inventoryImages, } = useInventoryContext()
  const [pincode, setPincode] = useState();
  const [availability, setAvailability] = useState(null);
  const [productReviews, setProductReviews] = useState([]);
  const [productRating, setProductRating] = useState(0);
  const [sizeMediumChanged, setSizeMediumChanged] = useState(false)
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  // ------------ //

  // useEffect(()=> {
  //   console.log("see all features inventoryDetails", inventoryDetails)
  // }, [])
  console.log("see all features inventoryDetails", inventoryDetails)

  const [colorClass, setColorClass] = useState(inventoryDetails.class_title)

  const [inventoryColorClasses, setInventoryColorClasses] = useState([]);

  console.log('inventoryColorClasses', inventoryColorClasses)
  // console.log('size_class_inventory', inventoryDetails?.size_class_inventory)

  useEffect(() => {
    productReviews.length!==0 && getProductRating();
  }, [productReviews])

  useEffect(() => {
    getProductReviews();
  }, [inventoryDetails]);

  //getProductReviews
  const getProductReviews = async () => {
    try {
      const res = await axios.post(`${api_url}/getProductReviews`, { 'product_id': inventoryDetails?.product_id })
      if (res.data.success === 1) {
        setProductReviews(res.data.data)
        // toast.success(res.data.message, {
        //   autoClose: 1500,
        //   theme: "colored",
        // });
        // console.log("getProductReviews",res.data)
      } else {
        toast.error('Something Went Wrong', {
          autoClose: 1500,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error("Axios Error", {
        autoClose: 1500,
        theme: "colored",
      });
    }
  }

  //getProductRating
  const getProductRating = () => {
    let prating = 0;
    productReviews.forEach((ele, i) => {
      prating += ele.rating;
    })
    prating = (prating/productReviews.length).toFixed(1);
    setProductRating(prating)
  }

  //checkPincodeAvailability
  const checkPincodeAvailability = async (mypincode) => {
    const data = {
      "pincode": mypincode
    }
    setIsLoading(true);
    try {
      let res = await axios.post(`${api_url}/checkPincodeAvailability`, data);
      if (res.data.success === 1) {
        setAvailability(res.data.message);
      }
    } catch (error) {
      toast.error('Something Wrong Happened', {
        autoClose: 2000,
        theme: 'colored'
      })
    } finally {
      setIsLoading(false);
    }
  }


  // useEffect(() => {
  //   console.log(" Review id Recieved... "+params.product_id);
  //   getInventoryDetails(params.product_id);
  // }, []);

  useEffect(() => {
    getInventoryDetails(params.product_id);
  }, [location]);

  useEffect(() => {
    getAllInventory(null, null, null, null, inventoryDetails?.last_cat_id);
  }, [inventoryDetails]);

  const [mainImage, setMainImage] = useState({})

  //Accessing the quantity value from Quantity Picker
  let access_qty;
  const accessQantity = (child_qty) => {
    access_qty = child_qty;
  }

  //handleColorClassChange
  const handleColorClassChange = (e) => {
    let ele = document.getElementById('color_class_label');
    ele.classList.remove('select_color_class');
    setColorClass(e.target.innerHtml)
    navigate(`/seeallfeature/${e.target.value}`)
  }

  //handleSizeMediumChange
  const handleSizeMediumChange = async (e) => {
    let ele = document.getElementById('color_class_label');
    ele.classList.add('select_color_class');
    setSizeMediumChanged(true)
    // if (inventoryDetails?.class_title) {
    //   setInventoryDetails((prev) => ({ ...prev, 'class_title': '--Select Class--' }))
    // }
    // if (inventoryDetails?.product_color) {
    //   setInventoryDetails((prev) => ({ ...prev, 'product_color': '--Select Color--' }))
    // }

    setColorClass('');

    const data = {
      'product_name': inventoryDetails?.product_name,
      'size_medium_id': e.target.value
    }
    try {
      const res = await axios.post(`${api_url}/getInventoryColorClasses`, data);
      if (res.data.success === 1) {
        setInventoryColorClasses(res.data.data);
      } else {
        toast.error('Something Wrong Happened', {
          autoClose: 2000,
          theme: 'colored'
        })
      }

    } catch (error) {
        console.log(error)
    }
  }

  console.log(productRating)



  return (
    <>
      <Header />
      <main id='main'>
        <div id='seeallfeature'>
          <section id="product_details" className="py-5">
            <div className="container">
              <div className="row">
                <div className="col-sm-5">
                  <div className="row">
                    <div className="col-md-3 col-lg-2">
                      <div className="d-md-block d-flex mx-5 mx-md-0">
                        {inventoryImages &&
                          inventoryImages.map((image, i) => {
                            return (
                              <div key={i} className="detail-side-img-contain border rounded mb-2 cursor-pointer">
                                <img onClick={() => setMainImage(image)} src={`${img_url}/${image.folder}/${image.image}`} className="d-block img-fluid mx-auto rounded" alt={image.alt} />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    <div className="col-md-9 col-lg-10">
                      {
                        Object.keys(mainImage).length === 0 ?
                          (
                            inventoryImages &&
                            inventoryImages.map((image, i) => {
                              return (
                                image.dp_status === 1 &&
                                <div key={i} className="detail-img-contain border rounded p-md-2">
                                  <img src={`${img_url}/${image.folder}/${image.image}`} className="d-block img-fluid mx-auto rounded-3 zoom_img" width={380} height={480} alt='' />
                                </div>
                              )
                            })
                          )
                          :
                          <div className="detail-img-contain border rounded p-md-2">
                            <img src={`${img_url}/${mainImage.folder}/${mainImage.image}`} className="d-block img-fluid mx-auto rounded-3 zoom_img" width={380} height={480} alt='' />
                          </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div>
                    <div className='mt-2 mt-sm-0'>
                      <div className='h3'>
                        {
                          inventoryDetails && (
                            inventoryDetails.product_name !== null && inventoryDetails.product_name
                          )
                        }
                      </div>
                      <>
                        { 
                          productRating!==0 &&
                          <ViewRating stars={productRating} />
                        }
                      </>
                      <div className='my-2'>
                        {
                          inventoryDetails && (
                            inventoryDetails?.mrp > inventoryDetails?.discounted_price && (
                              <del className='h4 text-secondary me-2'>₹{inventoryDetails.mrp}</del>
                            )
                          )
                        }
                        <span className='color-pink h3'>
                          {
                            inventoryDetails && (
                              inventoryDetails.discounted_price !== null && (`₹${inventoryDetails.discounted_price}`)
                            )
                          }
                        </span>
                      </div>

                      <div className="d-flex gap-4 mb-3">
                        <div>
                          <label htmlFor="" className='fw-light text-secondary'>Medium/Size</label>
                          <select className="form-select" name='size_medium' onChange={handleSizeMediumChange} aria-label="Default select example">
                            {
                              inventoryDetails.size_medium &&
                              inventoryDetails.size_medium.map((ele, i) => {
                                return (
                                  <option key={i} value={ele.size_medium_id} selected={ele.size_medium_title === inventoryDetails?.product_size}>{ele.size_medium_title}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                        <div>
                          <label htmlFor="" id='color_class_label' className='fw-light text-secondary'>Class/Color</label>
                          <select className="form-select" name='class_inventory_id' value={colorClass} onChange={handleColorClassChange} aria-label="Default select example">
                            {
                              sizeMediumChanged===false ?
                                <>
                                  {/* <option value={inventoryDetails?.id}>{inventoryDetails?.class_title} {inventoryDetails?.product_color}</option> */}
                                  <option>Select</option>
                                  {
                                    inventoryDetails &&
                                    inventoryDetails?.color_class &&
                                    inventoryDetails?.color_class.map((ele, i) => {
                                      return (
                                        // (inventoryDetails?.class_title !== ele.color_class_title ||
                                        //   inventoryDetails?.product_color !== ele.color_class_title)  
                                        // &&
                                        <option key={i} value={ele.inventory_id}
                                          selected={inventoryDetails?.class_title === ele.color_class_title ||
                                            inventoryDetails?.product_color === ele.color_class_title}>
                                          {ele.color_class_title}
                                        </option>
                                      )
                                    })
                                  }
                                </>
                                :
                                <>
                                
                                  <option  >--Select Class--</option>
                                  {

                                    inventoryColorClasses.map((ele, i) => {

                                      return (
                                        // (inventoryDetails?.class_title !== ele.color_class_title ||
                                        //   inventoryDetails?.product_color !== ele.color_class_title)
                                        // &&
                                        <option key={i} value={ele.inventory_id}>
                                          {ele.color_class_title}
                                        </option>
                                      )
                                    })
                                  }
                                </>
                            }
                          </select>
                        </div>
                      </div>

                      <div className="d-flex">
                        {
                          inventoryDetails && (
                            inventoryDetails.product_color !== null &&
                            (<div className='h5 me-3'> <span className='text-secondary'>Color : </span> {inventoryDetails.product_color}</div>)
                          )
                        }
                      </div>

                    </div>

                    <div className=''>
                      <div className=''>
                        <div className="badge rounded-pill bg-light py-2 px-3 text-secondary h5">
                          {
                            inventoryDetails && (
                              inventoryDetails.shipping_charges !== null
                                ? (`Delivery ₹${inventoryDetails.shipping_charges}`)
                                : 'Free Delivery'
                            )
                          }
                        </div>
                        <div className="check-pincode d-flex align-items-center">
                          <input type="number" className="form-control w-50" onChange={(e) => setPincode(e.target.value)} name='purchase_pincode' value={pincode} id="father" placeholder="Enter Pincode" />
                          <button onClick={() => checkPincodeAvailability(pincode)} className='btn btn-pink ms-2'>
                            <span>Check <span className='d-none d-sm-inline'>Availability</span></span>
                          </button>
                          {
                            availability !== null &&
                            <div className='ms-3 fw-bold'>
                              {
                                availability ? <span className='text-success'>Available</span> : <span className='text-danger'>Not Available</span>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className='my-4'>
                        <span className='me-2'>Qty:</span>
                        <QuantityPicker min={1} max={10} default_value={1} seeAllCallback={accessQantity} />
                      </div>
                      <div className="d-flex gap-3 gap-sm-4">
                        <button id="addtocart" onClick={() => handleAddToCart(inventoryDetails?.id, access_qty)} className="btn btn-pink py-2 px-sm-4">
                          <i className="bi bi-cart3 me-2"></i>
                          Add to Cart
                        </button>
                        <button id="addtocart" onClick={() => handleBuyNow(inventoryDetails?.id, access_qty)} className="btn btn-pink py-2 px-sm-4">
                          <i className="bi bi-chevron-double-right me-2"></i>
                          Buy Now
                        </button>
                        <div className='d-flex align-items-center cursor-pointer' onClick={() => handleAddToWishlist(inventoryDetails.id)}>
                          <span className='color-pink me-2'><i className="bi bi-heart fs-4"></i></span>
                          <span className='d-none d-sm-block'>Add to Wishllist</span>
                        </div>
                      </div>
                      <div className='d-flex gap-3 align-items-center mt-4'>
                        <span className='fs-4'>Share : </span>
                        <FacebookShareButton
                          title={`${inventoryDetails?.product_name}`}
                          url={`https://evyapari.com/seeallfeature/${inventoryDetails.id}`}
                          quote={'abc'}
                          hashtag={'#portfolio...'}
                        >
                          <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>

                        <WhatsappShareButton
                          quote={'Abc'}
                          title={`${inventoryDetails?.product_name}`}
                          url={`https://evyapari.com/seeallfeature/${inventoryDetails.id}`}
                          hashtag={'#portfolio...'}
                        >
                          <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* -------sold by------- */}
          <section className='my-5'>
            <div className="container">
              <div className="border rounded p-3 mt-3">
                <div className="row align-items-center ">
                  <div className="col-md-2">
                    <div className="card-title fs-normal fs-5 mb-3">Sold By</div>
                  </div>
                  <div className="col-md-3">
                    <div className="row align-items-center">
                      <div className="col-3">
                        <img src={require("../assets/images/shop_img.webp")} className="d-block img-fluid mx-auto" height="72px" width="72px" alt="" />
                      </div>
                      <div className="col-9">
                        <span>{inventoryDetails.vendor_name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="d-flex justify-content-center gap-sm-5 gap-3 py-3 align-items-end">
                      <div>
                        <span>{inventoryDetails?.total_vendor_inventory} Products</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button onClick={() => navigate('/vendorshop', { state: { vendor_id: inventoryDetails.vendor_id } })} className="btn btn-outline-pink px-4 w-100">View Shop</button>
                    {/* <Link to="/vendorshop" className="btn btn-outline-pink px-4 w-100">View Shop</Link> */}
                  </div>

                </div>

              </div>
            </div>
          </section>

          <ProductDetails id={params.product_id} product_id={inventoryDetails?.product_id !== null && inventoryDetails?.product_id} inventoryDetails={inventoryDetails} productReviews={productReviews} />

          {/* ------products------ */}
          <section id="" className="my-5">
            <div className="container">
              {
                allInventory.length !== 0 &&
                <>
                  <h4>Related Products</h4>
                  <div className="d-flex flex-wrap gap-md-2 justify-content-around">
                    {
                      allInventory &&
                      allInventory.map((card, i) => {
                        const { last_cat_id, id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, itemExistInCart, itemExistInWishlist } = card;
                        return (
                          <div key={i} className="item">
                            <ProductCard
                              id={id}
                              product_id={product_id}
                              image={`${img_url}/${folder}/${image}`}
                              title={product_name}
                              mrp={mrp}
                              discounted_price={discounted_price}
                              shipping_charges={shipping_charges}
                              type={type}
                              text={convertToPlain(description)}
                              Card={card}
                              itemExistInCart={itemExistInCart}
                              itemExistInWishlist={itemExistInWishlist}
                              last_cat_id={last_cat_id}
                            />
                          </div>
                        )
                      })
                    }
                  </div>
                </>
              }
            </div>
          </section>
        </div>
      </main>


      <Footer />
    </>
  )
}

export default SeeAllFeature