import React, { useRef } from 'react'
import ReactToPrint, { PrintContextConsumer, useReactToPrint } from 'react-to-print';

const ReactPrint = () => {
    const componentRef = useRef();
    const pdfcontent=componentRef.current;
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <button onClick={handlePrint}>Print this out!</button>
            {/* <ReactToPrint content={pdfcontent}>
                <PrintContextConsumer>
                    {({ handlePrint }) => (
                        <button onClick={handlePrint}>Print this out!</button>
                    )}
                </PrintContextConsumer>
            </ReactToPrint> */}
            <div ref={componentRef}>
                <h2>Shivam</h2>
                <p>nhdfvdncksms</p>
            </div>
        </div>
    );
}
export default ReactPrint