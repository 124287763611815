import React, { useState } from 'react'

const MultiImageUpload = () => {
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);


    const createProductImagesChange
        =
        (e) => {
            const files = Array.from(e.target.files);
            setImages([]);
            setImagesPreview([]);
            files.forEach((file) => {
                const reader = new FileReader();
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setImagesPreview([
                            ...imagesPreview, reader.result]);
                        setImages([...images, reader.result]);

                    }
                };
                reader.readAsDataURL(file);
            });
        };

        console.log(images)

    return (
        <>
            {/* {
                images &&
                images.map((image, i) => {
                    return (
                        <div key={i} className="d-flex">
                            <img
                                alt="not found"
                                width={"100px"}
                                src={URL.createObjectURL(image)}
                            />
                        </div>
                    )
                })
            } */}
            <div id="createProductFormFile">
                <input type="file" name="avatar" onChange={createProductImagesChange} multiple />
            </div>
            <div id="createProductFormImage">
                {imagesPreview.map((image, index) => (
                    <div className='m-4'>
                        <img key={index} src={image} alt="Product Preview" width={100} />
                    </div>
                ))}
            </div>
        </>


    )
}

export default MultiImageUpload