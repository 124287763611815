import React, { useState } from 'react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const FileUpload = () => {
    const [selectedFile, setSelectedFile] = useState();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        const client = new S3Client({ region: "us-west-2" });
        const command = new PutObjectCommand({
            Bucket: "your_bucket_name",
            Key: selectedFile.name,
            Body: selectedFile,
        });

        try {
            const signedUrl = await getSignedUrl(client, command, { expiresIn: 3600 });
            console.log(`Successfully uploaded file. URL: ${signedUrl}`);
        } catch (err) {
            console.error("Error uploading file: ", err);
        }
    };

    return (
        <>
            {/* Images Uploading */}
            <div className="px-4 pb-4">
                <input type="file" onChange={handleFileChange} name="" accept="image/*" id="upload-img" />
                <label className="upload-img-label" htmlFor="upload-img"><img src="../assets/images/upload-image.png" alt="" /></label>
            </div>
            <div className="px-4 pb-4 text-end">
                <button type='submit' onClick={handleUpload} className="btn btn-pink rounded-0">Submit</button>
            </div>
        </>
    );
};

export default FileUpload;