import { configureStore } from "@reduxjs/toolkit";
import cartReducer from './CartSlice'
import variableReducer from './VariableSlice'
const store = configureStore({
    reducer : {
        cart : cartReducer,
        gVariables : variableReducer
    }
})

export default store;