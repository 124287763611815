import React, { useEffect } from 'react'
import { Link, NavLink, useNavigate,useLocation  } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext'
import '../assets/css/header.css';
import '../assets/css/custom_style.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Loading from './Loading'
import { useInventoryContext } from '../contexts/InventoryContext'


const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { api_url, isLoading, isAuthenticated, showNavbarOffCanvas, setShowNavbarOffCanvas, setIsAuthenticated, getCategories, categoryData, getCartItems, cartLength } = useGlobalContext();
    const { getPageInventory, searchKeyword, setSearchKeyword, getLastCategories } = useInventoryContext();

    // const [showNavbarOffCanvas, setShowNavbarOffCanvas] = useState(false)

    // const handleOffCanvasClose = () => setShowOffCanvas(false);
    // const handleOffCanvasShow = () => setShowOffCanvas(true);

    useEffect(() => {
        if (sessionStorage.getItem('token')) {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Get All Categories
    useEffect(() => {
        getCategories();
        getCartItems();
    }, []);


    useEffect(() => {
        setSearchKeyword('')
    }, [location]);



    const url = `${api_url}/pageInventory`;
    const handleSearch = () => {
        // getPageInventory(url);
        if(location.pathname!=='/marketplace')
        {
            // navigate('/marketplace')
            navigate({
                pathname: '/marketplace',
                search: `?search=${searchKeyword}`,
              });
        }
        // navigate('/marketplace')
        // navigate('/marketplace', { state: {'searchKeyword' : searchKeyword} });
    }

    const handleCategory = (cat_id, subcat_id, subcat2_id) => {
        getPageInventory(url, null, cat_id, subcat_id, subcat2_id);
        getLastCategories(cat_id, subcat_id, subcat2_id)
        navigate('/marketplace', { state: { cat_id: cat_id, subcat_id: subcat_id, subcat2_id: subcat2_id } })
    }

    // when sign out
    const handleLogout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('school_code');
        navigate('/');
        window.location.reload();
    }



    return (
        <>
            {isLoading ? <Loading /> : null}
            {/* {isLoading ? <SearchLoader /> : null} */}
            <header id="header" className="bg-light fixed-top">
                <nav className="navbar navbar-expand-lg border-bottom ">
                    <div className="container-fluid">

                        <NavLink to="/" className="navbar-brand logoimg">
                            <img src={require('../assets/images/logo.png')} alt="logo" />
                        </NavLink>

                        {/* <button className="navbar-toggler ms-auto border-0 p-0 ham" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="hamburger__toggle">
                                <span className="hamburger__icon"></span>
                            </span>
                        </button> */}
                        <button className="navbar-toggler ms-auto border-0 p-0" type="button" onClick={() => setShowNavbarOffCanvas(true)} aria-expanded="false" aria-label="Toggle navigation">
                            <span className="hamburger__toggle">
                                <span className="hamburger__icon"></span>
                            </span>
                        </button>

                        <form className="d-flex ">
                            <div className="input-group">
                                <input type="text" name='search' onChange={(e) => setSearchKeyword(e.target.value)} onKeyDown={(e) => {if (e.key === "Enter") handleSearch()}} value={searchKeyword!==null ? searchKeyword : ''} className="form-control border-right-0" placeholder="Search" />
                                <button onClick={handleSearch} className="btn border border-left-0" type="button">
                                    <i className="bi bi-search"></i>
                                </button>
                            </div>
                        </form>

                        <Offcanvas show={showNavbarOffCanvas} onHide={() => setShowNavbarOffCanvas(false)}>
                            <Offcanvas.Header closeButton>
                                {/* closeButton */}
                                {
                                    isAuthenticated ?
                                        <div className="bg-dark w-100">
                                            <div className="d-flex text-center ps-4 ms-2 mt-3" >
                                                <img src={require('../assets/images/pro.png')} className='rounded-circle' height={60} width={60} alt='' />
                                                <div className='text-white text-center fs-5 mt-4 ms-3'>{sessionStorage.getItem('username')}</div>
                                            </div>
                                            <h5 className='text-white ms-4 my-3'>Welcome to Evyapari</h5>
                                        </div>
                                        :
                                        <div className="bg-dark w-100">
                                            <div className="d-flex text-center ps-4 ms-2 mt-3" >
                                                <img src={require('../assets/images/pro.png')} className='rounded-circle' height={60} width={60} alt='' />
                                                <h6 className='ps-4 fs-4 text-white mt-3'>Hii, Guest</h6>
                                            </div>
                                            <div className='text-white text-center my-3'>
                                                <Link to="/login" onClick={() => setShowNavbarOffCanvas(false)} className='text-decoration-none h5 color-pink h4'>Login</Link>
                                                <span className='mx-2'> or</span>
                                                <Link to="/register" onClick={() => setShowNavbarOffCanvas(false)} className='text-decoration-none h5 color-pink h4'>Register</Link>
                                            </div>
                                        </div>
                                } </Offcanvas.Header>
                            <Offcanvas.Body>
                                <ul className="navbar-nav mb-lg-0 main-nav ms-lg-auto">
                                    <li className="nav-item">
                                        <NavLink to="/marketplace" onClick={() => setShowNavbarOffCanvas(false)} id='marketplace_link' className="nav-link">Marketplace</NavLink>
                                    </li>
                                    {sessionStorage.getItem('school_code') &&
                                        <>
                                            <div className="seperate-line me-3"></div>
                                            <li className="nav-item school">
                                                <NavLink to="/myschool" onClick={() => setShowNavbarOffCanvas(false)} className="nav-link myschool">My School</NavLink>
                                            </li>
                                            <div className="seperate-line ms-3"></div>
                                        </>
                                    }
                                </ul>

                                <div class="accordion accordion-flush" id="outerAccordion">
                                    {
                                        categoryData.length !== 0 &&
                                        categoryData.map((ctg, i) => {
                                            const { cat_name, cat_id, subcat } = ctg;
                                            return (
                                                <div key={i} class="accordion-item">
                                                    <div class="accordion-header" id={`heading${i}`}>
                                                        <button
                                                            class="accordion-button collapsed w-100 fw-bold d-flex justify-content-between careticon py-1"
                                                            data-bs-toggle="collapse" data-bs-target={`#collapseOne${i}`} aria-expanded="true"
                                                            aria-controls="collapseOne">

                                                            <p class="fs-6 my-0">{cat_name}</p>
                                                        </button>
                                                    </div>
                                                    <div id={`collapseOne${i}`} class="accordion-collapse collapse" aria-labelledby={`heading${i}`}>
                                                        <div class="accordion-body py-0">
                                                            <div class="accordion" id="innerAccordion">
                                                                {
                                                                    subcat.map((subcat, i) => {
                                                                        const { subcat_name, subcat_id, subcat2 } = subcat;
                                                                        return (
                                                                            <div key={i} class="accordion-item">
                                                                                <h3 class="accordion-header d-flex justify-content-between"
                                                                                    id={`innerHeading${i}`}>
                                                                                    <button class="accordion-button collapsed w-100 d-flex justify-content-between py-1"
                                                                                        type="button" data-bs-toggle="collapse"
                                                                                        data-bs-target={`#innerCollapseOne${i}`} aria-expanded="true"
                                                                                        aria-controls={`innerCollapseOne${i}`}>
                                                                                        <p class="my-0">{subcat_name}</p>
                                                                                    </button>
                                                                                </h3>
                                                                                <div id={`innerCollapseOne${i}`} class="accordion-collapse collapse "
                                                                                    aria-labelledby={`innerHeading${i}`} >
                                                                                    {
                                                                                        subcat2.map((subcat2, i) => {
                                                                                            const { subcat2_name, subcat2_id } = subcat2;
                                                                                            return (
                                                                                                <div key={i} class="accordion-body ps-5 py-1">
                                                                                                    <span onClick={() => handleCategory(cat_id, subcat_id, subcat2_id)}>{subcat2_name}</span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>

                        <ul className="d-none d-lg-flex navbar-nav mb-lg-0 ms-auto">
                            <li className="nav-item">
                                <NavLink to="/marketplace" id='marketplace_link' className="nav-link me-3">Marketplace</NavLink>
                            </li>
                            {sessionStorage.getItem('school_code') &&
                                <>
                                    <div className="seperate-line me-3"></div>
                                    <li className="nav-item school">
                                        <NavLink to="/myschool" className="nav-link myschool">My School</NavLink>
                                    </li>
                                    <div className="seperate-line ms-3"></div>
                                </>
                            }
                        </ul>

                        <ul className="d-none d-lg-flex navbar-nav mb-lg-0 w-100 start-0 justify-content-center position-absolute secondary-nav border border-0 category ">
                            {
                                categoryData.length !== 0 &&
                                categoryData.map((ctg, i) => {
                                    const { cat_name, cat_id, subcat } = ctg;
                                    return (
                                        <li key={i} className="nav-item dropdown dropdown-hover has-megamenu ">
                                            <Link className="nav-link " href="#" id="navbarDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                                {cat_name}
                                            </Link>
                                            <div className="dropdown-menu megamenu scrolling" aria-labelledby="navbarDropdown">
                                                <div className="row g-0 overflow-auto">
                                                    {
                                                        subcat.map((subcat, i) => {
                                                            const { subcat_name, subcat_id, subcat2 } = subcat;
                                                            return (
                                                                <div key={i} className="col">
                                                                    <h6 className="categary-heading">{subcat_name}</h6>
                                                                    <ul className="list-unstyled">
                                                                        {
                                                                            subcat2.map((subcat2, i) => {
                                                                                const { subcat2_name, subcat2_id } = subcat2;
                                                                                return (
                                                                                    <li className='list-item' key={i}>
                                                                                        <div className=''>
                                                                                            <span className='cursor-pointer' onClick={() => handleCategory(cat_id, subcat_id, subcat2_id)}>{subcat2_name}</span>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                        <ul className="icon-toggle list-inline mb-lg-0 mt-3 mt-lg-0 pt-2 headlogin me-2">
                            {
                                isAuthenticated ?
                                    <li className="list-inline-item me-0 ms-md-4">
                                        <div className="dropdown">
                                            <Link className="d-flex flex-column align-items-center" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                                                <div className="d-flex flex-column align-items-center">
                                                    <div><i className="bi bi-person headicon"></i></div>
                                                    <div className="login">Profile</div>
                                                </div>
                                            </Link>

                                            <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuButton">
                                                <h5>Hii {sessionStorage.getItem('username')}</h5>
                                                <div className='mb-1'>Welcome to E-vyapari</div>
                                                <div><Link to="/myprofile" className='text-danger'><i className=" bi bi-person"></i> My Profile</Link></div>
                                                <div><Link to="/myorders" className='text-danger'><i className="bi bi-bag"></i> My Orders</Link></div>
                                                <hr />
                                                <div onClick={() => handleLogout()} className="cursor-pointer w-100">
                                                    <i class="bi bi-box-arrow-right"></i> Sign Out
                                                </div>
                                                {/* <button onClick={() => handleLogout()} className="btn btn-pink w-100"> Sign Out </button> */}
                                            </div>
                                        </div>
                                    </li>
                                    :
                                    <li className="list-inline-item me-0">
                                        <Link to='/login'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-person headicon"></i></div>
                                                <div className="login">Login</div>
                                            </div>
                                        </Link>
                                    </li>
                            }
                            <li className="list-inline-item mx-3 mx-sm-4">
                                <Link to="/mywishlist">
                                    <div className="d-flex flex-column align-items-center">
                                        <div><i className="bi bi-heart headicon"></i> </div>
                                        <div className="login">Wishlist</div>
                                    </div>
                                </Link>
                            </li>

                            <li className="list-inline-item">
                                <Link to="/cart">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className={cartLength > 0 ? 'cart-animation' : ''}>
                                            <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                <i className="bi bi-cart headicon"></i>
                                            </div>
                                        </div>
                                        <div className="login">Cart</div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>

            </header >
        </>
    )
}

export default Header