import React, { useRef } from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'


function Pdf() {
    const pdfRef = useRef();

    const downloadPdf = () => {
        const htmlcontent = document.getElementById('content');
        html2canvas(htmlcontent, {}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');

            const pageWidth = 210;
            const pageHeight = 297;
            const pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(imgData, 'PNG', 0, 0, pageWidth, pageHeight);
            pdf.save('invoice.pdf');
        })




        // const input = pdfRef.current;
        // html2canvas(input).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     const pdf = new jsPDF('p', 'mm', 'a4', true);
        //     const pdfWidth = pdf.internal.pageSize.getWidth();
        //     const pdfHeight = pdf.internal.pageSize.getHeight();
        //     const imgWidth = canvas.width;
        //     const imgHeight = canvas.height;
        //     const ratio = Math.min(pdfWidth / imgWidth / pdfHeight / imgHeight);
        //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
        //     const imgY = 30;
        //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth*ratio, imgHeight*ratio);

        //     pdf.save('invoice.pdf')
        // })
    }
    return (
        <>
            <div ref={pdfRef} id='content'>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque cum deserunt praesentium sapiente dolore, in pariatur itaque ex, inventore fugit aliquid voluptatibus, doloribus qui? Tempora iure ducimus eum quasi modi! Ab animi corrupti dignissimos!</p>
            </div>
            <div>
                <button onClick={downloadPdf}>Download PDF</button>
            </div>
        </>

    )
}

export default Pdf