import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import Loading from '../Components/Loading'
import Footer from '../Components/Footer';
import '../assets/css/myschool.css'
import { Link } from 'react-router-dom'

const MySchool = () => {
    const { userId, schoolCode, navigate, img_url, getSchoolInformation, schoolData, isLoading, sessionType, setCartLength, api_url, schoolSetData, setIsLoading, getSchoolSet } = useGlobalContext();
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [SchoolCode, setSchoolCode] = useState('');
    const [cats, setCats] = useState([]);

    useEffect(() => {
        getSchoolSet();
        getSchoolInformation();
        getSetCategories();
    }, []);

    //getSetCategories
    const getSetCategories = async () => {
        const data = {
            'school_code': schoolCode
        };
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/getSetCategories`, data);
            if (res.data.success === 1) {
                setCats(res.data.data)
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    console.log("Categories", cats)

    //addSetToCart
    const addSetToCart = async (set_id, vendor_id, set_qty, redirect = false) => {
        const data = {
            'user_id': userId,
            'session_type': sessionType,
            'set_id': set_id,
            'vendor_id': vendor_id
        };

        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/addSetToCart`, data);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored'
                })
                setCartLength((prev) => prev + set_qty);
                setShowPreviewModal(false);
            }
            else if (res.data.success === 0 && redirect === false) {
                toast.error(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
                setShowPreviewModal(false);
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
            if (redirect) {
                navigate('/cart')
            }
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Header />
            <main id='main'>
                {/* <!-----------------------------------Banner Starts---------------------------------> */}
                <section id="school_header" className='py-md-5'>
                    <div className='container-fluid p-0'>
                        <div className='d-md-none'>
                            {
                                schoolData.school_banner !== null ?
                                    <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school banner" className="img-fluid d-block mx-auto" />
                                    :
                                    <img src={require('../assets/images/school_banner_2.jpeg')} alt="banner" className="img-fluid d-block mx-auto" />
                            }
                        </div>
                    </div>
                    <div className="container">
                        <div className='d-md-block d-none border border-4 rounded-end-3 border-danger'>
                            <div className="row g-0">
                                <div className="col-lg-7">
                                    {
                                        schoolData.school_banner !== null ?
                                            <img src={`${img_url}/${schoolData.folder}/${schoolData.school_banner}`} alt="school banner" className="img-fluid d-block mx-auto" />
                                            :
                                            <img src={require('../assets/images/school_banner_2.jpeg')} alt="banner" className="img-fluid d-block mx-auto" />
                                    }
                                </div>
                                <div className="col-lg-5">
                                    <div className='d-flex justify-content-center align-items-center bg-orange h-100'>
                                        <div className='my-4 text-center'>
                                            <p className='h4'>{schoolData.school_name}</p>
                                            <ul className="list-unstyled">
                                                <li>{schoolData.village && schoolData.village}{schoolData.landmark && `, ${schoolData.landmark}`}</li>
                                                <li>{schoolData.zipcode && `Pincode: ${schoolData.zipcode}`}{schoolData.post_office && `, Post Office: ${schoolData.post_office}`}</li>
                                                <li>{schoolData.city && schoolData.city}{schoolData.distt && `, ${schoolData.distt}`}{schoolData.state && `, ${schoolData.state}`}</li>
                                            </ul>
                                            <div className='d-flex justify-content-center'>
                                                <input type="text" className="form-control w-50 rounded-end-0" onChange={(e) => setSchoolCode(e.target.value)} name='SchoolCode' value={SchoolCode} id="SchoolCode" placeholder="School Code" />
                                                <button className='btn btn-pink rounded-start-0' onClick={() => navigate('/myinformation', { state: { schoolcode: SchoolCode } })}>Change School</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-----------------------------------Banner Ends-----------------------------------> */}

                <section id="category-product myschool" className="pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className=''>
                                    <h3 className="bg-pink text-center text-white mb-0 p-1">Set Categories</h3>
                                    <div className="accordion border border-3 border-danger" id="accordionExample">

                                        {
                                            cats.map((cat, i) => {
                                                const { cat_title, cat_id, subcats } = cat;
                                                return (
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header p-2" id={`headingOne${i}`}>
                                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} aria-expanded="true" aria-controls={`collapse${i}`}>
                                                                <span className='h5 mb-0'>{cat_title}</span>
                                                            </button>
                                                        </h2>
                                                        <div id={`collapse${i}`} className="accordion-collapse collapse" aria-labelledby={`headingOne${i}`} data-bs-parent="#accordionExample">
                                                            <div className="accordion-body pt-0">
                                                                {
                                                                    subcats.map(subcat => {
                                                                        const { class_title, class_id } = subcat;
                                                                        return (
                                                                            <>
                                                                                <div className='border-bottom border-bottom-2 border-gray py-1'>
                                                                                    <span data-bs-toggle="collapse" data-bs-target={`#collapse${i}`} className='cursor-pointer' onClick={() => getSchoolSet(cat_id, class_id)}>Class <strong>{class_title}</strong></span>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-9 gap-5">
                                <div id="book-set">
                                    <div className="row">
                                        {
                                            schoolSetData.map((set, i) => {
                                                const {
                                                    id, set_id, vendor_id, vendor, set_qty,
                                                    setclass, cat_title, type_title,
                                                    board, org_title, grade, set_price, total_discount,
                                                    discounted_price, set_items, stock_status
                                                } = set;

                                                return (
                                                    <>
                                                        <div key={i} className="col-md-3 my-3">
                                                            <div id="flip-card">
                                                                <div className="book">
                                                                    <div className="">
                                                                        <table className="f-sm">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">S.No</th>
                                                                                    <th scope="col">Image</th>
                                                                                    <th scope="col">Name</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    set_items.slice(0, 4).map((item, i) => {
                                                                                        return (
                                                                                            <tr key={i}>
                                                                                                <td>{i + 1}</td>
                                                                                                <td><img src={`${img_url}/${item.img}`} alt="book1" className="book-img-size" /> </td>
                                                                                                <td>{item.itemname}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                        <div className='d-flex justify-content-center gap-2'>
                                                                            <button className="btn btn-outline-pink mt-2" data-bs-toggle="modal" data-bs-target={`#schoolsetmodal${i}`}>Preview</button>
                                                                            {
                                                                                stock_status === 0 ?
                                                                                <button className="btn btn-pink mt-2">Out of Stock</button>
                                                                                :
                                                                                <button onClick={() => addSetToCart(set_id, vendor_id, set_qty, true)} className="btn btn-pink mt-2">Buy Now</button>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="school_set_cover">
                                                                        <div className="card mx-auto">
                                                                            <div className="overflow-hidden schoolset_img_contain position-relative">
                                                                                <img src={require("../assets/images/my_school/set_cover_3.jpg")} className="mx-auto img-fluid rounded position-relative" alt="..." />
                                                                            </div>
                                                                            <div className="position-absolute text-light text-center h-100 w-100 p-4 bg-dark-shade">
                                                                                <div>
                                                                                    <div className='fw-bolder shadow'><span className='h4'>{setclass}</span> Class (Book Set)</div>
                                                                                    <div><del className='s-font2'>₹{set_price}</del><span className='fw-semibold'> ₹{discounted_price}</span></div>
                                                                                    <div>({type_title})</div>
                                                                                    <div>Sold By : {vendor}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/*************************************modal*****************************/}

                                                            <div id="sets-table">
                                                                {
                                                                    <div key={i} className="modal fade modal-lg" id={`schoolsetmodal${i}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered">
                                                                            <div className="modal-content">
                                                                                <div className="modal-header bg-pink text-white">
                                                                                    <h5 className="modal-title">Set Items</h5>
                                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                                </div>
                                                                                <div className="modal-body">
                                                                                    <table className="table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>S.No</th>
                                                                                                <th>Image</th>
                                                                                                <th>Name</th>
                                                                                                <th>Class</th>
                                                                                                <th>Price</th>
                                                                                                <th>Publisher</th>
                                                                                                <th>QTY</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                set_items.map((item, i) => {
                                                                                                    return (
                                                                                                        <tr >
                                                                                                            <td data-label="S.No">{i + 1}</td>
                                                                                                            <td data-label="Image"><img src={`${img_url}/${item.img}`} alt="book1" className="book-img-size" /></td>
                                                                                                            <td data-label="Name">{item.itemname}</td>
                                                                                                            <td data-label="Class">{item.classno}</td>
                                                                                                            <td data-label="Price"><del>₹{item.price}</del> ₹{item.new_price}</td>
                                                                                                            <td data-label="Publisher">{item.company_name}</td>
                                                                                                            <td data-label="QTY">{item.qty}</td>
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </tbody>
                                                                                    </table>
                                                                                    <div>
                                                                                        <h6>Total Price : <span className="text-danger">₹{set_price}</span></h6>
                                                                                        <h6>Discount Price : <span className="text-danger">₹{total_discount}</span></h6>
                                                                                        <h6>Final Price : <span className="text-danger">₹{discounted_price}</span></h6>
                                                                                        {
                                                                                            stock_status === 0 ?
                                                                                            <button data-bs-dismiss="modal" className="btn btn-pink"> Out of Stock</button>
                                                                                            :
                                                                                            <button onClick={() => addSetToCart(set_id, vendor_id, set_qty)} data-bs-dismiss="modal" className="btn btn-pink">Add To Cart</button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
            {/* <Footer /> */}
        </>
    )
}

export default MySchool