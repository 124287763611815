import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../contexts/GlobalContext';

const UploadImage = () => {
    const { api_url } = useGlobalContext();
    const [image, setImage] = useState('');

    const handleImage = (e) => {
        console.log('Image : ', e.target.files)
        setImage(e.target.files[0])
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('review_image', image)
        console.log('Review', formData)

        try {
            const res = await axios.post(`${api_url}/addReview`, formData)
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 1500,
                    theme: "colored",
                });
                console.log(res.data)
            } else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.error("Axios Error", {
                autoClose: 1500,
                theme: "colored",
            });
        }
    }
    return (
        <>
            <div>UploadImage</div>
            <input type="file" name="review_image" onChange={handleImage} accept="image/*" />
            <button onClick={handleSubmit} className="btn btn-pink rounded-0">Submit</button>
        </>


    )
}

export default UploadImage