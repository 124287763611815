import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { useGlobalContext } from '../contexts/GlobalContext'
import * as Icon from 'react-bootstrap-icons';
import { useInventoryContext } from '../contexts/InventoryContext'

const ProductCard = (props) => {
    const { last_cat_id, id, product_id, image, title, mrp, discounted_price, text, type, shipping_charges, itemExistInCart, itemExistInWishlist } = props;
    const [showModal, setShowModal] = useState(false);
    const { handleAddToWishlist, removeWishlistItem, handleBuyNow } = useGlobalContext();
    const { handleAddToCart } = useInventoryContext();
    // const [existInCart, setExistInCart] = useState(itemExistInCart)
    const navigate = useNavigate();
    const location = useLocation();


    const clickSeeAllFeature = () => {
        // alert(id)
        navigate(`/seeallfeature/${id}`, { state: { 'last_cat_id': last_cat_id } })
        if (location.pathname.includes('/seeallfeature')) {
            setShowModal(false);
        }
        window.scrollTo(0, 0)
    }

    //for quick view
    const AddToCart = (id) => {
        handleAddToCart(id);
        setShowModal(false)
    }

    return (
        <>
            {/* ------Product Card------ */}
            <div className="product-card card ">
                <div className="overflow-hidden card-img-contain">
                    <img src={image} className="card-img-top zoom mx-auto rounded" alt="..." />
                    <button onClick={() => setShowModal(true)} className="btn text-white qck-btn px-2 py-1">Quick View</button>
                </div>
                <div className="card-body">
                    <div className="card-title h6">{id} - {title.slice(0, 40)}{title > title.slice(0, 43) ? "..." : null}</div>
                    <div>
                        {
                            mrp > discounted_price &&
                            <del className='text-secondary h6'>₹{mrp}</del>
                        }
                        <span className='h5'> ₹{discounted_price}</span>
                    </div>
                    <div className='d-flex justify-content-between my-2'>
                        <span className="badge rounded-pill bg-light pt-2 px-2 px-md-3 text-secondary product_card_delivery_text">
                            {
                                shipping_charges ? `Delivery ₹${shipping_charges}` : 'Free Delivery'
                            }
                        </span>
                        {
                            itemExistInWishlist ?
                                <span className='cursor-pointer' onClick={() => removeWishlistItem(id)}><i className="bi bi-heart-fill fs-5 color-pink"></i></span>
                                :
                                <span className='cursor-pointer' onClick={() => handleAddToWishlist(id)}><i className="bi bi-heart fs-5 color-pink"></i></span>
                        }
                    </div>
                    <div className="d-flex d-sm-block gap-2">
                        {
                            itemExistInCart ?
                                <>
                                    <NavLink to="/cart" className="d-none d-sm-block btn btn-orange w-100 py-1 mb-2">
                                        <i className="fa-sharp fa-solid fa-cart-shopping me-2"></i>
                                        View Cart
                                    </NavLink>
                                    <NavLink to="/cart" className="d-block d-sm-none btn btn-orange rounded-circle product_addtocart_icon d-flex justify-content-center align-items-center">
                                        <i className="bi bi-cart mx-auto"></i>
                                    </NavLink>
                                </>
                                :
                                <>
                                    <button className="d-none d-sm-block btn btn-pink w-100 py-1 mb-2" onClick={() => handleAddToCart(id)}>
                                        Add To Cart
                                    </button>
                                    <button className="d-block d-sm-none btn btn-outline-pink rounded-circle product_addtocart_icon d-flex justify-content-center align-items-center" onClick={() => handleAddToCart(id)}>
                                        <i className="bi bi-cart mx-auto"></i>
                                    </button>
                                </>
                        }
                        <button className="d-block d-sm-none btn btn-pink w-100 r_font1" onClick={() => handleBuyNow(id)}>
                            Buy Now
                        </button>
                        <button className="d-sm-block d-none btn btn-pink w-100 py-1 r_font1" onClick={() => handleBuyNow(id)}>
                            <i className="bi bi-chevron-double-right me-2 "></i>
                            Buy Now
                        </button>
                    </div>
                </div>
            </div>
            {/* ------Quick View Modal------ */}
            <Modal size="lg" autoFocus={false} centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header className='text-white' closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 py-sm-1 pb-5">
                            <img src={image} className="card-img-top mx-auto" alt="..." />
                        </div>
                        <div className="col-lg-6">
                            <h4>{title}</h4>
                            <div className="d-flex">
                                <div className='me-3'>
                                    {
                                        mrp > discounted_price &&
                                        <del className='h5 text-secondary'>₹{mrp}</del>
                                    }
                                    <span className='h4'> ₹{discounted_price}</span>
                                </div>
                                <span className="badge rounded-pill bg-light py-2 px-3 me-3 text-secondary h5">
                                    {
                                        shipping_charges ? `Delivery ₹${shipping_charges}` : 'Free Delivery'
                                    }
                                </span>
                                <span className='cursor-pointer' onClick={() => handleAddToWishlist(id)}><i className="bi bi-heart fs-4"></i></span>
                            </div>
                            <p>{type}</p>
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <button className="btn btn-pink w-100" onClick={() => AddToCart(id)}><i className="bi bi-cart-fill"></i> Add to Cart</button>
                                </div>
                                <div className="col-md-6">
                                    <button className="btn btn-pink w-100" onClick={() => handleBuyNow(id)}><i className="bi bi-chevron-double-right"></i> Buy Now</button>
                                </div>
                            </div>
                            <div className="mt-4">
                                {/* <Link to={`/seeallfeature/${id}`} className="w-100">
                                    <button className='btn btn-outline-pink' onClick={() => clickSeeAllFeature(id)}>See All Feature</button>
                                </Link> */}
                                <button onClick={clickSeeAllFeature} className="btn btn-outline-pink w-100">See All Feature</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProductCard