import React from 'react'
import {FaStar, FaStarHalfAlt} from 'react-icons/fa'
import {AiOutlineStar} from 'react-icons/ai'

const ShowRating = ({stars=3.5}) => {
    const ratingStar = Array.from({length: 5}, (elem, index) => {
        let number = index +0.5;
        return (
            <span key={index}>
                {
                    stars>= index +1 ? (
                        <FaStar className='icon' color="#ffc107"/>
                    ) : stars >= number ? (
                        <FaStarHalfAlt className='icon' color="#ffc107"/>
                    ) : (
                        <AiOutlineStar className='icon' color="#ffc107"/>
                    )
                }
            </span>
        )
    })
  return (
    <div className='icon-style'>
        {ratingStar}
    </div>
  )
}

export default ShowRating