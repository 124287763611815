import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Components/Header';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import { useLocation } from 'react-router-dom';
import { FaStar } from 'react-icons/fa'

const GiveReview = (props) => {
   const location = useLocation()
   const { product_id, item_type, vendor_id } = location?.state
   const { api_url, userId } = useGlobalContext();
   const [selectedImage, setSelectedImage] = useState(null);
   const [image, setImage] = useState('');
   const [images, setImages] = useState([]);
   const [imageURLS, setImageURLs] = useState([]);

   const [rating, setRating] = useState(null);
   const [hover, setHover] = useState(null);

   const [reviewData, setReviewData] = useState({
      'rating': rating,
      'review_comment': '',
      'review_image': '',
      'user_id': userId,
      'product_id': product_id,
      'item_type': item_type,
      'vendor_id': vendor_id
   });
   const [reviewErrors, setReviewErrors] = useState({});

   const handleChange = (e) => {
      setReviewData({ ...reviewData, [e.target.name]: e.target.value })
   }

   useEffect(() => {
      if (images.length < 1) return;
      const newImageUrls = [];
      images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
      setImageURLs(newImageUrls);
   }, [images]);


   

    const handleImage = (e) => {
        console.log('Image : ', e.target.files)
        setImage(e.target.files[0])
    }
   function handleImage3(e) {
      setImages([...e.target.files]);
   }

   const handleImage2 = (e) => {
      console.log('Image : ', e.target.files)
      // setReviewData({ ...reviewData, review_image: e.target.files[0] })
      setImages(...images, ...e.target.files)
   }

   const ReviewValidation = () => {
      const errors = {};
      if (!reviewData.review_comment) {
         errors.review_comment = 'Please Enter Discription';
      }
      return errors;
   }

   const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();

      formData.append('review_image', image)
      formData.append('review_comment', reviewData.review_comment)
      formData.append('user_id', reviewData.user_id)
      formData.append('product_id', reviewData.product_id)
      formData.append('item_type', reviewData.item_type)
      formData.append('vendor_id', reviewData.vendor_id)
      formData.append('rating', rating)

      console.log('Review', formData)
      const errors = ReviewValidation();
      setReviewErrors(errors);

      if (Object.keys(errors).length === 0) {
         try {
            const res = await axios.post(`${api_url}/addReview`, formData)
            if (res.data.success === 1) {
               toast.success(res.data.message, {
                  autoClose: 1500,
                  theme: "colored",
               });
               console.log(res.data)
            } else {
               toast.error('Something Went Wrong', {
                  autoClose: 1500,
                  theme: "colored",
               });
            }
         } catch (error) {
            toast.error("Axios Error", {
               autoClose: 1500,
               theme: "colored",
            });
         }
      }
   }

   return (
      <>
         <Header />
         <main id='main'>
            <section id="give-review" className="py-3 bg-light">
               <div className="container-fluid">
                  <div className="b-shadow-2 p-4 bg-white mb-3">
                     <h4>Rating & Reviews</h4>
                  </div>
                  <div className="row">
                     {/* <!-- Filter --> */}
                     <div className="col-sm-3 mb-4">
                        <div className="b-shadow-2 bg-white p-3">
                           <h5>What makes a good review</h5>
                           <hr />
                           <div>
                              <h6>Have you used this product?</h6>
                              <p className="s-font2">Your review should be about your experience with the product.</p>
                           </div>
                           <hr />
                           <div>
                              <h6>Why review a product?</h6>
                              <p className="s-font2">Your valuable feedback will help fellow shoppers decide!</p>
                           </div>
                           <hr />
                           <div>
                              <h6>How to review a product?</h6>
                              <p className="s-font2">Your review should include facts. An honest opinion is always appreciated. If you have an issue with the product or service please contact us from the help centre.</p>
                           </div>
                        </div>
                     </div>
                     {/* <!-- filter ends --> */}

                     {/* <!-- my orders  --> */}
                     <div className="col-sm-9">
                        <div className="b-shadow-2 bg-white">
                           <div className="p-4 pb-0">
                              <p className="h5">Rate this product</p>
                              <div>
                                 {[...Array(5)].map((star, index) => {
                                    const currentRating = index + 1;
                                    return (
                                       <label>
                                          <input
                                             type='radio'
                                             name='rating'
                                             value={currentRating}
                                             onClick={() => setRating(currentRating)}
                                             className='d-none'
                                          />
                                          <FaStar
                                             className='cursor-pointer'
                                             size={40}
                                             color={currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                             onMouseEnter={() => setHover(currentRating)}
                                             onMouseLeave={() => setHover(null)}
                                          />
                                       </label>
                                    )
                                 })}
                              </div>
                           </div>
                           <hr />
                           {/* <h2>{product_id}</h2> */}
                           <form action="">
                              <div className="px-4 mb-4">
                                 <p className="h5">Review this product</p>
                                 <div className="form-floating border-0">
                                    <textarea className="form-control" name='review_comment' value={reviewData.review_comment} onChange={(e) => handleChange(e)} placeholder="Leave a comment here" id="discription"></textarea>
                                    <label htmlFor="discription">Description</label>
                                    <div className="text-danger">{reviewErrors.review_comment}</div>
                                 </div>
                                 
                              </div>
                              {/* Images Uploading */}
                              {selectedImage && (
                                 <div>
                                    <img
                                       alt="not found"
                                       width={"250px"}
                                       src={URL.createObjectURL(image)}
                                    />
                                    <br />
                                    <button onClick={() => setSelectedImage(null)}>Remove</button>
                                 </div>
                              )}

                              {
                                 image &&
                                 <div className="px-4 mb-2">
                                    <img
                                       alt="not found"
                                       width={"100px"}
                                       src={URL.createObjectURL(image)}
                                    />
                                 </div>
                              }
                              <div className="px-4 mb-4">
                                 <input type="file" multiple name="review_image" onChange={handleImage} accept="image/*" />
                              </div>

                              <div className="px-4 pb-4 text-end">
                                 <button onClick={handleSubmit} className="btn btn-pink rounded-0">Submit</button>
                              </div>

                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </main>


         <ToastContainer />
      </>
   )
}

export default GiveReview