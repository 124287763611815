import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';

const OtpLogin = () => {
  const {api_url, setIsLoading} = useGlobalContext();
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const location = useLocation();

  const [otpData, setOtpData] = useState({
    otp: location.state.otp,
  });

  const handleChange = (e) => {
    setOtpData({ ...otpData, [e.target.name]: e.target.value })
  }

  const handleVerify = (e) => {
    e.preventDefault();

    let error = {};
    if (!otpData.otp) {
      error.otp = 'Please enter OTP';
    }
    setError(error);

    if (Object.keys(error).length === 0) {
      const data = {
        'user_id': location.state.user_id,
        'otp': otpData.otp,
        'otp_type':'login'
      }
      setIsLoading(true)
      axios.post(`${api_url}/verifyOtp`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message}`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              sessionStorage.setItem('token',  res.data.data.token);
              sessionStorage.setItem('username',  res.data.data.name);
              sessionStorage.removeItem('guest_id')
              sessionStorage.setItem('user_id',  res.data.data.user_id);
              res.data.data.school_code !== null && sessionStorage.setItem('school_code', res.data.data.school_code);
              navigate('/')
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};
    if (!otpData.otp) {
      error.otp = 'Please enter OTP';
    }
    setError(error);

    if (Object.keys(error).length === 0) {

    }
  }

  return (
    <>
      <Header />
      <main id='main' className='h-100'>
        <section id="" className=' h-100'>
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
              <div className="text-center pt-5">
              {/* <div className="mx-auto p-4"><img src="../assets/images/logo.png" className="d-block mx-auto" width="150" alt="" /></div> */}
              <div className="f-shadow bg-white">
                <div className="mx-auto h6 m-0 p-3 bg-pink text-white">Login With OTP</div>
                <form className="mx-auto border p-4">
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control" onChange={handleChange} name='otp' value={otpData.otp} id="otp" placeholder="Password" />
                    <label htmlFor="otp">Enter OTP<span className="text-danger">*</span></label>
                    <div className="text-danger">{error.otp}</div>
                  </div>
                  <div className="row">
                    {/* <div className="col"><Link to='' className="btn btn-outline-pink">Resend OTP</Link></div> */}
                    <div className="col"><button onClick={handleVerify} className="btn btn-pink d-block ms-auto">Verify</button></div>
                  </div>
                </form>
              </div>
            </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  )
}

export default OtpLogin