import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../Components/Loading';
import { toast } from 'react-toastify';

const MyAddress = () => {
   const { isLoading, schoolCode, setIsLoading, api_url, userId, userInformation, getUserInformation, allShippingAddress, setAllShippingAddress, getAllShippingAddress } = useGlobalContext();


   //get All Shipping Addresses
   useEffect(() => {
      getUserInformation();
      getAllShippingAddress();
   }, []);

   // Set Default Address
   const SetDefaultAddress = (address_id) => {
      const data = {
         user_id: userId,
         address_id: address_id
      }
      setIsLoading(true)
      axios.post(`${api_url}/defaultAddress`, data)
         .then(res => {
            if (res.data.success === 1) {
               setIsLoading(false)
               toast.success('Default Address is set Successfully', {
                  autoclose: 1500
               })
               setAllShippingAddress(AllAddress =>
                  AllAddress.map(Address =>
                     Address.id === address_id ? { ...Address, default_address: 1 } : { ...Address, default_address: 0 }
                  )
               )
            } else {
               setIsLoading(false)
               toast.success('Something Went Wrong', {
                  autoclose: 1500
               })
            }
         })
         .catch(err => {
            setIsLoading(false);
            toast.error('Axios Error', {
               autoclose: 1500
            })
            console.log(err)
         })

   }


   const handleAddressRemove = (address_id) => {
      const data = {
         address_id: address_id,
      }
      setIsLoading(true)
      axios.post(`${api_url}/removeAddress`, data)
         .then(res => {
            if (res.data.success === 1) {
               setIsLoading(false)
               toast.success(res.data.message, {
                  autoclose: 1000,
                  theme:'colored'
               })
               setAllShippingAddress(AllAddress =>
                  AllAddress.filter(Address =>
                     Address.id !== address_id
                  )
               )
            } else {
               setIsLoading(false)
               toast.error(res.data.message, {
                  autoclose: 1500
               })
            }
         })
   }

   // useEffect(() => {
   //    if(!isAuthenticated) {
   //       navigate('/')
   //    }
   // }, [])


   return (
      <>
         {isLoading ? <Loading /> : null}
         <Header />
         <main id='main'>
            <section id="my-address" className="">
               <div className="container">
                  <div className='mt-2'>
                     <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
                     <span> <i class="bi bi-chevron-right f-sm"></i> My Address</span>
                  </div>
                  <h3 className='mb-3 mt-5'>Your Shipping Address</h3>
                  <Link to='/addaddress' className='color-pink text-decoration-none'>
                     <div className="border b-shadow rounded p-3 w-25 d-flex align-items-center gap-3 justify-content-center">
                        <p className="h5 color-pink mt-2">Add a New Address</p>
                        <i className="bi bi-plus-square fs-3"></i>
                     </div>
                  </Link>

                  <div className="row g-5 mt-0">
                     {
                        (allShippingAddress.length !== 0) ?
                           allShippingAddress.map((address, i) => {
                              const { id, user_id, name, phone_no, alternate_phone, school_name, default_address, village, city, address_type, state, district, post_office, pincode } = address;
                              let address_id = id;
                              return (
                                 <div key={i} className="col-lg-6">
                                    <div className={`${default_address === 1 && 'active-address'} border b-shadow rounded p-3 h-100 position-relative`}>
                                       <div className="row">
                                          <div className="col-3">
                                             <div className='d-flex justify-content-center'>
                                                <img src={require("../assets/images/account_img/home.png")} className="d-block img-fluid me-2" height="100" width="100" alt="" />
                                             </div>
                                             {/* <div className='mt-3'>
                                                <input type="checkbox" className='form-check-input' name="" id="primary-add" />
                                                <label htmlFor='primary-add' className='ms-1 s-font2  '>Set as Primary Address </label>
                                             </div> */}
                                          </div>
                                          <div className="col-9">
                                             <p className="h5">{address_type === 1 ? 'Home' : `School ${schoolCode} (Pickup Address)`}</p>
                                             <ul className="list-unstyled">
                                                <li>{name}, {phone_no}{alternate_phone && `, ${alternate_phone}`}</li>
                                                {address_type === 2 && <li>School Name : {school_name}</li>}
                                                <li>{`${village}`}{address.address && `, Near ${address.address}`}</li>
                                                <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                <li>{city && `${city}, `}{`${district}, ${state}`}</li>
                                             </ul>
                                             <div>
                                                {address_type === 1 &&
                                                   <>
                                                      <Link to={`/editaddress/${address_id}`} className='text-decoration-none'><span className='color-pink s-font2'>Edit</span></Link>
                                                      <span className='color-pink mx-2'>|</span>
                                                      <span className='color-pink s-font2 cursor-pointer' data-bs-toggle="modal" data-bs-target={`#delete_address_modal${address_id}`}>Remove</span>
                                                   </>
                                                }
                                                {
                                                   default_address === 0 &&
                                                   <>
                                                      <span className='color-pink mx-2'>|</span>
                                                      <span className='color-pink s-font2 cursor-pointer' onClick={() => SetDefaultAddress(address_id)}>Set as Default</span>
                                                   </>
                                                }
                                                {/* modal */}
                                                <div className="modal fade" id={`delete_address_modal${address_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                   <div className="modal-dialog modal-dialog-centered">
                                                      <div className="modal-content">
                                                         <div className="modal-header bg-pink text-white">
                                                            <h5 className="modal-title" id="exampleModalLabel">Confirm Deletion</h5>
                                                            <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                                                         </div>
                                                         <div className="modal-body">
                                                            <div className="form-floating mb-3">
                                                               <ul className="list-unstyled">
                                                                  <li>{address.name}</li>
                                                                  <li>{address.village + ", " + address.address}</li>
                                                                  <li>{"Pincode: " + address.pincode + ", Post Office: " + address.post_office}</li>
                                                                  <li>{address.district + ", " + address.state + ", " + address.country}</li>
                                                                  <li>{address.city}</li>
                                                                  <li>Phone number: {address.phone_no + ", " + address.alternate_phone}</li>
                                                               </ul>
                                                            </div>
                                                         </div>
                                                         <div className="modal-footer d-flex justify-content-evenly">
                                                            <button type="button" className="btn btn-outline-pink py-1 px-5" data-bs-dismiss="modal">No</button>
                                                            <button type="button" className="btn btn-pink py-1 px-5" data-bs-dismiss="modal" onClick={() => handleAddressRemove(address.id)}>Yes</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                {/* modal */}
                                             </div>
                                             {
                                                default_address === 1 &&
                                                <div className='position-absolute top-0 end-0 py-2 px-3'>
                                                   {/* <i className="bi bi-check-circle-fill text-success fs-3"></i> */}
                                                   <span className='color-pink'>Default Address</span>
                                                </div>
                                             }
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )
                           })
                           :
                           <h4 className='text-danger'>No Address Found !</h4>
                     }
                  </div>

                  {/* Billing Address */}
                  <h3 className="pt-5">Your Billing Address</h3>
                  <div className="row mt-4 pb-4">
                     <div className="col-lg-6">
                        <a href="my_profile_my_address.php" className="text-decoration-none text-dark">
                           <div className="border b-shadow zoom-2 rounded p-3 h-100">
                              <div className="row">
                                 <div className="col-3">
                                    <div className='d-flex justify-content-center'>
                                       <img src={require("../assets/images/account_img/home.png")} className="d-block img-fluid me-2" height="100" width="100" alt="" />
                                    </div>
                                 </div>
                                 <div className="col-9">
                                    <div className="d-flex justify-content-between">
                                       <p className="h4">Billing Address</p>
                                       <Link to="/myinformation" className='text-decoration-none me-2 btn btn-pink'>Edit</Link>
                                    </div>
                                    {
                                       userInformation &&
                                       <ul className="list-unstyled">
                                          <li>{`${userInformation.name}`}{userInformation.phone_no && `, ${userInformation.phone_no}`}{userInformation.alternate_phone && `, ${userInformation.alternate_phone}`}</li>
                                          <li>{userInformation.village && userInformation.village}{userInformation.address && `, Near ${userInformation.address}`}</li>
                                          <li>{userInformation.pincode && `Pincode: ${userInformation.pincode}`}{userInformation.post_office && `, Post Office: ${userInformation.post_office}`}</li>
                                          <li>{userInformation.city && userInformation.city}{userInformation.district && `, ${userInformation.district}`}{userInformation.state && `, ${userInformation.state}`}</li>
                                       </ul>
                                    }
                                 </div>
                              </div>
                           </div>
                        </a>
                     </div>
                  </div>
               </div>
            </section>
         </main>
      </>
   )
}

export default MyAddress