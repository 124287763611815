import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Arrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block",
        background: 'black',
        boxShadow: '0px 0px 5px black'
      }}
      onClick={onClick}
    />
  );
}

function CarouselSlider(props) {
  const {items, title, auto_play, display_dots} = props;
  var settings = {
    dots: display_dots,
    infinite: true,
    speed: 500,
    autoplay: auto_play,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <Arrow/>,
    prevArrow: <Arrow/>,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 572,
        settings: {
          slidesToShow: 1,
        }
      },
      
    ]
  };
  return (
    <div className="container">
      <h2>{ title && title}</h2>
      <Slider {...settings}>
        {
          items.map((item,i)=> {
            return (
              <div key={i}>
              {item}
              </div>
              
            )
          })
        }
      </Slider>
    </div>

  );
}

export default CarouselSlider;
