import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import Header from '../Components/Header';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from '../Components/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

function PlaceOrder() {
    const { userId, api_url, cartItems, getCartItems, getAllShippingAddress, allShippingAddress, isLoading, setIsLoading } = useGlobalContext();

    const [orderAddress, setOrderAddress] = useState({});
    const [orderedItems, setOrderedItems] = useState([]);
    const [orderPreviewDetails, setOrderPreviewDetails] = useState({});
    const [showAddressModal, setShowAddressModal] = useState(false)

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();


    //orderPreview
    const orderPreview = async () => {
        const data = {
            'user_id': userId,
            'invoice_number': params.invoice_number,
        }
        try {
            setIsLoading(true);
            let res = await axios.post(`${api_url}/orderPreview`, data)
            if (res.data.success === 1) {
                setOrderPreviewDetails(res.data.data);
                setOrderAddress(res.data.data.order_address);
                setOrderedItems(res.data.data.ordered_items);
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                });
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 1500,
                theme: 'colored'
            });
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        orderPreview();
        getAllShippingAddress();
    }, [])

    const handlePlaceOrder = (payment_mode) => {
        const objectForSending = {
            'user_id': userId,
            'order_id': orderPreviewDetails.invoice_number,
            'total_amount': orderPreviewDetails.order_total,
        }

        const form = document.createElement('form');
        form.method = 'POST';
        if(payment_mode==='online')
        {
            form.action = `${api_url}/payment`;
        }
        else{
            form.action = `${api_url}/codOrder`;
        }
        

        // Sample 1
        const hiddenFieldSample1 = document.createElement('input');
        hiddenFieldSample1.type = 'hidden';
        hiddenFieldSample1.name = 'user_id';
        hiddenFieldSample1.value = objectForSending.user_id;
        form.appendChild(hiddenFieldSample1);

        // Sample 2
        const hiddenFieldSample2 = document.createElement('input');
        hiddenFieldSample2.type = 'hidden';
        hiddenFieldSample2.name = 'order_id';
        hiddenFieldSample2.value = objectForSending.order_id;
        form.appendChild(hiddenFieldSample2);

        // Sample 3
        const hiddenFieldSample3 = document.createElement('input');
        hiddenFieldSample3.type = 'hidden';
        hiddenFieldSample3.name = 'total_amount';
        hiddenFieldSample3.value = Number(orderPreviewDetails.grand_total);
        form.appendChild(hiddenFieldSample3);

        document.body.appendChild(form);
        form.submit();

        // var win = window.open(`${api_url}/payment`, '_blank');
        // win.focus();
        // axios.post(`${api_url}/payment`, data);
        //     .then((res) => {
        //         navigate(`${api_url}/payment`)
        //     })
        //     .catch((error) => {
        //         toast.error('Axios Error', {
        //             autoClose: 1500,
        //             theme: 'colored'
        //         });
        //         console.log(error)
        //     })
    }

    //Address Selection
    const handleAddressSelection = (address_id) => {
        const data = {
            'user_id': userId,
            'invoice_number': location.state?.invoice_number,
            'address_id': address_id,
        }
        axios.post(`${api_url}/updateOrderShippingAddress`, data)
            .then((res) => {
                if (res.data.success === 1) {
                    setOrderAddress(res.data.data);
                    setShowAddressModal(false)
                    window.location.reload();
                }
            })
            .catch((error) => {
                toast.error('Axios Error', {
                    autoClose: 1500,
                    theme: 'colored'
                });
                console.log(error)
            })
    }

    //Calculations
    var totalMrp = 0, totalDiscountedPrice = 0, totalCartQty = 0, totalDeliveryCharges = 0, totalDiscount = 0;
    orderedItems.forEach((item) => {
        const { id, product_name, qty, mrp, item_type, discounted_price, shipping_charges, product_color, product_size, class_title, description, image, folder, alt } = item
        let product_quantity = Number(qty);
        let product_id = id;
        totalMrp += item.mrp * product_quantity;
        totalDiscountedPrice += item.discounted_price * product_quantity;
        totalDiscount = (totalMrp - totalDiscountedPrice)
        totalCartQty += product_quantity;
        totalDeliveryCharges += Number(shipping_charges) * product_quantity
    })

    var orderItems_HTML = '';
    useEffect(() => {
        getCartItems();
    }, []);


    console.log(orderedItems)


    if (orderedItems.length > 0) {
        orderItems_HTML =
            <>
                <div className="table-responsive-sm">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.NO</th>
                                <th scope="col">Product</th>
                                <th scope="col">Class/Size</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderedItems.map((item, i) => {
                                    const { product_name, vendor_name, qty, mrp, class_title, discounted_price } = item
                                    let product_quantity = Number(qty);
                                    return (
                                        <tr key={i}>
                                            <th scope="row">{i + 1}</th>
                                            <td>
                                                <span>{product_name}</span>
                                                {
                                                    vendor_name !== null ?
                                                        <span>(<strong>Sold By</strong> {vendor_name})</span>
                                                        :
                                                        <span>(<strong>Sold By</strong>Sold By Evyapari)</span>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    class_title !== null && class_title
                                                }
                                            </td>
                                            <td><del>₹{mrp}</del> ₹{discounted_price}</td>
                                            <td>{product_quantity}</td>
                                            <td>{product_quantity * discounted_price}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </>
    }
    return (
        <>
            <Header />
            <main id='main'>
                <section className='pt-4'>
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <div>
                                    <span className="h4 me-3">Shipping Address</span>
                                    <button onClick={() => setShowAddressModal(true)} className='btn btn-pink py-0'>Change</button>
                                </div>

                                {
                                    orderAddress !== null &&
                                    <ul className="list-unstyled">
                                        <li>
                                            <div className="d-flex gap-2">
                                                <div>
                                                    <span>{orderAddress.name} </span>
                                                    {
                                                        orderAddress.address_type === 1 ?
                                                            <span className="badge rounded-0 bg-success">Home</span>
                                                            :
                                                            <span className="badge rounded-0 bg-warning text-dark">School</span>
                                                    }
                                                </div>
                                                <div className="fw-bold text-dark">{orderAddress.phone_no}{orderAddress.alternate_phone && `, ${orderAddress.alternate_phone}`}</div>
                                            </div>
                                        </li>
                                        {orderAddress.address_type === 2 && <li>School Name: {orderAddress.school_name}</li>}
                                        {/* <li>{`${orderAddress.name}`}{orderAddress.phone_no && `, ${orderAddress.phone_no}`}{orderAddress.alternate_phone && `, ${orderAddress.alternate_phone}`}</li> */}
                                        <li>{orderAddress.village && orderAddress.village}{orderAddress.address && `, Near ${orderAddress.address}`}</li>
                                        <li>{orderAddress.pincode && `Pincode: ${orderAddress.pincode}`}{orderAddress.post_office && `, Post Office: ${orderAddress.post_office}`}</li>
                                        <li>{orderAddress.city && orderAddress.city}{orderAddress.district && `, ${orderAddress.district}`}{orderAddress.state && `, ${orderAddress.state}`}</li>
                                    </ul>
                                }
                            </div>
                            <div className='text-end'>
                                <p>Purchase Order Number : {orderPreviewDetails.invoice_number}</p>
                                <p>Order Date : {orderPreviewDetails.order_date}</p>
                                <p className='h5'><span className='text-secondary'>Order Status</span> : Pending</p>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        {orderItems_HTML}
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-8"></div>
                            <div className="col-md-4">
                                <div className='py-5 py-md-0'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className='d-flex justify-content-between'>
                                                <div className="card-title h5">Subtotal (qty {totalCartQty})</div>
                                                {/* <div className='h5'><del className='text-secondary'>₹{totalMrp}</del> <strong>₹{totalDiscountedPrice}</strong></div> */}
                                                <div className='h5'><strong>₹{orderPreviewDetails.order_total_without_shipping}</strong></div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className="card-title h5">Shipping Charges(Total)</div>
                                                <div className='h5'><strong>+ ₹{orderPreviewDetails.shipping_charge}</strong></div>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <div className="card-title h5">Total Discount</div>
                                                <div className='h5'><strong>- ₹{orderPreviewDetails.discount}</strong></div>
                                            </div>
                                            <hr />
                                            <div className='d-flex justify-content-between'>
                                                <div className="card-title h5">Grand Total</div>
                                                <div className='h5'><strong>₹{orderPreviewDetails.grand_total}</strong></div>
                                            </div>
                                            {/* {totalDiscount !== 0 && <p className="badge py-3 px-5 fs-5 bg-light-green text-success d-block">Yay! Your total discount is ₹{totalDiscount}</p>} */}
                                            {/* <h5 className="card-title">Subtotal ({cartItems.length} items, qty {totalCartQty}) : <strong>₹{totalDiscountedPrice}</strong></h5>
                                            <p className="card-text">With supporting text below as a natural lead-in to additional content.</p> */}
                                            {
                                                orderPreviewDetails?.mode_of_pay===1 ?  
                                                <Link to='' className="btn btn-pink w-100" onClick={() => handlePlaceOrder('online')}>Place Order</Link>
                                                :
                                                <Link to='' className="btn btn-pink w-100" onClick={() => handlePlaceOrder('cod')}>Place COD Order</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal size="lg" autoFocus={false} centered show={showAddressModal} onHide={() => setShowAddressModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>SHIPPING ADDRESS</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bg-white">
                            {
                                allShippingAddress.map((address, i) => {
                                    const { id, user_id, name, default_address, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                    let address_id = id;
                                    return (
                                        <div key={i} id='address' className='px-4'>
                                            <div className="form-check">
                                                <input type="radio" onClick={() => handleAddressSelection(address_id)} className="form-check-input" name="selectaddress" id="flexRadioDefault1" />
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="d-flex gap-2">
                                                            <div>
                                                                <span>{name} </span>
                                                                {
                                                                    address_type === 1 ?
                                                                        <span className="badge rounded-0 bg-success">Home</span>
                                                                        :
                                                                        <span className="badge rounded-0 bg-warning">School</span>
                                                                }

                                                            </div>
                                                            <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                        </div>
                                                        <div>
                                                            <ul className="list-unstyled">
                                                                <li>{`${village}, ${address.address}`}</li>
                                                                <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                <li>{`${city}, ${district}, ${state}`}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </main>
        </>
    )
}

export default PlaceOrder