import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import Loading from '../Components/Loading'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { InfoValidation } from './FormValidation'
import TermsConditions from '../Components/TermsConditions'
import ReCAPTCHA from 'react-google-recaptcha'
import './CSS/Cart.css'


const Cart = () => {
    const { api_url, setIsLoading, userInformation, getUserInformation, cartLength, removeSetFromCart, setCartLength, img_url, setCartItems, getAllShippingAddress, saveForLater, isLoading, getCartItems, cartItems, sessionType, userId, districts, stateData, handleStateChange, setAllShippingAddress, allShippingAddress } = useGlobalContext();
    const [infoErrors, setInfoErrors] = useState({});
    const [openForm, setOpenForm] = useState(false);
    const [shippingAddressId, setShippingAddressId] = useState(null);
    const [showAddresses, setShowAddresses] = useState(false);
    const [homeAddressCount, setHomeAddressCount] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [fillBillingAddress, setFillBillingAddress] = useState(false)
    const [chooseAddressModal, setChooseAddressModal] = useState(false)
    const [defaultShippingAddress, setDefaultShippingAddress] = useState({});
    const [valueFromRadio, setValueFromRadio] = useState(0)
    const [PaymentMode, setPaymentMode] = useState(1)
    const [schoolAddress, setSchoolAddress] = useState({})
    const [showAllAddress, setShowAllAddress] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const recaptcha = useRef();
    var totalMrp = 0, totalDiscountedPrice = 0, totalCartQty = 0, totalDeliveryCharges = 0, totalDiscount = 0;
    const [checkoutRegData, setCheckoutRegData] = useState({
        "user_id": userId,
        "first_name": "",
        "last_name": "",
        "email": "",
        "phone_no": "",
        "state": "",
        "district": "",
        "post_office": "",
        "address": "",
        "village": "",
        "city": "",
        "pincode": "",
        "password": ""
    });
    const [billingAddressFilled, setBillingAddressFilled] = useState(false)
    const getHomeAddressCount = () => {
        const data = {
            'user_id': userId,
        }
        axios.post(`${api_url}/getHomeAddressCount`, data)
            .then((res) => {
                if (res.data.success === 1) {
                    setHomeAddressCount(res.data.data);
                }
                else {
                    toast.error(res.data.message, {
                        autoClose: 1500,
                        theme: 'colored'
                    })
                }
            })
            .catch((error) => {
                toast.error('Axios Error (home Address Count)', {
                    autoClose: 1500,
                    theme: 'colored'
                })
            })
    }

    useEffect(() => {
        getCartItems();
        getUserInformation();
        getAllShippingAddress();
        getHomeAddressCount();

        allShippingAddress.forEach(element => {
            if (element.address_type === 2) {
                setSchoolAddress(element)
            }
        });
    }, []);

    let billingAddress;
    if (userInformation) {
        billingAddress = {
            'school_cod_status': userInformation?.cod_status,
            'user_cod_status': userInformation?.user_cod_status,
            "name": userInformation.name,
            "phone_no": userInformation.phone_no,
            "state": userInformation.state,
            "district": userInformation.district,
            "post_office": userInformation.post_office,
            "village": userInformation.village,
            "city": userInformation.city,
            "pincode": userInformation.pincode,
            "address": userInformation.address,
            "school_code": userInformation.school_code,
        }
    }

    const handleChange = (e) => {
        setCheckoutRegData(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleState = (e) => {
        setCheckoutRegData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        handleStateChange(e);
    }

    // Reomove cart item
    const removeCartItem = async (e, product_id) => {
        e.preventDefault();
        const data = {
            "user_id": userId,
            "product_id": product_id,
            'item_type': 0,
        }

        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/removeItemFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored',
                    style: { color: '#000000' },
                })
                setCartItems(cartItems =>
                    cartItems.filter((item) =>
                        product_id !== item.id
                    )
                );
                setCartLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored'
                })
            }
        } catch (error) {
            toast.error('Axios Error(removeCartItem)', {
                autoClose: 1500
            })
        } finally {
            setIsLoading(false)
        }
    }

    // Reomove Set item
    const removeSetItem = async (e, product_id, set_id) => {
        e.preventDefault();
        const data = {
            "user_id": userId,
            "product_id": product_id,
            'item_type': 1,
            'set_id': set_id
        }

        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/removeItemFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored',
                    style: { color: '#000000' },
                })
                setCartItems(cartItems =>
                    cartItems.filter((item) =>
                        product_id !== item.id
                    )
                );
                setCartLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored'
                })
            }
        } catch (error) {
            toast.error('Axios Error(removeCartItem)', {
                autoClose: 1500
            })
        }
        finally {
            setIsLoading(false)
        }
    }


    //Checkout Registration
    const checkoutRegistration = async (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()

        let errors = InfoValidation(checkoutRegData);
        setInfoErrors(errors);
        console.log(errors)

        if (Object.keys(errors).length === 0) {
            if (!captchaValue) {
                alert('Please verify the reCAPTCHA!')
            }
            else {
                setIsLoading(true)
                try {
                    let res = await axios.post(`${api_url}/checkoutRegistration`, checkoutRegData);
                    if (res.data.success === 1) {
                        const resData = res.data.data;
                        toast.success(res.data.message, {
                            autoClose: 1500,
                            theme: 'colored'
                        });
                        const timer = setTimeout(() => {
                            setOpenForm(false)
                            navigate('/otpverification', { state: { user_id: res.data.data.user_id, otp: res.data.data.otp, prevPath: location.pathname } });
                            // window.location.reload(false);
                        }, 2000);
                        return () => clearTimeout(timer);
                    } else {
                        setIsLoading(false)
                        toast.error(res.data.message, {
                            autoClose: 1500,
                            theme: 'colored'
                        })
                    }
                } catch (error) {
                    setIsLoading(false)
                    console.log(error)
                }
            }

        }

    }

    //update Cart quanity
    const updateCartQuantity = async (product_id, scope) => {
        try {
            const data = {
                "user_id": userId,
                "product_id": product_id,
                "scope": scope
            }
            let res = await axios.put(`${api_url}/updateCartQuantity`, data);
            if (res.data.success === 1) {
                console.log("success")
            } else {
                console.log("server error")
            }
        } catch (error) {
            console.log(error)
        }
    }

    //handle Decrement
    const handleDecrement = (product_id) => {
        setCartItems(cartItems =>
            cartItems.map(item =>
                product_id === item.id ? { ...item, qty: Number(item.qty) - (Number(item.qty) > 1 ? 1 : 0) } : item
            )
        );
        updateCartQuantity(product_id, "dec")
    }

    // handle Increment
    const handleIncrement = (pid) => {
        setCartItems(cartItems =>
            cartItems.map((item) =>
                pid === item.id ? { ...item, qty: Number(item.qty) + (Number(item.qty) < 10 ? 1 : 0) } : item
            )
        );
        updateCartQuantity(pid, "inc")
    }

    //Select Shipping Address
    const selectShippingAddress = async (address_id) => {
        const data = {
            user_id: userId,
            address_id: address_id
        }
        try {
            let res = await axios.post(`${api_url}/defaultAddress`, data);
            if (res.data.success === 1) {
                console.log('success')
                allShippingAddress.forEach((address) => {
                    if (address.id === address_id) {
                        setDefaultShippingAddress(address);
                    }
                })
            }
            else {
                toast.error(res.data.success, {
                    autoClose: 1500,
                    theme: 'colored'
                });
            }
        } catch (error) {
            toast.success('Axios Error', {
                autoClose: 1500,
                theme: 'colored'
            });
        }
    }

    //Address Selection
    const handleAddressSelection = (address_id) => {
        setAllShippingAddress(AllShippingAddress =>
            AllShippingAddress.map(address => (
                address.id === address_id ? { ...address, default_address: 1 } : { ...address, default_address: 0 }
            )
            )
        )
        setShippingAddressId(address_id);
        selectShippingAddress(address_id);
    }

    //Continue Checkout
    const ContinueCheckout = () => {
        if (sessionType === 'guest') {
            setOpenForm(true)
        } else {
            allShippingAddress.forEach((address) => {
                if (address.default_address === 1) {
                    setShippingAddressId(address.id)
                }
            })
            setShowAddresses(true)
        }
    }


    //handleRadioChange
    const handleRadioChange = (e) => {
        setValueFromRadio(e.target.value);
    }

    //proceedToCheckout
    const proceedToCheckout = () => {
        const data = {
            'user_id': userId,
            'shipping_address_id': valueFromRadio,
            'mode_of_payment': PaymentMode,
        }
        setIsLoading(true)
        axios.post(`${api_url}/proceedToCheckout`, data)
            .then((res) => {
                let orderRes = res.data.data;
                if (res.data.success === 1) {
                    toast.success('Preview Your Order', {
                        autoClose: 1500,
                        theme: 'colored'
                    });
                    navigate(`/placeorder/${orderRes.invoice_number}`, { state: { invoice_number: orderRes.invoice_number, order_time: orderRes.order_time } })
                }
                else {
                    toast.error('Something Went Wrong', {
                        autoClose: 1500,
                        theme: 'colored'
                    });
                }
                setIsLoading(false)
            })
            .catch((err) => {
                console.log("data sent", data)
                toast.error('Axios Error', {
                    autoClose: 1500,
                    theme: 'colored'
                });
                setIsLoading(false)
            })
    }

    //handleProceedToCheckout
    const handleProceedToCheckout = () => {
        if (valueFromRadio === 0 && homeAddressCount === 0) {

            if (billingAddress['name'] === null || billingAddress['phone_no'] === null ||
                billingAddress['state'] === null || billingAddress['district'] === null ||
                billingAddress['post_office'] === null || billingAddress['village'] === null ||
                billingAddress['city'] === null || billingAddress['pincode'] === null) {
                setFillBillingAddress(true);
            }
            else {
                proceedToCheckout();
            }
        }
        else if (valueFromRadio === 0 && homeAddressCount !== 0) {
            setChooseAddressModal(true);
        }
        else {
            proceedToCheckout();
        }

    }
    //handleOnlineMode
    const handleOnlineMode = (e) => {
        setPaymentMode(1);
        if(!(showAllAddress===true && valueFromRadio!==0)){
            setValueFromRadio(0)
        }
        
        setShowAllAddress(true);
    }

    //handleSchoolCOD
    const handleSchoolCOD = (e) => {
        setPaymentMode(2);
        setValueFromRadio(schoolAddress?.id)
        setShowAllAddress(false);
    }

    var cart_HTML = '';
    var totaltable_HTML = '';
    if (cartItems.length > 0) {
        cart_HTML =
            <>
                {
                    cartItems.map((item, i, Items) => {
                        const { id, product_name, item_weight, qty, mrp, set_id, vendor_id, item_type, discounted_price, shipping_charges, product_color, product_size, class_title, description, image, folder, alt } = item
                        let product_quantity = Number(qty);
                        let product_id = id;
                        totalMrp += item.mrp * product_quantity;
                        totalDiscountedPrice += Math.round(item.discounted_price * product_quantity);
                        totalDiscount = Math.round(totalMrp - totalDiscountedPrice)
                        totalCartQty += product_quantity;
                        totalDeliveryCharges += Number(shipping_charges) * product_quantity


                        // let next_current_set_id = 0;
                        if (i + 1 < Items.length) {
                            var next_current_set_id = Items[i + 1]?.set_id;
                        }

                        let current_set_id = item.set_id;

                        if (item_type === 1) {
                            return (
                                <>
                                    <div id='cart_item' key={i} className="ps-3 py-3 border">
                                        <div className="row">
                                            <div className="col-2">
                                                <div className="d-flex gap-2 justify-content-between">
                                                    <div className='d-none d-sm-block h5'>{i + 1}.</div>
                                                    <div className="cart-set-img overflow-hidden">
                                                        <Link to={`${img_url}/${folder}/${image}`} target='_blank'>
                                                            <img src={`${img_url}/${folder}/${image}`} width={50} height={50} alt={alt} />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-2">
                                                <div className=''><Link to={`/seeallfeature/${product_id}`} className="text-decoration-none text-dark"><div className='h6'>{product_name}</div></Link></div>
                                            </div>
                                            <div className="col-2">
                                                <div className=''><del className='h6 text-secondary'>₹{mrp}</del><span className='h5'> ₹{discounted_price}</span></div>
                                            </div>
                                            <div className="col-2">
                                                <div className='d-flex'>
                                                    {
                                                        class_title !== null && <div className='h6 me-3'><span className='text-secondary'>Class:</span> {class_title}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <span>Qty: {product_quantity}</span>
                                            </div>
                                            <div className="col-1">
                                                <span className="color-pink cursor-pointer" onClick={(e) => removeSetItem(e, id, set_id)}><i className="bi bi-trash3-fill"></i></span>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        (current_set_id !== next_current_set_id) ?
                                            <div className='mb-5 w-100 w-sm-25'>
                                                <button onClick={(e) => removeSetFromCart(e, set_id)} className='btn rounded-0 btn-pink w-100'>Remove Set</button>
                                                <br />
                                            </div>
                                            :
                                            null
                                    }
                                </>
                            )
                        }
                        else {
                            return (
                                <div id='cart_item' key={i} className="ps-md-3 py-4 border-bottom">
                                    <div className="row">
                                        <div className="col-md-4 col-3">
                                            <div className="d-flex gap-2 justify-content-between">
                                                <div className='h4 d-none d-sm-block'>{i + 1}.</div>
                                                <div className="cart-img-contain overflow-hidden">
                                                    <img src={`${img_url}/${folder}/${image}`} alt={alt} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-9">
                                            <div className=''>
                                                <div className=''><Link to={`/seeallfeature/${product_id}`} className="text-decoration-none text-dark"><h5>{product_name}</h5></Link></div>
                                                <div className="d-flex mb-1">
                                                    <div className='me-3'><del className='h6 text-secondary'>₹{mrp}</del><span className='h5'> ₹{discounted_price}</span></div>
                                                    <div className="badge rounded-pill bg-light py-1 px-3 text-secondary h5">{shipping_charges ? `Delivery ₹${shipping_charges}` : 'Free Delivery'}</div>
                                                </div>

                                                <div className='d-flex'>
                                                    {
                                                        class_title !== null && <div className='h5 me-3'><span className='text-secondary'>Class :</span> {class_title}</div>
                                                    }
                                                    {
                                                        product_color !== null && <div className='h5 me-3'><span className='text-secondary'>Color :</span> {product_color}</div>
                                                    }
                                                    <div className='h5'><span className='text-secondary'>Medium/Size :</span> {product_size}</div>
                                                </div>
                                                {/* <p className="d-none d-md-block">{convertToPlain(description).slice(0, 250)}...</p> */}
                                            </div>
                                            <div className="d-none d-sm-flex gap-3 align-items-center mb-3 mt-2">
                                                <div>
                                                    <span>Qty : </span>
                                                    <span className="quantity-picker">
                                                        <button className="quantity-modifier modifier-left" onClick={() => handleDecrement(id)}>&ndash;</button>
                                                        <input className="quantity-display" type="text" value={product_quantity} readOnly />
                                                        <button className="quantity-modifier modifier-right" onClick={() => handleIncrement(id)}>&#xff0b;</button>
                                                    </span>
                                                </div>
                                                <button className="border-0 bg-transparent d-md-none color-pink cursor-pointer" onClick={(e) => removeCartItem(e, id)}><i className="bi bi-trash3-fill"></i></button>
                                                <button className="border-0 bg-transparent color-pink d-md-block d-none" onClick={(e) => removeCartItem(e, id)}>Remove</button>
                                                <button className="border-0 bg-transparent color-pink" onClick={() => saveForLater(id)}>Save <span className="d-sm-inline-block d-none">for Later</span></button>
                                                <Link to="https://web.whatsapp.com/" className="d-none d-sm-block"><img src={require("../assets/images/social_media/WhatsApp.svg.png")} width="25" alt="" /></Link>
                                            </div>
                                            <div className='d-none d-sm-block'>Subtotal : ₹{product_quantity * discounted_price}</div>
                                        </div>
                                        <div className="col-12 d-sm-none d-flex gap-3 align-items-center mb-3 mt-2">
                                            <div>
                                                <span>Qty : </span>
                                                <span className="quantity-picker">
                                                    <button className="quantity-modifier modifier-left" onClick={() => handleDecrement(id)}>&ndash;</button>
                                                    <input className="quantity-display" type="text" value={product_quantity} readOnly />
                                                    <button className="quantity-modifier modifier-right" onClick={() => handleIncrement(id)}>&#xff0b;</button>
                                                </span>
                                            </div>
                                            <button className="border-0 bg-transparent d-md-none color-pink cursor-pointer" onClick={(e) => removeCartItem(e, id)}><i className="bi bi-trash3-fill"></i></button>
                                            <button className="border-0 bg-transparent color-pink d-sm-block d-none" onClick={(e) => removeCartItem(e, id)}>Remove</button>
                                            <button className="border-0 bg-transparent color-pink r_font1" onClick={() => saveForLater(id)}>Save for Later</button>
                                            <Link to="https://web.whatsapp.com/" className="d-none d-sm-block"><img src={require("../assets/images/social_media/WhatsApp.svg.png")} width="25" alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </>

        totaltable_HTML =
            <>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">s.no</th>
                            <th scope="col">Product</th>
                            <th scope="col">Unit Price</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            cartItems.map((item, i) => {
                                const { product_name, qty, mrp, discounted_price } = item
                                let product_quantity = Number(qty);
                                return (
                                    <tr key={i}>
                                        <th scope="row">{i + 1}</th>
                                        <td>{product_name}</td>
                                        <td><del>₹{mrp}</del> ₹{discounted_price}</td>
                                        <td>{product_quantity}</td>
                                        <td>{product_quantity * discounted_price}</td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </>
    } else {
        cart_HTML =
            <div>
                <div className='color-pink h4 mt-3'>Your Cart is Empty</div>
                <div className='row'>
                    <div className="col-md-9">
                        <img src={require('../assets/images/empty_cart.jpg')} className='img-fluid d-block mx-auto' alt="" />
                    </div>
                    <div className=''>
                        <div className='text-center'>
                            <Link to={'/marketplace'} className='btn btn-pink py-2 px-3'>Add Items to Cart</Link>
                        </div>
                    </div>
                </div>
            </div>
    }

    return (
        <>
            {/* {isLoading ? <Loading /> : null} */}
            <Header />
            <main id='main'>
                <section id="cart" className="p-md-3 pb-5">
                    <div className="container-fluid">
                        <div className="row g-5">
                            <div className="col-lg-7">
                                <div className="border-bottom">
                                    <h4>{cartItems.length !== 0 ? `Shopping Cart (${cartItems.length})` : 'Shopping Cart'}</h4>
                                </div>
                                {cart_HTML}
                            </div>

                            <div className="col-lg-5">

                                {
                                    cartItems.length !== 0 &&
                                    <>
                                        <h4>Order Review</h4>
                                        {totaltable_HTML}
                                        <div className="card">
                                            <div className="card-body">
                                                <div className='d-flex justify-content-between'>
                                                    <div className="card-title h5">Subtotal ({cartItems.length} items, qty {totalCartQty})</div>
                                                    <div className='h5'><strong>₹{totalMrp}</strong></div>
                                                </div>
                                                <div className='d-flex justify-content-between'>
                                                    <div className="card-title h5">Total Discount</div>
                                                    <div className='h5'><strong>- ₹{totalMrp - totalDiscountedPrice}</strong></div>
                                                </div>
                                                <hr />
                                                <div className='d-flex justify-content-between'>
                                                    <div className="card-title h5">Total</div>
                                                    <div className='h5'><strong>₹{totalDiscountedPrice}</strong></div>
                                                </div>
                                                <hr />
                                                {totalDiscount !== 0 && <p className="badge py-3 px-5 h5 bg-light-green text-success d-block">Yay! Your total discount is ₹{totalDiscount}</p>}
                                                {
                                                    showAddresses === false &&
                                                    <>
                                                        <Link to={`/myschool`} className="btn btn-outline-pink w-100 mt-3">Buy More School Sets</Link>
                                                        <button onClick={() => ContinueCheckout()} className="btn btn-pink w-100 my-3">Continue Checkout</button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    openForm &&
                                    <div className="border mt-4">
                                        <div className="mx-auto">
                                            <p className="h4 px-3 py-2 bg-pink text-white m-0">Your Information</p>
                                        </div>
                                        <form className="mx-auto p-4">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='first_name' value={checkoutRegData.first_name} id="name" placeholder="name" />
                                                        <label htmlFor="name">First Name <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.first_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='last_name' value={checkoutRegData.last_name} id="name" placeholder="name" />
                                                        <label htmlFor="name">Last Name <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.last_name}</div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='school_code' value={checkoutRegData.school_code} id="schoolcode" placeholder="schoolcode" />
                                                        <label htmlFor="schoolcode">School Code</label>
                                                    </div>
                                                </div> */}
                                                
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="email" className="form-control" onChange={handleChange} name='email' value={checkoutRegData.email} id="email" placeholder="email" />
                                                        <label htmlFor="email">Email <span className="text-danger"></span></label>
                                                        <div className="text-danger">{infoErrors.email}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="tel" className="form-control" onChange={handleChange} maxLength={10} name='phone_no' value={checkoutRegData.phone_no} id="phone" placeholder="phone" />
                                                        <label htmlFor="phone">Contact Number <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.phone_no}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="password" className="form-control" onChange={handleChange} name='password' required value={checkoutRegData.classno} id="classno" placeholder="city" />
                                                        <label htmlFor="classno">Create Password<span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.password}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="form-select py-3" onChange={(e) => handleState(e)} name='state' value={checkoutRegData.state} aria-label="Default select example">
                                                        <option value="Select State">--Select State--</option>
                                                        {
                                                            stateData.map((state, i) => {
                                                                return (
                                                                    <option key={i} value={state.state_name}>{state.state_name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <div className="text-danger mb-3">{infoErrors.state}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <select className="form-select py-3" onChange={handleChange} name='district' value={checkoutRegData.district} aria-label="Default select example">
                                                        <option >--Select District--</option>
                                                        {
                                                            districts.map((district, i) => {
                                                                return (
                                                                    <option key={i} value={district}>{district}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <div className="text-danger mb-3">{infoErrors.district}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating">
                                                        <input type="text" className="form-control" onChange={handleChange} name='post_office' value={checkoutRegData.post_office} id="postOffice" placeholder="postoffice" />
                                                        <label htmlFor="postOffice">Post Office <span className="text-danger">*</span></label>
                                                    </div>
                                                    <div className="text-danger mb-3">{infoErrors.post_office}</div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-floating mb-4">
                                                        <input type="text" className="form-control" onChange={handleChange} name='pincode' maxLength={6} value={checkoutRegData.pincode} id="pincode" placeholder="pincode" />
                                                        <label htmlFor="zipcode">Pincode <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.pincode}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='address' value={checkoutRegData.address} id="address" placeholder="address" />
                                                        <label htmlFor="address">Land Mark/House no./Building  name <span className="text-danger"></span></label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='village' value={checkoutRegData.village} id="villa" placeholder="villa-no" />
                                                        <label htmlFor="villa">Village/Ward No. <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.village}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" onChange={handleChange} name='city' value={checkoutRegData.city} id="city" placeholder="city" />
                                                        <label htmlFor="city">City <span className="text-danger">*</span></label>
                                                        <div className="text-danger">{infoErrors.city}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" checked type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    I agree to accept the terms and conditions
                                                    <span className='cursor-pointer text-decoration-none text-danger' data-bs-toggle="modal" data-bs-target="#ReadMoreModal"> READ.</span>
                                                </label>
                                            </div>
                                            <TermsConditions />
                                            <button className="btn btn-pink px-4 px-sm-5 w-100" onClick={(e) => checkoutRegistration(e)}>Register</button>
                                            <div className='text-center'>Already have account <Link to="/login" className='text-decoration-none h5 color-pink'>Login</Link> here</div>
                                        </form>
                                    </div>
                                }

                                {
                                    showAddresses &&
                                    <>
                                        <div className='row'>
                                            {
                                                (showAllAddress) ?
                                                    <>
                                                        <div className="col-12">
                                                            <div className="bg-white mt-3 b-shadow-2">
                                                                <div className='bg-pink p-2'>
                                                                    <h5 className='text-white m-0'>BILLING ADDRESS</h5>
                                                                </div>
                                                                <div id='address' className='p-3'>
                                                                    <div className="row">
                                                                        <div className="col-md-9">
                                                                            <div>
                                                                                {
                                                                                    <ul className="list-unstyled">
                                                                                        <li>{`${billingAddress.name}`}{billingAddress.phone_no && `, ${billingAddress.phone_no}`}{billingAddress.alternate_phone && `, ${billingAddress.alternate_phone}`}</li>
                                                                                        <li>{billingAddress.village && billingAddress.village}{billingAddress.address && `, ${billingAddress.address}`}</li>
                                                                                        <li>{billingAddress.pincode && `Pincode: ${billingAddress.pincode}`}{billingAddress.post_office && `, Post Office: ${billingAddress.post_office}`}</li>
                                                                                        <li>{billingAddress.city && billingAddress.city}{billingAddress.district && `, ${billingAddress.district}`}{billingAddress.state && `, ${billingAddress.state}`}</li>
                                                                                    </ul>
                                                                                }
                                                                            </div>

                                                                            {
                                                                                homeAddressCount === 0 &&
                                                                                <div className="form-check">
                                                                                    <input type="radio" className="form-check-input" name='ship_address' value={0} onChange={(e) => handleRadioChange(e)} title="Choose Shipping Address" id="same_as_bill" defaultChecked />
                                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                        <span className='color-pink r_font2'>Shipping Address Same as Billing Address</span>
                                                                                    </label>
                                                                                </div>
                                                                            }

                                                                            <div>
                                                                                <Link to='/addaddress' className='color-pink text-decoration-none mt-3'>
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                        <span className="h5 color-pink mt-2">Add a New Address</span>
                                                                                        <i className="bi bi-plus-square fs-5"></i>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>

                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <div className='text-end'>
                                                                                <Link to="/myinformation" className='text-decoration-none btn btn-pink'>Edit</Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            {
                                                                allShippingAddress.map((address, i) => {
                                                                    const { id, user_id, default_address, name, phone_no, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                                                    return (
                                                                        (default_address === 1 && address_type === 1) &&
                                                                        <div className="bg-white b-shadow-2 mt-3">
                                                                            <div className='bg-pink p-2'>
                                                                                <h5 className='text-white m-0'>SHIPPING ADDRESS</h5>
                                                                            </div>
                                                                            <div key={i} id='address' className='p-3'>
                                                                                <div className="row">
                                                                                    <div className="col-md-9">
                                                                                        <div className="d-flex">
                                                                                            <div className="form-check">
                                                                                                <input type="radio" className="form-check-input" name='ship_address' value={id} onChange={(e) => handleRadioChange(e)} title="Choose Shipping Address" id="homeaddress" />
                                                                                            </div>
                                                                                            <div>
                                                                                                <div className="d-flex gap-2">
                                                                                                    <div>
                                                                                                        <span>{name} </span>
                                                                                                        {
                                                                                                            address_type === 1 ?
                                                                                                                <span className="badge rounded-0 bg-success">Home</span>
                                                                                                                :
                                                                                                                <span className="badge rounded-0 bg-warning text-dark">School</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <ul className="list-unstyled">
                                                                                                        <li>{`${village}`}{address.address && `, ${address.address}`}</li>
                                                                                                        <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                                                        <li>{city && `${city}, `}{`${district}, ${state}`}</li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-3">
                                                                                        <div className='text-end'>
                                                                                            <button className='btn btn-pink' onClick={() => setShowAddressModal(true)}>Change</button>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className="col-12">
                                                            {
                                                                allShippingAddress.map((address, i) => {
                                                                    const { id, user_id, default_address, name, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;

                                                                    return (
                                                                        address_type === 2 &&
                                                                        <div className="bg-white b-shadow-2 mt-3">

                                                                            <Accordion>
                                                                                <Accordion.Item eventKey="0">
                                                                                    <Accordion.Header>
                                                                                        <div className='bg-pink p-2 w-100'>
                                                                                            <h5 className='text-white m-0'>SCHOOL PICKUP ADDRESS</h5>
                                                                                        </div>
                                                                                    </Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <div key={i} id='address' className=''>
                                                                                            <div className="row">
                                                                                                <div className="">
                                                                                                    <div className='d-flex'>
                                                                                                        <div className="form-check">
                                                                                                            <input type="radio" className="form-check-input" name='ship_address' value={id} onChange={(e) => handleRadioChange(e)} required title="Choose Shipping Address" id="school_add" />
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div className="d-flex gap-2">
                                                                                                                <div>
                                                                                                                    <span>{name} </span>
                                                                                                                    <span className="badge rounded-0 bg-warning text-dark">School</span>
                                                                                                                </div>
                                                                                                                <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <ul className="list-unstyled">
                                                                                                                    {address_type === 2 && <li>School Name : {school_name}</li>}
                                                                                                                    <li>{`${village}`}{address.address && `, ${address.address}`}</li>
                                                                                                                    <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                                                                    <li>{city && `${city}, `}{`${district}, ${state}`}</li>
                                                                                                                </ul>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            </Accordion>

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="col-12">
                                                        {
                                                            allShippingAddress.map((address, i) => {
                                                                const { id, user_id, default_address, name, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;

                                                                return (
                                                                    address_type === 2 &&
                                                                    <div className="bg-white b-shadow-2 mt-3">
                                                                        <div className='bg-pink p-2'>
                                                                            <h5 className='text-white m-0'>SCHOOL PICKUP ADDRESS</h5>
                                                                        </div>
                                                                        <div key={i} id='address' className='p-3'>
                                                                            <div className="row">
                                                                                <div className="">
                                                                                    <div className="d-flex gap-2">
                                                                                        <div>
                                                                                            <span>{name} </span>
                                                                                            <span className="badge rounded-0 bg-warning text-dark">School</span>
                                                                                        </div>
                                                                                        <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <ul className="list-unstyled">
                                                                                            {address_type === 2 && <li>School Name : {school_name}</li>}
                                                                                            <li>{`${village}`}{address.address && `, ${address.address}`}</li>
                                                                                            <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                                            <li>{city && `${city}, `}{`${district}, ${state}`}</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                            }


                                            <div className="col-12">
                                                <div className='my-3 px-3 py-2 b-shadow-2'>
                                                    <div className='mb-2'><strong>Payment Method: </strong></div>
                                                    <div className='d-flex gap-3'>
                                                        <div className="form-check">
                                                            <label className="form-check-label" id="online_payment_method">
                                                                <span className='color-pink'>Online</span>
                                                            </label>
                                                            <input type="radio" className="form-check-input" name='mode_of_payment' value={1} onChange={(e) => handleOnlineMode()} title="Choose Peyment Method" id="online_payment_method" defaultChecked />
                                                        </div>
                                                        {
                                                            (billingAddress?.school_cod_status === 1 || billingAddress?.user_cod_status === 1) &&
                                                            <div className="form-check">
                                                                <label className="form-check-label" htmlFor="cod_payment_method">
                                                                    <span className='color-pink'>Cash on Delivery</span>
                                                                </label>

                                                                {
                                                                    billingAddress?.user_cod_status === 1 ?
                                                                        <input type="radio" className="form-check-input" name='mode_of_payment' value={2} onChange={(e) => setPaymentMode(2)} title="Choose Shipping Address" id="cod_payment_method" />
                                                                        :
                                                                        <input type="radio" className="form-check-input" name='mode_of_payment' value={2} onChange={(e) => handleSchoolCOD(e)} title="Choose Shipping Address" id="cod_payment_method" />
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    showAddresses &&
                                    <button onClick={() => handleProceedToCheckout()} className="btn btn-orange w-100 py-2 my-3"><span className='h4'>Proceed to Checkout</span></button>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Modal size="lg" autoFocus={false} centered show={showAddressModal} onHide={() => setShowAddressModal(false)}>
                    <Modal.Header className='bg-pink text-white' closeButton>
                        <Modal.Title><h5>SHIPPING ADDRESS</h5></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bg-white">
                            {
                                allShippingAddress.map((address, i) => {
                                    const { id, user_id, name, default_address, phone_no, school_name, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                    let address_id = id;
                                    return (
                                        address_type === 1 &&
                                        <div key={i} id='address' className='px-4'>
                                            <div className="form-check">
                                                <input type="radio" onClick={() => handleAddressSelection(address_id)} checked={default_address === 1 ? true : false} className="form-check-input" name="selectaddress" id="flexRadioDefault1" />
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="d-flex gap-2">
                                                            <div>
                                                                <span>{name} </span>
                                                                <span className="badge rounded-0 bg-success">Home</span>
                                                            </div>
                                                            <div className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</div>
                                                        </div>
                                                        <div>
                                                            <ul className="list-unstyled">
                                                                <li>{`${village}, ${address.address}`}</li>
                                                                <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                <li>{`${city}, ${district}, ${state}`}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className='text-end'>
                                                            <Link to={`/editaddress/${address_id}`} className='btn btn-pink'>Edit</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />

                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <Link to='/addaddress' className='color-pink text-decoration-none mt-3'>
                                <div className="border b-shadow rounded d-flex align-items-center gap-3 justify-content-center">
                                    <p className="h5 color-pink mt-2">Add a New Address</p>
                                    <i className="bi bi-plus-square fs-5"></i>
                                </div>
                            </Link>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="" autoFocus={false} centered show={fillBillingAddress} onHide={() => setFillBillingAddress(false)}>
                    <Modal.Body>
                        <div className='text-center p-3'>
                            <h5 className='mb-4'>Please Complete Your Billing Address Information</h5>
                            <Link to="/myinformation" className='text-decoration-none w-50 py-2 btn btn-pink'>Ok</Link>
                        </div>

                    </Modal.Body>
                </Modal>

                <Modal size="" autoFocus={false} centered show={chooseAddressModal}>
                    <Modal.Body>
                        <div className='text-center p-3'>
                            <h5 className='mb-4'>Please Choose Your Shipping Address</h5>
                            <button onClick={() => setChooseAddressModal(false)} className='w-50 py-2 btn btn-pink' closeButton>Ok</button>
                        </div>

                    </Modal.Body>
                </Modal>
            </main>
        </>
    )
}

export default Cart