import { useSelector } from 'react-redux'
import ProductCard from '../Components/ProductCard'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SchoolCard from '../Components/SchoolCard';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Data from '../data/Data.json'
import { useInventoryContext } from '../contexts/InventoryContext';
import { useGlobalContext } from '../contexts/GlobalContext';
import CarouselSlider from '../Components/CarouselSlider/CarouselSlider';

const Home = () => {
    const { img_url, schools, getSchools, convertToPlain, setShowSchoolModal } = useGlobalContext();
    const { homeInventory, getHomeInventory } = useInventoryContext();

    const options = {
        loop: true,
        nav: true,
        margin: 10,
        video: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },

            500: {
                items: 2
            },
            768: {
                items: 3,
            },
            992: {
                items: 4,
            },
            1400: {
                items: 5,
            }
        }
    }


    //On Page Load (Get All Inventory)
    useEffect(() => {
        //Set guest id if not logged in
        if (!sessionStorage.getItem('user_id') && !sessionStorage.getItem('guest_id')) {
            const guestId = Math.floor(100000 + Math.random() * 900000);
            sessionStorage.setItem('guest_id', guestId);
        }
        getHomeInventory();
        getSchools();
    }, []);

    const productCarouselItems = homeInventory.map((card, i) => {
        const { id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (
            <ProductCard
                key={i}
                id={id}
                product_id={product_id}
                image={`${img_url}/${folder}/${image}`}
                title={product_name}
                mrp={mrp}
                discounted_price={discounted_price}
                shipping_charges={shipping_charges}
                type={type}
                text={(description)}
                Card={card}
                itemExistInCart={itemExistInCart}
                itemExistInWishlist={itemExistInWishlist}
            />
        )
    })


    const schoolCarouselItems = schools.map((school, i) => {
        const { school_name, school_banner, folder } = school;
        return (
            <SchoolCard
                key={i}
                school_name={school_name}
                school_image={`${img_url}/${folder}/${school_banner}`}
                school_banner={school_banner}
            />
        )
    })

    return (
        <>
            {/* {isLoading ? <Loading /> : null} */}
            <Header />
            <main id='main'>
                {/* <!-----------------------------------Banner Starts---------------------------------> */}
                <section id="banner">
                    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='position-relative'>
                                    <div className='banner_img_contain'>
                                        <img src={`${img_url}/site_img/online book store.jpg`} className="object-fit-fill" width='100%' height='100%' alt="..." />
                                    </div>
                                    {
                                        sessionStorage.getItem('school_code') === null ?
                                            <div className='myschool_banner_btn'>
                                                <button onClick={() => setShowSchoolModal(true)} className='btn btn-pink px-md-4 py-md-2 fw-bolder'>My School</button>
                                            </div>
                                            :
                                            <div className='myschool_banner_btn'>
                                                <Link to={'/myschool'} className='btn btn-pink px-md-4 py-md-2 fw-bolder'>My School</Link>
                                            </div>
                                    }

                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="banner_img_contain">
                                    <img src={`${img_url}/site_img/e-marketplace.jpg`} className="object-fit-fill" width='100%' height='100%' alt="..." />
                                </div>
                            </div>
                        </div>
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button> */}
                    </div>
                </section>

                {/* <!-----------------------------------Product Starts-----------------------------------> */}
                <section id="product" className="my-5">
                    <div className="container-fluid">
                        <div>
                            <CarouselSlider items={productCarouselItems.slice(0,10)} title='' auto_play={true} display_dots={false} />
                        </div>
                        <div>
                            <CarouselSlider items={productCarouselItems.slice(10,20)} title='' auto_play={true} display_dots={false} />
                        </div>


                        <div className='text-end'>
                            <Link to={'/marketplace'} ><button className='btn btn-danger'>View All</button></Link>
                        </div>
                    </div>
                </section>
                {/* <!-----------------------------------Product Ends--------------------------------------> */}

                {/* <!-----------------------------------School Starts-------------------------------------> */}
                <section id="school" className="bg-dark py-4">
                    <div className="container">
                        <h2 className="text-center text-white pb-4">E-Vyapari Popular Schools</h2>
                        <CarouselSlider items={schoolCarouselItems} title='' auto_play={true} />
                    </div>
                </section>
                {/* <!-----------------------------------School Ends---------------------------------------> */}
            </main>
            <Footer />
        </>
    )
}

export default Home