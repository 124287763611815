import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PassValidation } from './FormValidation';
import Header from '../Components/Header';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Copyright from '../Components/Copyright';

const ResetForgotPassword = () => {
  const { api_url, setIsLoading } = useGlobalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    new_password: ''
  });

  const [passShow1, setPassShow1] = useState(true);
  const [passShow2, setPassShow2] = useState(true);
  const [type1, setType1] = useState('password')
  const [type2, setType2] = useState('password')
  const togglePassword = (flag) => {
    if (flag === 1 && type1 === 'password') {
      setType1('text');
      setPassShow1(!passShow1)
    } else if (flag === 1 && type1 === 'text') {
      setType1('password');
      setPassShow1(!passShow1)
    }

    if (flag === 2 && type2 === 'password') {
      setType2('text');
      setPassShow2(!passShow2)
    } else if (flag === 2 && type2 === 'text') {
      setType2('password');
      setPassShow2(!passShow2)
    }
  }

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = PassValidation(passData);
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      const data = {
        user_id: location.state.user_id,
        new_password: passData.new_password
      }
      setIsLoading(true)
      axios.post(`${api_url}/resetPassword`, data)
        .then((res) => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(`${res.data.message} 🤩`, {
              theme: 'colored',
              autoClose: 1500,
            })
            let timer = setTimeout(() => {
              navigate('/login', { state: { user_id: location.state.user_id } });
            }, 2000);
            return () => clearTimeout(timer);
          }
          else {
            setIsLoading(false)
            toast.error(`${res.data.message} `, {
              theme: 'colored',
              autoClose: 1500,
            })
          }
        })
        .catch((err) => {
          setIsLoading(false)
          toast.error('Axios Error', {
            theme: 'colored',
            autoClose: 1500,
          })
          console.log(err)
        })

      // try {
      //   let res = axios.post(`${api_url}/resetPassword`, data);
      //   if (res.data.success === 1) {
      //     toast.success(`${res.data.message} 🤩`, {
      //       theme: 'colored',
      //       autoClose: 1500,
      //     })
      //     let timer = setTimeout(() => {
      //       navigate('/login');
      //     }, 2000);
      //     return () => clearTimeout(timer);
      //   }
      //   else {
      //     toast.error(`${res.data.message} `, {
      //       theme: 'colored',
      //       autoClose: 1500,
      //     })
      //   }
      // } catch (error) {
      //   toast.error('Axios Error', {
      //     theme: 'colored',
      //     autoClose: 1500,
      //   })
      // }
    }
  }

  return (
    <>
      <Header />
      <main id='main'>
        <section id="login">
          <div className="container">
            <div className="login-container align-this">
              <div className="f-shadow bg-white">
                <div className="mx-auto h6 m-0 p-3 bg-pink text-white">Change Your Password</div>
                <form onSubmit={handleSubmit} className="mx-auto border p-4">
                  <div className="form-floating mb-3">
                    <input type={type1} className="form-control" onChange={handleChange} name='new_password' value={passData.new_password} id="new_password" placeholder="Password" />
                    <label htmlFor="new_password">New Password <span className="text-danger">*</span></label>
                    <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(1)}>{passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow1 ? 'Show' : 'Hide'}</span>
                    <div className="text-danger">{passErrors.new_password}</div>
                  </div>
                  <div className="form-floating mb-3">
                    <input type={type2} className="form-control" onChange={handleChange} name='cpassword' id="cpassword" placeholder="Password" />
                    <label htmlFor="cpassword">Confirm Password <span className="text-danger">*</span></label>
                    <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(2)}>{passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow2 ? 'Show' : 'Hide'}</span>
                    <div className="text-danger">{passErrors.cpassword}</div>
                  </div>
                  <div className="col "><button type="submit" className="btn btn-pink d-block">Update</button></div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Copyright />
      <ToastContainer />
    </>
  )
}

export default ResetForgotPassword