import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useGlobalContext } from './GlobalContext';

const AppContext = React.createContext();

const OrderProvider = ({ children }) => {
    const { api_url,userId,sessionType,setCartLength, setIsLoading } = useGlobalContext();
    const [searchKeyword, setSearchKeyword] = useState(null);




    //*******************************************API CALLS********************************************//    
    const [orders, setOrders] = useState([]);
    const getMyOrders = () => {
        const data = {
           'user_id': userId,
        }
        setIsLoading(true)
        axios.post(`${api_url}/getMyOrders`, data)
           .then((res) => {
              if (res.data.success === 1) {
                 setOrders(res.data.data);
              }
              setIsLoading(false)
           })
           .catch((error) => {
              toast.error('Axios Error', {
                 autoClose: 1500,
                 theme: 'colored'
              });
              console.log(error)
              setIsLoading(false)
           })
     }


    return (
        <AppContext.Provider value={
            {
                orders,
                getMyOrders
            }
        }>
            {children}
        </AppContext.Provider>
    );
};

const useOrderContext = () => {
    return useContext(AppContext);
}
export { OrderProvider, useOrderContext }