import React from 'react'
import { hdata } from '../data/Cdata'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'

const OrderHistory = () => {
  return (
    <>
    <Header />
      <main id='main'>
        <section id="order-history">
          <div className="container">
          <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i class="bi bi-chevron-right f-sm"></i> Order History</span>
            </div>
            <h4 className="py-4">Your Order History</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="text-center">
                    <th scope="col">NO.</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Product Image</th>
                    <th scope="col">Class</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Delivery Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    hdata.map((item, i) => {
                      return (
                        <tr key={i} className="text-center">
                          <th scope="row">{i+1}</th>
                          <td className="text-start w-25">{item.title}</td>
                          <td className="text-center">
                            <div className="history-img-contain mx-auto"><img src={item.image} alt="" /></div>
                          </td>
                          <td>{item.class}</td>
                          <td>₹ <del>140.00</del> {item.price}</td>
                          <td>{item.qty}</td>
                          <td>{item.date}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export default OrderHistory