import React from 'react'

const PrintPdf = () => {
    const PrintPdf = () => {
        let printContents = document.getElementById('content').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }
    return (
        <>
            <button onClick={PrintPdf}>Download PDF</button>
            <div id='content'>
                <h2>Heading</h2>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cumque cum deserunt praesentium sapiente dolore, in pariatur itaque ex, inventore fugit aliquid voluptatibus, doloribus qui? Tempora iure ducimus eum quasi modi! Ab animi corrupti dignissimos!</p>
            </div>
        </>

    )
}

export default PrintPdf