import {createSlice} from "@reduxjs/toolkit"

export const Status = Object.freeze(
    {
        success : 'success 1',
        error : 'error 1',
        loading : 'loading...'
    }
)
const api_url = `http://192.168.18.56/api`;

const variableSlice = createSlice({
    name : 'Variable',
    initialState : {},
    reducers : {
        passVariables(state, action) {
            state = {
                api_url
            }
        }
    }
})

export const {passVariables} = variableSlice.actions;
export default variableSlice.reducer;