import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useGlobalContext } from '../contexts/GlobalContext';

const BottomNavigationBar = () => {
    const { setShowNavbarOffCanvas, cartLength, isAuthenticated } = useGlobalContext();
    return (
        <>
            <div className='clear p-4 p-md-0 bg-danger mt-1'></div>
            <div id="bottom_navigation_bar" className='footmobile overflow-hidden fixed-bottom bg-light '>
                <div className="row g-4 mt-1 ">
                    <div className="col-12 mt-0">
                        <ul className='list-inline d-flex justify-content-around text-white mb-0'>
                            <li className='list-inline-item  bottom_navigation_icons'>
                                <NavLink to='/' className='text-decoration-none'>
                                    <div className="d-flex flex-column align-items-center">
                                        <div><i className="bi bi-house fs-1 text-white footicon"></i></div>
                                        <div className="foothome text-white">Home</div>
                                    </div>
                                </NavLink>
                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                <div className="d-flex flex-column align-items-center" onClick={() => setShowNavbarOffCanvas(true)}>
                                    <div><i className="bi bi-stack fs-1 text-white footicon"></i></div>
                                    <div className="foothome text-white">Categories</div>
                                </div>
                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                {
                                    isAuthenticated ?
                                        <NavLink to='/myorders' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-box-seam fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-white">My Order</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <NavLink to='/login' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-box-seam fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-white">My Order</div>
                                            </div>
                                        </NavLink>

                                }

                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                <NavLink to='/cart' className='text-decoration-none'>
                                    <div className="d-flex flex-column align-items-center">
                                        <div><i className="bi bi-cart fs-1 text-white footicon"></i></div>
                                        <div className="foothome text-white">My Cart</div>
                                    </div>
                                </NavLink>
                                {/* <Link to="/cart">
                                    <div className="d-flex flex-column align-items-center">
                                        <div className={cartLength > 0 ? 'cart-animation' : ''}>
                                            <div className={cartLength > 0 ? 'cart-icon' : ''} data-totalitems={cartLength}>
                                                <i className="bi bi-cart headicon"></i>
                                            </div>
                                        </div>
                                        <div className="login">Cart</div>
                                    </div>
                                </Link> */}
                            </li>
                            <li className='list-inline-item bottom_navigation_icons'>
                                {
                                    isAuthenticated ?
                                        <NavLink to='/myprofile' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-person fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-white">Profile</div>
                                            </div>
                                        </NavLink>
                                        :
                                        <NavLink to='/login' className='text-decoration-none'>
                                            <div className="d-flex flex-column align-items-center">
                                                <div><i className="bi bi-person fs-1 text-white footicon"></i></div>
                                                <div className="foothome text-white">Profile</div>
                                            </div>
                                        </NavLink>

                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BottomNavigationBar