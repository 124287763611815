import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const CheckOut = () => {
    const [showAddressModal, setShowAddressModal] = useState(false)

    var totalPayable = 0;
    var totalCartPrice = 0;
    var shippingCharges = 0;
    const navigate = useNavigate();
    const { getCartItems, cartItems, getAllShippingAddress, allShippingAddress } = useGlobalContext();

    cartItems.map((item) => {
        const { qty, mrp, shipping_charges, discounted_price } = item
        let product_quantity = Number(qty);
        shippingCharges += Number(shipping_charges);
        totalPayable += discounted_price * product_quantity;
        totalCartPrice += (mrp * product_quantity) + Number(shipping_charges);

        return null
    })
    var totalDiscount = totalCartPrice - totalPayable

    useEffect(() => {
        getCartItems();
        getAllShippingAddress();
    }, []);
    return (
        <>
            <Header />
            <main>
                <section className='bg-light-gray py-5'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {/* <div className="bg-white p-2 mb-4 b-shadow-2">
                                    Login
                                </div> */}
                                <div className="bg-white mb-4 b-shadow-2">
                                    <div className='bg-pink p-2'>
                                        <h5 className='text-white m-0'>DELIVERY ADDRESS</h5>
                                    </div>
                                    {
                                        allShippingAddress.slice(0, 1).map((address, i) => {
                                            const { id, user_id, name, phone_no, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                            return (
                                                <div key={i} id='address' className='my-2 p-4'>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="d-flex gap-2">
                                                                    <p>{name} <span class="badge rounded-0 bg-secondary">Home</span></p>
                                                                    <p className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</p>
                                                                </div>
                                                                <div>
                                                                    <ul className="list-unstyled">
                                                                        <li>{`${village}, Near ${address.address}`}</li>
                                                                        <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                        <li>{`${city}, ${district}, ${state}`}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className='text-end'>
                                                                    <button className='btn btn-pink' onClick={() => setShowAddressModal(true)}>Change</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className="bg-white mb-4 b-shadow-2">
                                    <div className='bg-pink p-2'>
                                        <h5 className='text-white m-0'>PAYMENT OPTIONS</h5>
                                    </div>
                                    <div id='address' className='my-2 p-4'>
                                        <div className="form-check">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <label htmlFor="upi">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="upi" />
                                                        <div>
                                                            <h5>UPI</h5>
                                                            <p className="fw-bold text-dark">Choose an Option</p>
                                                            <div>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="phonepay" />
                                                                <label htmlFor="phonepay">
                                                                    <p>Phone Pay</p>
                                                                </label>
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="upiid" />
                                                                <label htmlFor="upiid">
                                                                    <p>Your UPI Id</p>
                                                                </label>
                                                                <p className='text-secondary'>Pay by any upi app</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <div>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <h5>Wallets</h5>
                                                        <p className='text-secondary'>Offer Available</p>
                                                    </div>
                                                    <div>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <h5>Credit/ Debit/ ATM Card</h5>
                                                        <p className='text-secondary'>Offer Available</p>
                                                        <p>Add ad and secure your card as per RBI Guidlines</p>
                                                    </div>
                                                    <div>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <h>Net Banking</h>
                                                        <p className='text-secondary'>Lorem ipsum dolor sit amet consectetur.</p>
                                                    </div>
                                                    <div>
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <h>Cash On Delivery</h>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bg-white p-2 b-shadow-2">
                                    <h5>Price Details</h5>
                                    <hr />
                                    <div className='d-flex'>
                                        <p>Price ({cartItems.length} Item)</p>
                                        <p className='ms-auto'>₹{totalCartPrice}</p>
                                    </div>
                                    <div className='d-flex'>
                                        <p>Delivery Charges</p>
                                        <p className='ms-auto'>{shippingCharges !== 0 ? shippingCharges : 'Free'}</p>
                                    </div>
                                    {/* <div className='d-flex'>
                                        <p>Sale Fee</p>
                                        <p className='ms-auto'>Rs. 14</p>
                                    </div> */}
                                    <p className='border-bottom'></p>
                                    <div className='d-flex'>
                                        <h5>Total Payable</h5>
                                        <p className='ms-auto'>₹{totalPayable}</p>
                                    </div>
                                    <hr />
                                    {totalDiscount !== 0 && <p class="badge py-3 px-5 fs-5 bg-light-green text-success d-block">Yay! Your total discount is ₹{totalDiscount}</p>}
                                    <button className='btn btn-pink w-100 p-3 fs-4'>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal size="lg" autoFocus={false} centered show={showAddressModal} onHide={() => setShowAddressModal(false)}>
                        <Modal.Header className='bg-pink text-white' closeButton>
                            <Modal.Title><h5>DELIVERY ADDRESS</h5></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="bg-white">
                                {
                                    allShippingAddress.map((address, i) => {
                                        const { id, user_id, name, phone_no, alternate_phone, village, city, address_type, state, district, post_office, pincode } = address;
                                        let address_id = id;
                                        return (
                                            <div key={i} id='address' className='px-4'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <div className="d-flex gap-2">
                                                                <p>{name} <span class="badge rounded-0 bg-secondary">Home</span></p>
                                                                <p className="fw-bold text-dark">{phone_no}{alternate_phone && `, ${alternate_phone}`}</p>
                                                            </div>
                                                            <div>
                                                                <ul className="list-unstyled">
                                                                    <li>{`${village}, Near ${address.address}`}</li>
                                                                    <li>{`Pincode: ${pincode}, Post Office: ${post_office}`}</li>
                                                                    <li>{`${city}, ${district}, ${state}`}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className='text-end'>
                                                                <Link to={`/editaddress/${address_id}`} className='btn btn-pink'>Edit</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Modal.Body>
                    </Modal>
                </section>
            </main>
        </>
    )
}

export default CheckOut