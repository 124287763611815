import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InfoValidation } from './FormValidation';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../Components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import Loading from '../Components/Loading';
import Copyright from '../Components/Copyright';

const MyProfileInformation = () => {
  const { userId, isLoading, location, setIsLoading, navigate, setIsAuthenticated, onlyNumbers, disabled, handleBtn, setDisabled, api_url, districts, stateData, handleStateChange } = useGlobalContext();
  // const {isAuthenticated} = useGlobalContext();
  const [infoErrors, setInfoErrors] = useState({});
  const [informationData, setInformationData] = useState({
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_no": "",
    // "dob": "",
    "state": "",
    "district": "",
    "post_office": "",
    "address": "",
    "village": "",
    "city": "",
    "pincode": "",
    "school_code": '',
    "classno": ""
  });

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // january is 0
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  let todayDate = yyyy + "-" + mm + "-" + dd;

  //get user information
  useEffect(() => {
    setIsLoading(true);
    axios.get(`${api_url}/getInformation/${sessionStorage.getItem('user_id')}`)
      .then(res => {
        if (res.data.success === 1) {
          let infoData = res.data?.data;
          setIsLoading(false);
          setDisabled(true);
          if (location.state?.schoolcode) {
            setInformationData({ ...infoData, school_code: location.state.schoolcode });
          }
          else {
            setInformationData(res.data?.data);
          }
          console.log(infoData)
          handleStateChange(null, res.data.data.state);
        }
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }, [])

  //Update information
  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = InfoValidation(informationData);
    setInfoErrors(errors);
    console.log(errors)

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios.post(`${api_url}/updateInformation/${sessionStorage.getItem('user_id')}`, informationData)
        .then(res => {
          if (res.data.success === 1) {
            setIsLoading(false);
            setDisabled(true)
            sessionStorage.removeItem('school_code')
            res.data.data.school_code !== null && sessionStorage.setItem('school_code', res.data.data.school_code);
            toast.success(res.data.message, {
              autoClose: 1500,
              theme: 'colored'
            });
            const timer = setTimeout(() => {
              navigate(-1);
            }, 2000)
            return () => clearTimeout(timer);
          } else {
            toast.error(res.data.message, {
              autoClose: 2000,
              theme: 'colored'
            });
            setIsLoading(false);
          }
        })
        .catch(err => {
          toast.error('Axios Error', {
            autoClose: 2000,
          });
          console.log(err)
          setIsLoading(false);
        })
    }
  };


  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [])


  const handleChange = (e) => {
    setInformationData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleState = (e) => {
    handleStateChange(e);
    setInformationData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  return (
    <>
      {isLoading ? <Loading /> : null}
      <Header />
      <main id='main'>
        <section id="my-personal-information" className="">
          <div className="container">
            <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i class="bi bi-chevron-right f-sm"></i> My Information</span>
            </div>
            <div className='py-lg-5 pb-5'>
              <div className="row">
                <div className="col-lg-5">
                  <div className="h-100 d-flex align-items-center">
                    <img src={require("../assets/images/personal-info.png")} className="d-block img-fluid mx-auto" alt="" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="border f-shadow">
                    <div className="mx-auto">
                      <p className="h4 p-3 bg-pink text-white m-0">Your Personal Information</p>
                    </div>
                    <form className="mx-auto p-4">
                      <fieldset disabled={false}>
                        <div className="row g-3">
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='first_name' value={informationData.first_name} id="name" placeholder="name" />
                              <label htmlFor="name">First Name <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.first_name}</div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='last_name' value={informationData.last_name} id="name" placeholder="name" />
                              <label htmlFor="name">Last Name <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.last_name}</div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="tel" className="form-control" onKeyDown={(e) => onlyNumbers(e)} onChange={handleChange} maxLength={10} name='phone_no' value={informationData.phone_no} id="phone" placeholder="phone" />
                              <label htmlFor="phone">Contact Number <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.phone_no}</div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-floating">
                              <input type="date" max={todayDate} className="form-control" onChange={handleChange} name='dob' value={informationData.dob} id="dob" placeholder="dob" />
                              <label htmlFor="dob">Select Date of Birth<span className="text-danger">*</span></label>
                              <div className="text-danger">{infoErrors.dob}</div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="email" className="form-control" onChange={handleChange} name='email' value={informationData.email} id="email" placeholder="email" />
                              <label htmlFor="email">Email <span className="text-danger"></span></label>
                            </div>
                            <div className="text-danger">{infoErrors.email}</div>
                          </div>
                          <div className="col-md-6">
                            <select className="form-select py-3" onChange={(e) => handleState(e)} name='state' value={informationData.state} aria-label="Default select example">
                              <option value="Select State">--Select State--</option>
                              {
                                stateData.map((state, i) => {
                                  return (
                                    <option key={i} value={state.state_name}>{state.state_name}</option>
                                  )
                                })
                              }
                            </select>
                            <div className="text-danger">{infoErrors.state}</div>
                          </div>
                          <div className="col-md-6">
                            <select className="form-select py-3" onChange={handleChange} name='district' value={informationData.district} aria-label="Default select example">
                              <option>--Select District--</option>
                              {
                                districts.map((district, i) => {
                                  return (
                                    <option key={i} value={district}>{district}</option>
                                  )
                                })
                              }
                            </select>
                            <div className="text-danger">{infoErrors.district}</div>
                          </div>
                          <div className="col-12">
                            <div className={infoErrors.post_office ? `form-floating border border-danger rounded` : `form-floating`}>
                              <input type="text" className="form-control" onChange={handleChange} name='post_office' value={informationData.post_office} id="postOffice" placeholder="postoffice" />
                              <label htmlFor="postOffice">Post Office <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.post_office}</div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='address' value={informationData.address} id="address" placeholder="address" />
                              <label htmlFor="address">Land Mark/House no./Building  name <span className="text-danger"></span></label>
                            </div>
                            {/* <div className="text-danger">{infoErrors.address}</div> */}
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='village' value={informationData.village} id="villa" placeholder="villa-no" />
                              <label htmlFor="villa">Village/Ward No. <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.village}</div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='city' value={informationData.city} id="city" placeholder="city" />
                              <label htmlFor="city">City <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.city}</div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='pincode' value={informationData.pincode} id="zipcode" placeholder="zipcode" />
                              <label htmlFor="zipcode">Pincode <span className="text-danger">*</span></label>
                            </div>
                            <div className="text-danger">{infoErrors.pincode}</div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='school_code' value={informationData.school_code} id="schoolcode" placeholder="schoolcode" />
                              <label htmlFor="schoolcode">School Code <span className="text-danger"></span></label>
                            </div>
                            {/* <div className="text-danger">{infoErrors.school_code}</div> */}
                          </div>
                          {/* <div className="col-sm-6">
                            <div className="form-floating">
                              <input type="text" className="form-control" onChange={handleChange} name='classno' value={informationData.classno} id="classno" placeholder="city" />
                              <label htmlFor="classno">Class <span className="text-danger"></span></label>
                            </div>
                          </div> */}
                        </div>
                      </fieldset>
                      <div className="d-flex justify-content-between mt-3">
                        <span onClick={() => navigate(-1)} className="btn btn-outline-pink px-3 px-sm-4">Go Back</span>
                        <button onClick={(e) => handleSubmit(e)} className="btn btn-pink px-4 px-sm-5">Update</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Copyright />

      <ToastContainer />
    </>
  )
}

export default MyProfileInformation