import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import ProductCard from '../Components/ProductCard'
import Cdata from '../data/Cdata'
import ProductModal from '../Components/ProductModal'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import { useGlobalContext } from '../contexts/GlobalContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useInventoryContext } from '../contexts/InventoryContext'
import CarouselSlider from '../Components/CarouselSlider/CarouselSlider'

const VendorShop = () => {
    const [vendorDetails, setVendorDetails] = useState({});
    const { setIsLoading, api_url, img_url, allInventory, getAllInventory } = useGlobalContext();
    const { vendorInventory, getVendorInventory, vendorPageData } = useInventoryContext();
    const params = useParams();
    const location = useLocation();

    //getVendor
    const getVendor = async () => {
        const data = {
            'vendor_id': location.state?.vendor_id
        }
        setIsLoading(true)
        try {
            let res = await axios.post(`${api_url}/vendorDetails`, data);
            if (res.data.success === 1) {
                setVendorDetails(res.data.data)
            }
        } catch (error) {
            toast.error('Something Wrong Happened', {
                theme: "colored",
                autoClose: 2000,
            });
        } finally {
            setIsLoading(false)
        }
    }

    const handleNextPage = () => {
        getVendorInventory(vendorPageData.next_page_url, location.state?.vendor_id)
        window.scrollTo(0, 600)
    }

    const handlePreviousPage = () => {
        getVendorInventory(vendorPageData.prev_page_url, location.state?.vendor_id)
        window.scrollTo(0, 600)
    }

    console.log(vendorInventory);
    console.log(allInventory.length)
    useEffect(() => {
        getVendor();
        getVendorInventory(`${api_url}/vendorInventory`, location.state?.vendor_id);
    }, [])

    const carouselItems = vendorInventory.map((card, i) => {
        const { id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
        return (
            vendor_id === location.state?.vendor_id ?
                <ProductCard
                    key={i}
                    id={id}
                    product_id={product_id}
                    image={`${img_url}/${folder}/${image}`}
                    title={product_name}
                    mrp={mrp}
                    discounted_price={discounted_price}
                    shipping_charges={shipping_charges}
                    type={type}
                    text={(description)}
                    Card={card}
                    itemExistInCart={itemExistInCart}
                    itemExistInWishlist={itemExistInWishlist}
                />
                : null
        )
    })

    return (
        <>
            <Header />
            <main id='main'>

                {/* <!-- section-1-banner start --> */}
                <section id="shop-banner" className="border-bottom border-dark">
                    <div id="carouselExampleCaptions" className="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='banner_img_contain'>
                                    {
                                        vendorDetails?.site_background_img ?
                                            <img src={`${img_url}/${vendorDetails?.folder}/${vendorDetails?.site_background_img}`} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                                            :
                                            <img src={`${img_url}/site_img/e-marketplace.jpg`} className="object-fit-fill" width='100%' height='100%' alt="banner" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- section-2-shoptitle-start --> */}
                <section id="shop-title" className="py-5">
                    <div className="container py-4">
                        <div className="row mx-1">
                            <div className="col-md-3">
                                <img id="shop-icon" src={require('../assets/images/store-icon.png')} alt="shop-icon" className=" img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-md-6">
                                <h2 className='text-danger'>{vendorDetails?.username}</h2>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <span><strong className="mx-2">{vendorDetails?.total_vendor_inventory}</strong>Products</span>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-3 ">
                                <button id="follow-btn"> + Follow Us</button>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <h2 className="text-center pb-5">Featured Products</h2>
                        <div className="row text-center">
                            <div className="col-md-3">
                                <Link to="" className=" btn rounded-5">
                                    <div className="card">
                                        <div className="bg">
                                            <h2>New Arrivals</h2>
                                            <button id="follow-btn">More</button>
                                        </div>
                                        <div className="blob"></div>

                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="" className="btn rounded-5">
                                    <div className="card">
                                        <div className="bg">
                                            <h2>High Quality</h2>
                                            <button id="follow-btn">More</button>
                                        </div>
                                        <div className="blob"></div>

                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="" className="btn rounded-5">
                                    <div className="card">
                                        <div className="bg">
                                            <h2>Best Selling</h2>
                                            <button id="follow-btn">More</button>
                                        </div>
                                        <div className="blob"></div>

                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-3">
                                <Link to="" className="btn rounded-5">
                                    <div className="card">
                                        <div className="bg">
                                            <h2>All Products</h2>
                                            <button id="follow-btn">More</button>
                                        </div>
                                        <div className="blob"></div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                {/* New Arrivals */}
                <CarouselSlider items={carouselItems} title='New Arrivals' auto_play={false} display_dots={true} />

                {/* <!-- product and category section --> */}
                <section id="product" className="my-5">
                    <div className="container-fluid">
                        {
                            vendorInventory.length !== 0 &&
                            <div className="d-flex flex-wrap gap-md-2 justify-content-around">
                                {
                                    vendorInventory &&
                                    vendorInventory.map((card, i) => {
                                        const { id, product_id, image, folder, product_name, mrp, type, description, discounted_price, shipping_charges, vendor_id, itemExistInCart, itemExistInWishlist } = card;
                                        return (
                                            vendor_id === location.state?.vendor_id ?
                                                <div key={i} className="item">
                                                    <ProductCard
                                                        id={id}
                                                        product_id={product_id}
                                                        image={`${img_url}/${folder}/${image}`}
                                                        title={product_name}
                                                        mrp={mrp}
                                                        discounted_price={discounted_price}
                                                        shipping_charges={shipping_charges}
                                                        type={type}
                                                        text={(description)}
                                                        Card={card}
                                                        itemExistInCart={itemExistInCart}
                                                        itemExistInWishlist={itemExistInWishlist}
                                                    />
                                                </div>
                                                : null
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </section>


                {/* <!-- //pagination --> */}
                <div className='d-flex justify-content-end me-5'>
                    <ul className='pagination'>
                        {
                            vendorPageData.prev_page_url ? (
                                <li className='page-item'>
                                    <button className='page-link' onClick={handlePreviousPage}>
                                        <span className='text-danger'>Previous</span>
                                    </button>
                                </li>
                            ) : null
                        }
                        {
                            vendorPageData.next_page_url ? (
                                <li className='page-item'>
                                    <button className='page-link' onClick={handleNextPage}>
                                        <span className='text-danger'>Next</span>
                                    </button>
                                </li>
                            ) : null
                        }
                    </ul>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default VendorShop