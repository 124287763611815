import React from 'react'

const Copyright = () => {
    return (
        <>
            <div className=''>
                <div className="text-center p-3 bg-light">
                    Copyright &copy; 2018 V4Masters E-Vyapari. All Right Reserved.
                </div>
            </div>
        </>
    )
}

export default Copyright