import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PassValidation } from './FormValidation';
import axios from 'axios';
import { useGlobalContext } from '../contexts/GlobalContext';
import Loading from '../Components/Loading';
import Copyright from '../Components/Copyright';
import Header from '../Components/Header';

const ChangePassword = () => {
  const { isLoading, setIsLoading, api_url, userId } = useGlobalContext();
  const navigate = useNavigate();
  const [passErrors, setPassErrors] = useState({});
  const [passData, setPassData] = useState({
    "user_id": userId,
    "current_password": '',
    "new_password": ''
  });

  // Show and Hide password
  const [passShow1, setPassShow1] = useState(true);
  const [passShow2, setPassShow2] = useState(true);
  const [passShow3, setPassShow3] = useState(true);
  const [type1, setType1] = useState('password')
  const [type2, setType2] = useState('password')
  const [type3, setType3] = useState('password')
  const togglePassword = (flag) => {
    if (flag === 1 && type1 === 'password') {
      setType1('text');
      setPassShow1(!passShow1)
    } else if (flag === 1 && type1 === 'text') {
      setType1('password');
      setPassShow1(!passShow1)
    }

    if (flag === 2 && type2 === 'password') {
      setType2('text');
      setPassShow2(!passShow2)
    } else if (flag === 2 && type2 === 'text') {
      setType2('password');
      setPassShow2(!passShow2)
    }

    if (flag === 3 && type3 === 'password') {
      setType3('text');
      setPassShow3(!passShow3)
    } else if (flag === 3 && type3 === 'text') {
      setType3('password');
      setPassShow3(!passShow3)
    }
  }

  const handleChange = (e) => {
    setPassData({ ...passData, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let errors = PassValidation(passData);
    setPassErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsLoading(true)
      axios.post(`${api_url}/changePassword`, passData)
        .then(res => {
          if (res.data.success === 1) {
            setIsLoading(false)
            toast.success(res.data.message, {
              theme: 'colored',
              autoClose: 2000,
            });
            let timer = setTimeout(() => {
              // sessionStorage.removeItem('token');
              navigate(-1);
            }, 3000);
            return () => clearTimeout(timer);
          } else {
            setIsLoading(false);
            setPassData({
              ...passData,
              "current_password": '',
              "new_password": '',
            })
            document.getElementById('cpassword').value = '';
            toast.error(res.data.message, {
              autoClose: 2000,
            });
          }
        })
        .catch(err => {
          console.log(err);
          toast.error('Axios Error', {
            autoClose: 2000,
          });
          setIsLoading(false);
        })
    }
  }

  return (
    <>
      {isLoading ? <Loading /> : null}
      <Header />
      <main id='main'>
        <section id="passward">
          <div className="container">
            <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i class="bi bi-chevron-right f-sm"></i> Change Password</span>
            </div>
            <div className="py-5">
              <div className="row justify-content-center">
                <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4">
                  <div className="mx-auto p-4"><img src="../assets/images/logo.png" className="d-block mx-auto" width="150" alt="" /></div>
                  <div className="f-shadow">
                    <div className="mx-auto h6 m-0 p-3 bg-pink text-white">Change Your Password</div>
                    <form onSubmit={handleSubmit} className="mx-auto border p-4">
                      <div className="form-floating mb-3">
                        <input type={type1} className="form-control" onChange={handleChange} name='current_password' value={passData.current_password} id="current_password" placeholder="Password" />
                        <label htmlFor="current_password">Current Password <span className="text-danger">*</span></label>
                        <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(1)}>{passShow1 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow1 ? 'Show' : 'Hide'}</span>
                        <div className="text-danger">{passErrors.current_password}</div>
                      </div>
                      <div className="form-floating mb-3">
                        <input type={type2} className="form-control" onChange={handleChange} name='new_password' value={passData.new_password} id="new_password" placeholder="Password" />
                        <label htmlFor="new_password">New Password <span className="text-danger">*</span></label>
                        <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(2)}>{passShow2 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow2 ? 'Show' : 'Hide'}</span>
                        <div className="text-danger">{passErrors.new_password}</div>
                      </div>
                      <div className="form-floating mb-3">
                        <input type={type3} className="form-control" onChange={handleChange} name='cpassword' id="cpassword" placeholder="Password" />
                        <label htmlFor="cpassword">Confirm Password <span className="text-danger">*</span></label>
                        <span className='text-danger s-font2 cursor-pointer position-absolute p-3 top-0 end-0' onClick={() => togglePassword(3)}>{passShow3 ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>} {passShow3 ? 'Show' : 'Hide'}</span>
                        <div className="text-danger">{passErrors.cpassword}</div>
                      </div>
                      <div className="row">
                        <div className="col"><Link to='/myprofile' className="btn btn-outline-pink">Go Back</Link></div>
                        <div className="col "><button type="submit" className="btn btn-pink d-block ms-auto">Update</button></div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Copyright />
      <ToastContainer />
    </>
  )
}

export default ChangePassword