import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StateDistrict from '../data/StateDistrict.json';
import * as Icon from 'react-bootstrap-icons';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [countries, setCountries] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [showSchoolModal, setShowSchoolModal] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState(null);

    const api_url = `https://evyapari.com/admin/public/api`;
    const img_url = `https://evyapari-new.s3.ap-south-1.amazonaws.com`;


    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const handleBtn = (e) => {
        setDisabled(false);
    };

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const [registerData, setRegisterData] = useState({
        "first_name": "",
        "last_name": "",
        "phone_no": "",
        "state": "",
        "district": "",
        "email": "",
        "password": "",
    });
    //changeHandler (step 1, step2, step3)
    const changeHandler = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        setRegisterData({ ...registerData, [key]: value });
    }

    const onlyNumbers = (e) => {
        let input = String.fromCharCode(e.which);
        if (!(/[0-9\b]/.test(input))) {
            e.preventDefault();
        }
    }

    // Convert to html code plain text
    function convertToPlain(html_code) {
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html_code;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // Convert to html code plain text
    function convertToHtml(htmlStr) {
        const parser = new DOMParser();
        const document = parser.parseFromString(htmlStr, "text/html");
        return document.body.innerHTML;
    }

    const [showNavbarOffCanvas, setShowNavbarOffCanvas] = useState(false)

    //----------------------------------------- State & Districts -------------------------------------//
    //My Personal Information, //Add Address, //Edit AAddress, //checkout, //My School
    const [districts, setDistricts] = useState([]);
    let stateData = StateDistrict.India;
    const handleStateChange = (e, state = null) => {
        let getState;
        if (e) {
            getState = e.target.value;
        } else if (state) {
            getState = state;
        }
        const getDistricts = stateData.find(state => state.state_name === getState).districts;
        setDistricts(getDistricts);
    }

    //------------------------------------ userId, sessionType & schoolCode ----------------------------------//
    let userId, sessionType;
    if (sessionStorage.getItem('user_id')) {
        userId = sessionStorage.getItem('user_id');
        sessionType = "user";
    } else {
        userId = sessionStorage.getItem('guest_id');
        sessionType = "guest";
    }
    let schoolCode = sessionStorage.getItem('school_code');
    // console.log("schoolCodeschoolCode",schoolCode)
    //------------------------------------------------------------------------------------------//

    //*******************************************All API CALLS********************************************//    

    // Get User information
    const [userInformation, setUserInformation] = useState();
    const getUserInformation = () => {
        setIsLoading(true);
        axios.get(`${api_url}/getInformation/${sessionStorage.getItem('user_id')}`)
            .then(res => {
                if (res.data.success === 1) {
                    setUserInformation(res.data.data);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    //--------------------------------------- Addresses -------------------------------------//
    //get All Shipping Addresses
    const [allShippingAddress, setAllShippingAddress] = useState([]);
    const data = {
        user_id: userId,
    }
    const getAllShippingAddress = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/getAllShippingAddress`, data);
            setAllShippingAddress(res.data.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    //--------------------------------------- All Categories -------------------------------------//
    const [categoryData, setCategoryData] = useState([]);
    const getCategories = async () => {
        setIsLoading(true)
        try {
            setIsLoading(false)
            const res = await axios.get(`${api_url}/category`);
            setCategoryData(res.data.data);
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    }

    //--------------------------------------- All Inventory -------------------------------------//
    const [allInventory, setAllInventory] = useState([]);
    const getAllInventory = async (searchKeyword = null, cat_id = null, subcat_id = null, subcat2_id = null) => {
        const data = {
            'user_id':userId,
            "searchKeyword": searchKeyword,
            "cat_id": cat_id,
            "subcat_id": subcat_id,
            "subcat2_id": subcat2_id
        };
        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/allInventory`, data);
            setAllInventory(res.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }


    const [info, setInfo] = useState({});
    const [inventory, setInventory] = useState([]);
    const inventory_url = `${api_url}/pageInventory`;
    const callPageInventory = async (url=inventory_url, keyword=null) => {
        const data = {
            "searchKeyword": keyword,
        }
        setIsLoading(true);
        try {
            const res = await axios.post(url, {
                headers: {
                    'Content-type': 'application/json'
                },
                data
            });
            setInventory(res.data.data.data);
            setInfo(res.data.data)
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    //--------------------------------------- Inventory Details -------------------------------------//
    const [inventoryDetails, setInventoryDetails] = useState([]);
    const [inventoryImages, setInventoryImages] = useState([]);
    const getInventoryDetails = async (product_id) => {
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/inventoryDetail/${product_id}`);
            setInventoryDetails(res.data.data.inventory);
            setInventoryImages(res.data.data.inv_images);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    //--------------------------------Get Cart Products---------------------------------//
    const [cartItems, setCartItems] = useState([]);
    const [cartLength, setCartLength] = useState(0);
    const getCartItems = async () => {
        const data = {
            'user_id': userId
        }
        try {
            const res = await axios.post(`${api_url}/getCartItems`, data);
            setCartItems(res.data.data);
            setCartLength(res.data.data.length)
        } catch (error) {
            console.log(error)
        }
    }

    //--------------------------------------- Add to Cart -------------------------------------//
    const handleAddToCart = async (product_id, qty = 1) => {
        const cartData = {
            "user_id": userId,
            "product_id": product_id,
            "session_type": sessionType,
            "qty": qty,
        };

        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/addCartProduct`, cartData);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 1500,
                    theme: 'colored',
                    style: { color: '#000000' },
                    icon: <Icon.Check />
                })
                setAllInventory(cartItems =>
                    cartItems.map(CartItem =>
                        CartItem.id === product_id ? { ...CartItem, itemExistInCart: true } : CartItem
                    )
                );
                setAllInventory(cartItems =>
                    cartItems.map(CartItem =>
                        CartItem.id === product_id ? { ...CartItem, itemExistInCart: true } : CartItem
                    )
                );

                setCartLength((prev) => prev + 1);
            }
            else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    // -------------------------------------- Buy Now ------------------------------------------- //
    const handleBuyNow = async (pid, qty = 1) => {
        const cartData = {
            "user_id": userId,
            "product_id": pid,
            "session_type": sessionType,
            "qty": qty,
        };
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/addCartProduct`, cartData);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 2000,
                    theme: 'colored',
                })
            }
            else {
                toast.error(res.data.message, {
                    autoClose: 1500,
                })
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 2000,
            })
        } finally {
            setIsLoading(false)
            navigate('/cart')
        }
    }

    //--------------------------------Remove Item From Cart---------------------------------//
    const removeCartItem = async (e, product_id) => {
        e.preventDefault();
        const data = {
            "user_id": userId,
            "product_id": product_id
        }

        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/removeItemFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored',
                    style: { color: '#000000' },
                    icon: <Icon.Check />
                })
                setCartItems(cartItems =>
                    cartItems.filter((item) =>
                        product_id !== item.id
                    )
                );
                setCartLength((prev) => prev - 1);
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
            }
        } catch (error) {
            toast.error('Axios Error(removeCartItem)', {
                autoClose: 3000
            })
        } finally {
            setIsLoading(false)
        }
    }

    //--------------------------------Remove Set From Cart---------------------------------//
    const removeSetFromCart = async (e, set_id) => {
        e.preventDefault();
        const data = {
            "user_id": userId,
            "set_id": set_id
        }
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/removeSetFromCart`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
                setCartItems(cartItems =>
                    cartItems.filter((item) =>
                        set_id !== item.set_id
                    )
                );
                setCartLength((prev) => prev - res.data.data);
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
            }
        } catch (error) {
            toast.error('Axios Error(remove Set)', {
                autoClose: 3000
            })
        } finally {
            setIsLoading(false)
        }
    }

    // --------------------------------------- Save For Later ------------------------------------ //
    const saveForLater = async (pid) => {
        const data = {
            "user_id": userId,
            "product_id": pid
        }
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/saveForLater`, data);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 3000
                })
                const timer = setTimeout(() => {
                    window.location.reload(false)
                }, 2000)
                return () => clearTimeout(timer);
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }


    // -----------------------------------Add To Wishlist------------------------------------//
    const [wishlistData, setWishlistData] = useState([]);
    const handleAddToWishlist = (product_id) => {
        const data = {
            "user_id": userId,
            "product_id": product_id,
            "session_type": sessionType,
        };
        setIsLoading(true);
        axios.post(`${api_url}/addToWishlist`, data)
            .then((res) => {
                if (res.data.success === 1) {
                    setIsLoading(false);
                    toast.success(res.data.message, {
                        autoClose: 1500,
                        theme: 'colored',
                    })
                    setAllInventory(Items =>
                        Items.map(Item =>
                            Item.id === product_id ? { ...Item, itemExistInWishlist: true } : Item
                        )
                    );
                }
                else {
                    setIsLoading(false);
                    toast.error(res.data.message, {
                        autoClose: 2000,
                        theme: 'colored',
                        position: 'top-center'
                    })
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err)
            })
    }
    //--------------------------------Get All Wishlist Items----------------------------------------//
    const getAllWishlistItems = async () => {
        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/viewWishlist`, { user_id: userId });
            setWishlistData(res.data.data);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    };
    //--------------------------------Remove Item From Wishlist---------------------------------//
    const removeWishlistItem = async (product_id) => {
        const data = {
            "user_id": userId,
            "product_id": product_id
        }
        setIsLoading(true)
        try {
            const res = await axios.post(`${api_url}/removeWishlistItem`, data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored',
                    style: { color: '#000000' },
                })
                setAllInventory(Items =>
                    Items.map(Item =>
                        Item.id === product_id ? { ...Item, itemExistInWishlist: false } : Item
                    )
                );
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }


    //---------------------------------------My School---------------------------------------//
    // Get Schools
    const [schools, setSchools] = useState([]);
    const getSchools = async () => {
        setIsLoading(true);
        try {
            const res = await axios.get(`${api_url}/getSchools`);
            setSchools(res.data.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    // Get School Information
    const [schoolData, setSchoolData] = useState([])
    const getSchoolInformation = () => {
        axios.post(`${api_url}/getSchoolInfo`, { 'school_code': schoolCode })
            .then((res) => {
                if (res.data.success === 1) {
                    setSchoolData(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    //getSchoolSet
    const [schoolSetData, setSchoolSetData] = useState([])
    const getSchoolSet = async (cat_id = null, class_id = null) => {
        const data = {
            "school_code": schoolCode,
            'cat_id': cat_id,
            'class_id': class_id
        }
        setIsLoading(true);
        try {
            const res = await axios.post(`${api_url}/getSchoolSet`, data);
            if (res.data.success === 1) {
                setSchoolSetData(res.data.data)
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }


    //*******************************************All API CALLS END********************************************//

    return (
        <AppContext.Provider value={
            {
                navigate,
                location,
                params,
                showNavbarOffCanvas,
                setShowNavbarOffCanvas,
                setShowSchoolModal,
                showSchoolModal,
                registerData,
                changeHandler,
                currentStep,
                setCurrentStep,
                isAuthenticated,
                setIsAuthenticated,
                isLoading,
                setIsLoading,
                isEdit,
                setIsEdit,
                onlyNumbers,
                countries,
                setCountries,
                disabled,
                handleBtn,
                setDisabled,
                api_url,
                img_url,
                convertToPlain,
                convertToHtml,
                handleAddToCart,
                handleAddToWishlist,
                removeWishlistItem,
                wishlistData,
                getAllWishlistItems,
                handleBuyNow,
                removeCartItem,
                saveForLater,
                cartItems,
                setCartItems,
                getCartItems,
                cartLength,
                setCartLength,
                categoryData,
                getCategories,
                getInventoryDetails,
                inventoryDetails,
                getAllInventory,
                allInventory,
                inventoryImages,
                userId,
                sessionType,
                schoolCode,
                handleStateChange,
                districts,
                setDistricts,
                stateData,
                allShippingAddress,
                setAllShippingAddress,
                getAllShippingAddress,
                getUserInformation,
                userInformation,
                setAllInventory,
                setWishlistData,
                getSchoolSet,
                schoolSetData,
                removeSetFromCart,
                getSchoolInformation,
                schoolData,
                searchKeyword,
                setSearchKeyword,
                schools,
                getSchools,
                callPageInventory,
                info,
                inventory
            }
        }>
            {children}
        </AppContext.Provider>
    );
};

const useGlobalContext = () => {
    return useContext(AppContext);
}
export { AppContext, AppProvider, useGlobalContext }