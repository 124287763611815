import React, { useState } from 'react'
import { useGlobalContext } from '../contexts/GlobalContext'

const Step1 = () => {
    const { registerData, changeHandler, setCurrentStep } = useGlobalContext();
    const { first_name, last_name } = registerData;
    const step1_errors = {}
    const [errors, setErrors] = useState({});
    const nameRegex = /^[a-zA-Z]*$/;

    if (first_name === '') {
        step1_errors.first_name = "First name can't be empty"
    } 
    // else if (!(nameRegex.test(first_name))) {
    //     step1_errors.first_name = "Name can only contain alphabets"
    // }
    if (last_name === '') {
        step1_errors.last_name = "Last name can't be empty"
    } 
    // else if (!(nameRegex.test(last_name))) {
    //     step1_errors.last_name = "Name can only contain alphabets"
    // }

    const handleClick = () => {
        setErrors(step1_errors);

        if (Object.keys(step1_errors).length === 0) {
            setCurrentStep(2)
        }
    };

    return (
        <>
            <div>
                <div className="form-floating my-3">
                    <input type="text" id="first_name" name="first_name" value={registerData.first_name} onChange={(e) => changeHandler(e)} className="form-control" placeholder='first_name' />
                    <label className="form-label" htmlFor="first_name">First Name</label>
                    <div className='text-danger s-font2'>{errors.first_name}</div>
                </div>
                <div className="form-floating my-3">
                    <input type="text" id="last_name" name="last_name" value={registerData.last_name} onChange={(e) => changeHandler(e)} className="form-control" placeholder='last_name' />
                    <label className="form-label" htmlFor="last_name">Last Name</label>
                    <div className='text-danger s-font2'>{errors.last_name}</div>
                </div>
            </div>
            <div className="overflow-auto">
                <div className="float-end">
                    <button type="button" className="btn btn-danger" id="nextBtn" onClick={handleClick}>Next</button>
                </div>
            </div>
        </>
    )
}

export default Step1