import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

const PaymentStatus = () => {
    const { setIsLoading, userId, api_url } = useGlobalContext();
    const [paymentStatus, setPaymentStatus] = useState({});
    const params = useParams();
    useEffect(() => {
        getPaymentStatus();
    }, [])

    //orderPreview
    const getPaymentStatus = async () => {
        const data = {
            'user_id': userId,
            'order_id': params.order_id,
        }
        setIsLoading(true);
        try {
            let res = await axios.post(`${api_url}/get_payment_status`, data)
            if (res.data.success === 1) {
                setPaymentStatus(res.data.data);
            }
            else {
                toast.error('Something Went Wrong', {
                    autoClose: 1500,
                    theme: 'colored'
                });
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 1500,
                theme: 'colored'
            });
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Header />
            <main id='main'>
                <section className='py-5'>
                    <div className='row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <div className="border b-shadow zoom-2 rounded p-3 pt-0 ">
                                {
                                    (() => {
                                        switch (paymentStatus?.status) {
                                            case 0:
                                                return (
                                                    <div className='text-center'>
                                                        <div>
                                                            <img src={require('../assets/images/order_failed.gif')} height={180} alt="" />
                                                            {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                        </div>
                                                        <p className='h3 text-danger my-3'>Order Failed (if payment is deducted from your account, then status will be updated within 24 hrs)</p>
                                                    </div>
                                                );
                                            case 1:
                                                return (
                                                    paymentStatus?.pay_mode === 'COD' ?
                                                        <div className='text-center'>
                                                            <img src={require('../assets/images/namaste.gif')} className='me-3' height={100} alt="namaste" />
                                                            <div>
                                                                <img src={require('../assets/images/logo2.jpg')} height={80} alt="logo" />
                                                            </div>
                                                            <div className='h3 color-pink'>Thank you {paymentStatus?.name}</div>
                                                            <div className='h3 text-success mb-3'>Your COD Order Has Been Placed Successfully</div>
                                                        </div>
                                                        :
                                                        <div className='text-center'>
                                                            <img src={require('../assets/images/namaste.gif')} className='me-3' height={100} alt="namaste" />
                                                            <div>
                                                                <img src={require('../assets/images/logo2.jpg')} height={80} alt="logo" />
                                                                {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                            </div>
                                                            {/* <img src={require('../assets/images/namaste.gif')} height={100} alt="namaste" /> */}
                                                            <div className='h3 color-pink'>Thank you {paymentStatus?.name}</div>
                                                            <div className='h3 text-success mb-3'>Your Order Has Been Placed Successfully</div>
                                                        </div>
                                                );
                                            case 2:
                                                return (
                                                    <div className='text-center'>
                                                        <div>
                                                            {/* <img src={require('../assets/images/order_successfull.gif')} height={180} alt="" /> */}
                                                            {/* <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div> */}
                                                        </div>
                                                        <p className='h3 text-warning my-3'>Payment in Process (if payment is deducted from your account, then status will be updated within 24 hrs)</p>
                                                    </div>
                                                );
                                            default:
                                                return (
                                                    <div className='text-center pt-4'>
                                                        <p className='h4'>Payment Status</p>
                                                    </div>
                                                );
                                        }
                                    })()
                                }
                                {
                                    paymentStatus?.pay_mode === 'COD' ?
                                        <div className="">
                                            <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div>
                                            <div className="r_font1 fw-bold ls-tight text-center">Order Id : {paymentStatus?.order_id}</div>
                                            {/* <div className="r_font1 fw-bold ls-tight text-center">Transaction Id : {paymentStatus?.transaction_id}</div> */}
                                        </div>
                                        :
                                        <div className="">
                                            <div className=" fs-4 fw-bold ls-tight text-center">₹{paymentStatus?.transaction_amount}</div>
                                            <div className="r_font1 fw-bold ls-tight text-center">Order Id : {paymentStatus?.order_id}</div>
                                            <div className="r_font1 fw-bold ls-tight text-center">Transaction Id : {paymentStatus?.transaction_id}</div>
                                        </div>
                                }

                                <div className='text-center mb-3'>
                                    <Link to={'/myorders'} className='btn btn-pink'>View My Orders</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default PaymentStatus