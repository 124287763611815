import React from 'react'
import { Link } from 'react-router-dom'
import Copyright from '../Components/Copyright';
import TermsConditions from './TermsConditions';

const Footer = () => {
    return (
        <>
            {/* <!-----------------------------------Footer Starts-------------------------------------> */}
            <footer id="footer" className="text-center text-lg-start bg-light text-muted overflow-hidden">
                {/* <!-- Section: Social media --> */}
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    {/* <!-- Left --> */}
                    <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>

                    {/* <!-- Right --> */}
                    <div className="social-media-icon d-flex justify-content-center">
                        <Link to="https://www.facebook.com/evyapari" className="me-5 link-secondary rounded"
                            target="_blank"><img src={require("../assets/images/social_media/facebook.png")} width="20px" alt="facebook" />
                        </Link>
                        <Link to="" className="me-5 link-secondary rounded" target="_blank">
                            <img src={require("../assets/images/social_media/insta.png")} width="25px" alt="instagram" />
                        </Link>
                        <Link to="" className="me-5 link-secondary rounded" target="_blank">
                            <img src={require("../assets/images/social_media/google.png")} width="25px" alt="google" />
                        </Link>
                        <Link to="https://www.youtube.com/channel/UCo5W32KH2sCx82bodF3Zk0Q"
                            className="me-lg-5 link-secondary rounded" target="_blank">
                            <img src={require("../assets/images/social_media/youtube.png")} width="25px" alt="youtube" />
                        </Link>
                    </div>
                </section>
                {/* <!-- Section: Social media --> */}

                {/* <!-- Section: Links  --> */}
                <section className="">
                    <div className="container text-center text-md-start py-5">
                        <div className="row">
                            <div className="col-xl-3 mx-auto">
                                <div className="mb-4">
                                    <img src={require("../assets/images/logo2.jpg")} className="footer-logo d-block img-fluid mx-auto" alt="" />
                                </div>
                                <p className="mx-auto">V4 Masters E-Vyapari : Most leading service provider for all kinds of student and school Requirements.</p>
                            </div>

                            <div className="col-6 col-md-3 col-xl-2 mx-auto">
                                <h6 className="fw-bold mb-4">
                                    Company Info
                                </h6>
                                <p><Link to="#" className="text-reset">About Us</Link></p>
                                <p><Link to="#" className="text-reset">Contact Us</Link></p>
                                <p><span className='cursor-pointer text-decoration-nones' data-bs-toggle="modal" data-bs-target="#become_seller">Become a Seller</span></p>
                                <p><Link to="#!" className="text-reset">Seller Login</Link></p>
                            </div>

                            <div className="col-6 col-md-3 col-xl-2 mx-auto">
                                <h6 className="fw-bold mb-4">My Account</h6>
                                <p><Link to="/myprofile" className="text-reset">Profile</Link></p>
                                <p><Link to="/login" className="text-reset">Login</Link></p>
                                <p><Link to="/cart" className="text-reset">My Cart</Link></p>
                                <p><Link to="/myorders" className="text-reset">My Orders</Link></p>
                            </div>

                            <div className="col-6 col-md-3  col-xl-2 mx-auto">
                                <h6 className="fw-bold mb-4">Shop</h6>
                                <p><span className='cursor-pointer text-decoration-none' data-bs-toggle="modal" data-bs-target="#refund_and_returns">Refunds & returns</span></p>
                                <p><span className='cursor-pointer text-decoration-none' data-bs-toggle="modal" data-bs-target="#ReadMoreModal">Terms & Conditions</span></p>
                                <p><span className='cursor-pointer text-decoration-none' data-bs-toggle="modal" data-bs-target="#disclaimer">Desclaimer</span></p>
                                <p><span className='cursor-pointer text-decoration-none' data-bs-toggle="modal" data-bs-target="#privacy_and_policy">Privacy And Policy</span></p>
                                {/* <Link to="https://play.google.com/store/apps/details?id=com.evyapri.ecommerceapp" target="_blank">
                                    <img src={require("../assets/images/google-play.png")} alt="evyapari ios app" />
                                </Link> */}
                            </div>

                            <div className="col-6 col-md-3 col-xl-3 mx-auto mb-md-0">
                                <h6 className="fw-bold mb-4">Contact</h6>
                                <p><i className="fas fa-home me-3 text-secondary"></i>VPO NADAUN, Distt. Hamirpur (HP)</p>
                                <p>
                                    <i className="fas fa-envelope me-3 text-secondary"></i>
                                    evyapari@hotmail.com
                                </p>
                                <p><i className="fas fa-phone me-3 text-secondary"></i>9817475121</p>
                                <p><i className="fas fa-print me-3 text-secondary"></i>9817475121</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* refund and returns */}
                <div className="modal modal-lg fade" id="refund_and_returns" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header bg-pink text-white">
                                <h5 className="modal-title" id="exampleModalLabel">Refunds and returns</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <p style={{ textAlign: 'left' }}>
                                    We are not required to provide a refund or replacement if you change your mind. But you can
                                    choose a refund or exchange if an item has a major problem.<br /><br />
                                    <b>Cancellation Policy:</b><br /><br />
                                    In case we receive a cancellation e-mail and by that time the order has already been
                                    "processed" by the seller, the order cannot be cancelled. <b>V4 MASTERS e-vyapari</b>
                                    has
                                    the complete right to decide whether an order can be cancelled or not. The customer
                                    agrees not to dispute the decision made by <b>V4 MASTERS e-vyapari</b> and accept <b>V4
                                        MASTERS e-vyapari</b>’s decision regarding cancellation.<br />
                                    V4 MASTERS e-vyapari reserves the right to refuse or cancel any order placed for a
                                    product that is listed at an incorrect price or for any other reason. This shall be
                                    regardless of whether the order has been confirmed and/or payment been received.
                                    The 100% payment shall be refunded and the User shall be informed of the same.<br /><br />
                                    <b>Replacement/Exchange Policy:</b><br /><br />
                                    All products except books can be exchanged within 2 days of receiving the order,
                                    except in the following cases:<br />
                                    1. Items that have been used or opened<br />
                                    2. An item not returned in the condition it was received.<br />
                                    3. Any item received by <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> in a damaged condition.<br /><br />
                                    We take stringent measures to ensure that the items delivered to you are in perfect
                                    condition. However, there is a remote possibility that:<br />
                                    • The item may be damaged during transit<br />
                                    • Or there might be a manufacturing defect<br />
                                    • Or that a wrong item is delivered to you<br />
                                    In only such cases as given above, we will replace the item at no extra cost, provided
                                    that the request for replacement / return of item is made within the stipulated time-frame
                                    as given below. No returns or replacements will be allowed after the order is processed,
                                    except for the reasons given above.<br />
                                    To get a replacement, email us your Order Number and the Reason for returning. Only
                                    after receiving our confirmation for the return, you are requested to send the item back
                                    to us. We will then arrange for the replacement of the item through our logistics partner.
                                    We shall not be responsible for any items sent to us for return / replacement without our
                                    confirmation. The email address for all such communication is
                                    support@evyapari.com<br /><br />
                                    <b>Return Policy:</b> Any item ordered from <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> cannot be
                                    returned. It
                                    is only possible to exchange the item as per the above Exchange/Replacement Policy.
                                    Books cannot be exchanged.<br /><br />
                                    <b>Refund Policy of <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><u>www.evyapari.com</u></a></b>:
                                    Cases of unavailability of products or a service problem:<br />
                                    • If the item sent for replacement is not available, we will try to give you the best
                                    possible alternatives, but at the end, choice of the customer will be final.<br />
                                    • If the order is confirmed by us and the product is not shipped on time to the customer,
                                    then we give refunds to the customer, if the customer demands.<br />
                                    • 100% refund will be given, if product is not available or we are not able to ship the
                                    products.<br />
                                    • For courier products, if the delivery address is out-side the service area, then 100%
                                    refund will be given to the customer<br />
                                    • Refund to be processed within 7-10 Days after the returned products have been
                                    received via Bank Transfer or Cheque. <br /><br />
                                    <b>Shipping Merchandise to V4 MASTERS e-vyapari for Replacement/Exchange: </b><br /><br />
                                    V4 MASTERS e-vyapari has tie-ups with logistic partners who work specifically on
                                    reverse logistics. A customer can contact us on <b style={{ color: '#1623A9' }}><u>support@evyapari.com</u></b> and our
                                    customer support team will initiate a reverse pick-up for you. Alternatively, a customer
                                    can also ship us the merchandise via courier or insured parcel post for tracking
                                    purposes. <a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank"><b><u>www.evyapari.com</u></b></a> does not take any responsibility for
                                    reimbursement or compensation in the event that any returned packages are lost,
                                    stolen, or mishandled. We do not refund shipping or insurance costs.<br />
                                    Products for exchange or replacement must be sent to the seller’s address mentioned in
                                    the invoice. Shipping information and tracking number must be sent to
                                    <b style={{ color: '#1623A9' }}><u>support@evyapari.com</u></b> by e mail immediately after
                                    shipping.<br /><br />
                                    An email notification will be sent once the return has been processed.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Disclaimer */}
                <div className="modal modal-lg fade" id="disclaimer" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header bg-pink text-white">
                                <h5 className="modal-title" id="exampleModalLabel">Disclaimer for V4 MASTERS e-vyapari</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <p style={{ textAlign: 'left' }}>
                                    If you require any more information or have any questions about our site's disclaimer,
                                    please feel free to contact us by email at
                                    V4MASTERS@HOTMAIL.COM<br /><br />
                                    <b>Disclaimers for E VYAPARI</b><br /><br />
                                    All the information on this website - WWW.EVYAPARI.COM - is published in good faith and for
                                    general information
                                    purpose only. E VYAPARI does not make any warranties about the completeness, reliability and
                                    accuracy of this information. Any
                                    action you take upon the information you find on this website (E VYAPARI), is strictly at your
                                    own risk. E VYAPARI will not be liable
                                    for any losses and/or damages in connection with the use of our website. Our Disclaimer was
                                    generated with the help of
                                    the Disclaimer Generator and the Terms and Conditions Template.<br /><br />
                                    From our website, you can visit other websites by following hyperlinks to such external sites.
                                    While we strive to provide only quality
                                    links to useful and ethical websites, we have no control over the content and nature of these
                                    sites. These links to other websites do
                                    not imply a recommendation for all the content found on these sites. Site owners and content may
                                    change without notice and may
                                    occur before we have the opportunity to remove a link which may have gone 'bad'.<br /><br />
                                    Please be also aware that when you leave our website, other sites may have different privacy
                                    policies and terms which are beyond
                                    our control. Please be sure to check the Privacy Policies of these sites as well as their
                                    "Terms of Service" before engaging in any
                                    business or uploading any information.<br /><br />
                                    <b>Consent</b><br /><br />
                                    By using our website, you hereby consent to our disclaimer and agree to its terms.<br /><br />
                                    <b>Update</b><br /><br />
                                    Should we update, amend or make any changes to this document, those changes will be prominently
                                    posted here.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* privacy and policy */}
                <div className="modal modal-lg fade" id="privacy_and_policy" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header bg-pink text-white">
                                <h5 className="modal-title" id="exampleModalLabel">Welcome to our Privacy Policy</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <p style={{ textAlign: 'left' }}>
                                    -- Your privacy is critically important to us.<br />
                                    V4 MASTERS e-vyapari is located at:<br />
                                    <b>V4 MASTERS e-vyapari&lt;<br />
                                        SHOP NO. 148, BHAGAT COMPLEX<br />
                                        VPO NADAUN HAMIRPUR 177033<br />
                                        HIMACHAL PRADESH, INDIA<br />
                                        9817475121</b><br /><br />
                                    It is V4 MASTERS e-vyapari's policy to respect your privacy regarding any information we may
                                    collect
                                    while operating our website. This Privacy Policy applies to evyapari.com (hereinafter,
                                    "us", "we",
                                    or “www.evyapari.com "). We respect your privacy and are committed to protecting personally
                                    identifiable information you may provide us through the Website. We have adopted this privacy
                                    policy
                                    ("Privacy Policy") to explain what information may be collected on our Website, how we
                                    use this
                                    information, and under what circumstances we may disclose the information to third parties. This
                                    Privacy Policy applies only to information we collect through the Website and does not apply to
                                    our
                                    collection of information from other sources.<br /><br />
                                    This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the
                                    general
                                    rules and policies governing your use of our Website. Depending on your activities when visiting
                                    our
                                    Website, you may be required to agree to additional terms and conditions.<br /><br /><br />
                                    <b>- Website Visitors</b><br /><br />
                                    Like most website operators, V4 MASTERS e-vyapari collects non-personally-identifying
                                    information of
                                    the sort that web browsers and servers typically make available, such as the browser type,
                                    language
                                    preference, referring site, and the date and time of each visitor request. V4 MASTERS
                                    e-vyapari's
                                    purpose in collecting non-personally identifying information is to better understand how V4
                                    MASTERS e-
                                    vyapari's visitors use its website. From time to time, V4 MASTERS e-vyapari may release
                                    non-personally-
                                    identifying information in the aggregate, e.g., by publishing a report on trends in the usage of
                                    its
                                    website.<br /><br />
                                    V4 MASTERS e-vyapari also collects potentially personally-identifying information like Internet
                                    Protocol
                                    (IP) addresses for logged in users and for users leaving comments on http://evyapari.com blog
                                    posts. V4 MASTERS e-vyapari only discloses logged in user and commenter IP addresses under the
                                    same
                                    circumstances that it uses and discloses personally-identifying information as described
                                    below.<br /><br /><br /><br />
                                    <b>- Gathering of Personally-Identifying Information</b><br /><br />
                                    Certain visitors to V4 MASTERS e-vyapari's websites choose to interact with V4 MASTERS
                                    e-vyapari in
                                    ways that require V4 MASTERS e-vyapari to gather personally-identifying information. The amount
                                    and
                                    type of information that V4 MASTERS e-vyapari gathers depends on the nature of the interaction.
                                    For
                                    example, we ask visitors who sign up for a blog at <u>http://evyapari.com</u>to provide a
                                    username
                                    and email address.<br /><br />
                                    <b>- Security</b><br /><br />
                                    The security of your Personal Information is important to us, but remembers that no method of
                                    transmission over the Internet, or method of electronic storage is 100% secure. While we strive
                                    to use
                                    commercially acceptable means to protect your Personal Information, we cannot guarantee its
                                    absolute
                                    security.<br />
                                    <b>- Advertisements</b><br /><br />
                                    Ads appearing on our website may be delivered to users by advertising partners, who may set
                                    cookies.
                                    These cookies allow the ad server to recognize your computer each time they send you an online
                                    advertisement to compile information about you or others who use your computer. This information
                                    allows ad networks to, among other things, deliver targeted advertisements that they believe
                                    will be of
                                    most interest to you. This Privacy Policy covers the use of cookies by V4 MASTERS e-vyapari and
                                    does
                                    not cover the use of cookies by any advertisers.<br /><br /><br /><br />
                                    <b>- Links to External Sites</b><br /><br />
                                    Our Service may contain links to external sites that are not operated by us. If you click on a
                                    third party
                                    link, you will be directed to that third party's site. We strongly advise you to review the
                                    Privacy Policy
                                    and terms and conditions of every site you visit.<br /><br />
                                    We have no control over, and assume no responsibility for the content, privacy policies or
                                    practices of
                                    any third party sites, products or services.<br /><br />
                                    <b>- evyapari.com uses Google Ad Words for remarketing</b><br /><br />
                                    evyapari.com uses the remarketing services to advertise on third party websites (including
                                    Google) to previous visitors to our site. It could mean that we advertise to previous visitors
                                    who haven't
                                    completed a task on our site, for example using the contact form to make an enquiry. This could
                                    be in
                                    the form of an advertisement on the Google search results page, or a site in the Google Display
                                    Network. Third-party vendors, including Google, use cookies to serve ads based on someone's
                                    past
                                    visits. Of course, any data collected will be used in accordance with our own privacy policy and
                                    Google's
                                    privacy policy.<br /><br />
                                    You can set preferences for how Google advertises to you using the Google Ad Preferences page,
                                    and if
                                    you want to you can opt out of interest-based advertising entirely by cookie settings or
                                    permanently
                                    using a browser plug-in.<br /><br /><br />
                                    <b>-Protection of Certain Personally-Identifying Information</b><br /><br />
                                    V4 MASTERS e-vyapari discloses potentially personally-identifying and personally-identifying
                                    information
                                    only to those of its employees, contractors and affiliated organizations that<br /><br />
                                    <b>(i) </b> need to know that information in order to process it on V4 MASTERS e-vyapari's
                                    behalf or to
                                    provide services available at V4 MASTERS e-vyapari's website, and<br /><br />
                                    <b>(ii) </b>That has agreed not to disclose it to others. Some of those employees, contractors
                                    and affiliated
                                    organizations may be located outside of your home country; by using V4 MASTERS e-vyapari's
                                    website, you consent to the transfer of such information to them. V4 MASTERS e-vyapari will
                                    not rent or sell potentially personally-identifying and personally-identifying information to
                                    anyone. Other than to its employees, contractors and affiliated organizations, as described
                                    above, V4 MASTERS e-vyapari discloses potentially personally-identifying and personally-
                                    identifying information only in response to a subpoena, court order or other governmental
                                    request, or when V4 MASTERS e-vyapari believes in good faith that disclosure is reasonably
                                    necessary to protect the property or rights of V4 MASTERS e-vyapari, third parties or the public
                                    at large.<br /><br /><br />
                                    If you are a registered user of http://evyapari.com and have supplied your email address, V4
                                    MASTERS e-vyapari may occasionally send you an email to tell you about new features, solicit
                                    your
                                    feedback, or just keep you up to date with what's going on with V4 MASTERS e-vyapari and our
                                    products. We primarily use our blog to communicate this type of information, so we expect to
                                    keep this
                                    type of email to a minimum. If you send us a request (for example via a support email or via one
                                    of our
                                    feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond
                                    to your
                                    request or to help us support other users. V4 MASTERS e-vyapari takes all measures reasonably
                                    necessary to protect against the unauthorized access, use, alteration or destruction of
                                    potentially
                                    personally-identifying and personally-identifying information.<br /><br /><br />
                                    <b>- Aggregated Statistics</b><br /><br />
                                    V4 MASTERS e-vyapari may collect statistics about the behavior of visitors to its website. V4
                                    MASTERS e-
                                    vyapari may display this information publicly or provide it to others. However, V4 MASTERS
                                    e-vyapari
                                    does not disclose your personally-identifying information.<br /><br />
                                    <b>- Affiliate Disclosure</b><br /><br />
                                    This site uses affiliate links and does earn a commission from certain links. This does not
                                    affect your
                                    purchases or the price you may pay.<br /><br /><br />
                                    <b>-Cookies</b><br /><br />
                                    To enrich and perfect your online experience, V4 MASTERS e-vyapari uses "Cookies",
                                    similar
                                    technologies and services provided by others to display personalized content, appropriate
                                    advertising
                                    and store your preferences on your computer.<br /><br />
                                    A cookie is a string of information that a website stores on a visitor's computer, and that
                                    the visitor's
                                    browser provides to the website each time the visitor returns. V4 MASTERS e-vyapari uses cookies
                                    to
                                    help V4 MASTERS e-vyapari identify and track visitors, their usage of http://evyapari.com, and
                                    their website access preferences. V4 MASTERS e-vyapari visitors who do not wish to have cookies
                                    placed
                                    on their computers should set their browsers to refuse cookies before using V4 MASTERS
                                    e-vyapari's
                                    websites, with the drawback that certain features of V4 MASTERS e-vyapari's websites may not
                                    function
                                    properly without the aid of cookies.<br /><br />
                                    By continuing to navigate our website without changing your cookie settings, you hereby
                                    acknowledge
                                    and agree to V4 MASTERS e-vyapari's use of cookies.<br /><br />
                                    <b>- E-commerce</b><br /><br />
                                    Those who engage in transactions with V4 MASTERS e-vyapari – by purchasing V4 MASTERS
                                    e-vyapari's
                                    services or products, are asked to provide additional information, including as necessary the
                                    personal
                                    and financial information required to process those transactions. In each case, V4 MASTERS
                                    e-vyapari
                                    collects such information only insofar as is necessary or appropriate to fulfill the purpose of
                                    the visitor's
                                    interaction with V4 MASTERS e-vyapari. V4 MASTERS e-vyapari does not disclose
                                    personally-identifying
                                    information other than as described below. And visitors can always refuse to supply personally-
                                    identifying information, with the caveat that it may prevent them from engaging in certain
                                    website-
                                    related activities.<br /><br />
                                    <b>- Business Transfers</b><br /><br />
                                    If V4 MASTERS e-vyapari, or substantially all of its assets, were acquired, or in the unlikely
                                    event that V4
                                    MASTERS e-vyapari goes out of business or enters bankruptcy, user information would be one of
                                    the
                                    assets that is transferred or acquired by a third party. You acknowledge that such transfers may
                                    occur,
                                    and that any acquirer of V4 MASTERS e-vyapari may continue to use your personal information as
                                    set
                                    forth in this policy.<br /><br />
                                    <b>Privacy Policy Changes</b><br /><br />
                                    Although most changes are likely to be minor, V4 MASTERS e-vyapari may change its Privacy Policy
                                    from
                                    time to time, and in V4 MASTERS e-vyapari's sole discretion. V4 MASTERS e-vyapari encourages
                                    visitors
                                    to frequently check this page for any changes to its Privacy Policy. Your continued use of this
                                    site after
                                    any change in this Privacy Policy will constitute your acceptance of such change.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* become a seller */}
                <div className="modal modal-lg fade" id="become_seller" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header bg-pink text-white">
                                <h5 className="modal-title" id="exampleModalLabel">VENDOR AGREEMENT FOR PROVISION OF E-COMMERCE SERVICES</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <p style={{ textAlign: 'left' }}>
                                    This seller agreement is an electronic record and does not require any physical, electronic or
                                    digital
                                    signature. This agreement is a legally binding document between you and <b>V4 MASTERS
                                        (e-vyapari)</b>. The
                                    terms of this agreement will be effective upon your acceptance of the same and will govern the
                                    relationship between you and <b>V4 MASTERS (e-vyapari)</b>, including with respect to the
                                    listing, advertising,
                                    exhibiting, making available, marketing, sale and/or delivery of any products through the
                                    website. Here
                                    you (refer for vendor) and Firm (refer for <b>V4 MASTERS e-vyapari)</b> the registered firm
                                    <b>V4 MASTERS (e-
                                        vyapari</b>) having its address/registered office at <b>SHOP NO. 148, BHAGAT COMPLEX, MAIN
                                            BAZAR
                                            NADAUN DISTT. HAMIRPUR H.P. 177033</b>, with the <b>GST NO. 02IKOPS0284N1ZH </b>(refer as
                                    <b>V4 MASTERS e-
                                        vyapari</b>) AND <b>TCS REGISTRATION NO. 02IKOPS0284N1CS </b>duly authorized to enter in to
                                    present
                                    agreement as the First Part. And Duly authorized by partners of the firm to enter in to present
                                    agreement (hereinafter referred to as “the Firm”) of Second Part the parties above referred are
                                    individually known as “the party”/ “the Vendor” / “the Firm” as the case may be and collectively
                                    referred to as “the parties”; AND WHEREAS the Firm is owner of E-Commerce Website by the name of
                                    <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com
                                    </a></u></b> wherein various products of different nature are marketed and sold using
                                    electronic medium more particularly through the e-commerce domain. AND WHEREAS the parties
                                    hereto have after mutual discussions have come to an agreement that the products of the Vendor
                                    will
                                    be marketed by <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b>on their ecommerce store; AND WHEREAS
                                    parties have
                                    agreed to reduce their terms in writing NOW THIS AGREEMENT WITNESSES AS UNDER<br /><br />
                                    <b><i>1. Marketing Tools/Support, Products, Availability of products etc.</i></b><br /><br />
                                    1.1 The Vendor will keep informed at all times the Firm about the availability of the products
                                    in its
                                    inventory along with detailed specifications like size, color, texture etc. as may be required
                                    of the
                                    product. Order once placed on the Firm by the customer shall have to be honored by the Vendor at
                                    all
                                    costs.<br /><br />
                                    <b><i>2. Fee/Commissions etc.</i></b><br /><br />
                                    2.1 The firm as such shall not charge any fee for providing web space/display on website however
                                    for all
                                    such sales that are made/generated using the website <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b> a commission
                                    shall
                                    be paid by the Vendor to the Firm. The firm can be modify any rate of commission in respect of
                                    any
                                    product. The Details of commission rate would be share along with the agreement.
                                    <b><i>3. Order, Handling, Delivery service etc.</i></b><br /><br />
                                    3.1 Orders for the product shall be received using the website <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com
                                    </a></u></b> and shall be
                                    forwarded to the Vendor by the Firm via email/Telephone/Fax.<br /><br />
                                    3.2 The Vendor shall upon receipt of the order from the Firm immediately arrange to deliver the
                                    products (in case the delivery responsibility taken) to the designated address as early as
                                    possible but in
                                    any case the dispatch shall be made within 5(five) days of the receipt of the Order. The Vendor
                                    shall
                                    provide to the Firm the consignment number, details of courier/shipment agency immediately
                                    followed
                                    by proof of delivery.<br /><br />
                                    3.3 The vendor shall ready the product whenever the product purchase notification received (in
                                    case
                                    the firm taken the responsibility of delivering the product).<br /><br />
                                    3.4 The Vendor shall ensure that the products dispatched are of the specifications ordered and
                                    there is
                                    no variation whatsoever. The necessary guarantee/warranty shall be provided by the Vendor to the
                                    customer.<br /><br />
                                    3.5 The Vendor agrees to replace the defective products supplied to the customer at its own cost
                                    and
                                    shall not hold the Firm (V4 MASTERS e-vyapari.) responsible in any manner whatsoever.<br /><br />
                                    3.6 The serviceman shall ready for the service whenever the service request of any customer
                                    received
                                    via www.evyapari.com . Service will be done as per the guidelines share between the V4
                                    MASTERS e-vyapari and the serviceman/service provider.<br /><br />
                                    4. Covenants of Vendor the Vendor hereby covenants with the Firm as under:<br /><br />
                                    4.1 To deliver the product of the ordered specifications/description only including quantity and
                                    quality
                                    prescribed in the Order and there should be no instance of wrong item being delivered and/or
                                    quality
                                    issue and/or issue of Non delivery. Further, the Vendor shall maintain adequate stock/inventory
                                    of the
                                    items at all times. In case the Vendor is running out of supplies or is likely not to fulfill
                                    the Order
                                    received by the Firm, it shall intimate to the Firm at least 24 hours (1 days) in advance so
                                    that notice of
                                    OUT OF STOCK for the product can be placed on the website.<br /><br />
                                    4.2 Not to send any kind of promotion material or any such material, which is, derogatory to
                                    and/or
                                    adverse to the interests financial or otherwise of the Firm, to the customer either along with
                                    the
                                    products supplied or in any manner whatsoever.<br /><br />
                                    4.3 Not to do any act/deal in a thing / products/goods/services which are either
                                    banned/prohibited by
                                    law or violates any of the intellectual property right of any party in respect of such
                                    product.<br /><br />
                                    4.4 The Vendor declares that it has all rights and authorizations in respect of intellectual
                                    property rights
                                    of third parties and is authorized to sale/provide/license such products to the customer. The
                                    copy of
                                    such authorization shall be provided on demand without failure and/or protest.<br /><br />
                                    4.5 To provide to the Firm, for the purpose of the creation/display on website of Firm, the
                                    product
                                    description, images, disclaimer, delivery time lines, price and such other details for the
                                    products to be
                                    displayed and offered for sale.<br /><br />
                                    4.6 To provide full, correct, accurate and true description of the product so as to enable the
                                    customers
                                    to make an informed decision. The Vendor agrees not to provide any such description/information
                                    regarding the product which amounts to misrepresentation to the customer.<br /><br />
                                    4.7 To be solely responsible for the quality, quantity, merchantability, guarantee, warranties
                                    in respect
                                    of the products offered for sale through portal of the Firm.<br /><br />
                                    4.8 At all times have access to the Internet and its email account to check the status of
                                    approved orders
                                    and will ensure prompt deliveries within the time frame mentioned herein before in the
                                    agreement.<br /><br />
                                    4.9 Provide information about the Order Status including Bill Number on a daily basis.<br /><br />
                                    4.10 To raise an invoice as well as receipt of payment in the name of Customer for an amount
                                    equivalent
                                    to the amount displayed as MRP on the online store to the customer and paid by/charged to the
                                    customer.<br /><br />
                                    4.11 Not to offer any Products for Sale on the Online Store, which are prohibited for sale,
                                    dangerous,
                                    against the public policy, banned, unlawful, and illegal/prohibited under the Indian
                                    laws.<br /><br />
                                    4.12 To provide satisfactory proof about the ownership/licenses of all the legal rights in the
                                    Products
                                    that are offered for sale on the Online Store as and when demanded by the Firm.<br /><br />
                                    4.13 To pass on the legal title, rights and ownership in the Products sold to the
                                    Customer.<br /><br />
                                    4.14 To be solely responsible for any dispute that may be raised by the customer relating to the
                                    goods,
                                    merchandise and services provided by the Vendor. No claim of whatsoever nature will be raised on
                                    the
                                    Firm.<br /><br />
                                    4.15 The Vendor shall at all time during the pendency of this agreement endeavor to protect and
                                    promote the interests of the Firm and ensure that third parties rights including intellectual
                                    property
                                    rights are not infringed.<br /><br />
                                    4.16 The Vendor shall at all times be responsible for compliance of all applicable laws and
                                    regulations
                                    including but not limited to Intellectual Property Rights, Local Sales Tax, Central Sales Tax,
                                    Service tax,
                                    Value added tax, Standards of Weights &amp; Measures legislation, Sale of Goods Act, Excise and
                                    Import
                                    duties, Drugs and Cosmetics Act, Drugs and Remedial Magic Act, Code of Advertising Ethics,
                                    etc.<br /><br />
                                    4.17 To provide to the Firm copies of any document required by the Firm for the purposes of
                                    performance of its obligations under this agreement within 48 hours of getting a written notice
                                    from the
                                    Firm.<br /><br />
                                    4.18 To seek advance written approval from the Firm, prior to release of any
                                    promotion/advertisement
                                    material, in so far as the same relates to services offered pursuant to the terms of this
                                    Agreement.<br /><br />
                                    <b>5. Warranties, Representations and Undertakings of the Vendor the Vendor warrants and
                                        represents
                                        that</b><br /><br />
                                    5.1 The signatory to the present agreement is having the right and full authority to enter into
                                    this
                                    Agreement with the Firm and the agreement so executed is binding in nature.<br /><br />
                                    5.2 All obligations narrated under this Agreement are legal, valid, binding and enforceable in
                                    law against
                                    Vendor.<br /><br />
                                    5.3 There are no proceedings pending against the Vendor, which may have a material adverse
                                    effect on
                                    its ability to perform and meet the obligations under this Agreement.<br /><br />
                                    5.4 That it is an authorized business establishment and hold all the requisite permissions,
                                    authorities,
                                    approvals and sanctions to conduct its business and to enter into present agreement with the
                                    Firm.<br /><br />
                                    5.5 It shall, at all times ensure compliance with all the requirements applicable to its
                                    business and for
                                    the purposes of this agreement including but not limited to Intellectual Property Rights, Sales
                                    Tax,
                                    Central Sales Tax, Service tax, Standards of Weights &amp; Measures legislation, Sale of Goods
                                    Act, Value
                                    added tax, Excise and Import duties, etc. It further declares and confirms that it has paid and
                                    shall
                                    continue to discharge all its obligations towards statutory authorities.<br /><br />
                                    5.6 That it has adequate rights under relevant laws including but not limited to various
                                    Intellectual
                                    Property Legislation(s) to enter into this Agreement with the Firm and perform the obligations
                                    contained
                                    herein and that it has not violated/ infringed any intellectual property rights of any third
                                    party.<br /><br />
                                    5.7 It shall maintain details of all transaction and mark as complete / incomplete as the case
                                    may be and
                                    shall provide the same to the Firm upon demand.<br /><br />
                                    <b><i>6 Rights of Firm:</i></b><br /><br />
                                    6.1 Vendor agrees and acknowledges that the Firm, at all times during the continuance of this
                                    Agreement, shall have the right to remove/block/delete any text, graphic, image(s) uploaded on
                                    the
                                    online store by the Vendor without any prior intimation to Vendor in the event the said text,
                                    image,
                                    graphic is found to be in violation of law, breach of any of the terms of this Agreement, terms
                                    and
                                    conditions of www.evyapari.com Shopping Website. In such an event, the Firm reserves the right to
                                    forthwith remove/close the online store of the Vendor without any prior intimation or liability
                                    to the
                                    Vendor.<br /><br />
                                    6.2 Appropriate disclaimers and terms of use on grab This Shopping portal shall be placed by the
                                    Firm.
                                    6.3 At any time if the Firm believes that the services are being utilized by the Vendor or its
                                    Customer in
                                    contravention of the terms and provisions of this Agreement, Terms and conditions of use of grab
                                    This
                                    Shopping, the Firm shall have the right either at its sole discretion or upon the receipt of a
                                    request from
                                    the legal / statutory authorities or a court order to discontinue/terminate the said service(s)
                                    to
                                    Customer or the End user as the case may be, forthwith remove/block/close the online store of
                                    the
                                    Vendor and furnish such details about the Vendor and/or its customers upon a request received
                                    from
                                    the Legal/ Statutory Authorities or under a Court order.<br /><br />
                                    <b><i>7 Indemnity</i></b><br /><br />
                                    7.1 The Vendor indemnifies and shall hold indemnified the Firm, its partners, officers,
                                    employees,
                                    representatives, agents from and against all losses, damages, claims, suits, legal proceedings
                                    and
                                    otherwise howsoever arising from or in connection with any claim including but not limited to
                                    claim for
                                    any infringement of any intellectual property rights or any other rights of any third party or
                                    of law,
                                    concerning quality, quantity and any claim in relation to the Vendor’s product, the breach of
                                    any of the
                                    Vendor’s warranties, representations or undertakings or in relation to the non-fulfillment of
                                    any of its
                                    obligations under this Agreement or arising out of the Vendor infringing any applicable laws,
                                    regulations
                                    including but not limited to Intellectual Property Rights, gst , Local Sales Tax, Central Sales
                                    Tax, Service
                                    tax, Value Added tax, The Standards of Weights &amp; Measures legislation, Excise and Import
                                    duties, etc .
                                    For the purpose of this clause reference to the Firm shall also include the Mobile Operators and
                                    such
                                    other agencies through whom the Firm shall make the Online Store available to the
                                    Customers.<br /><br />
                                    7.2 This article shall survive the termination or expiration of this Agreement.<br /><br />
                                    <b><i>8 Limitation of Liability</i></b><br /><br />
                                    8.1 The Firm on the basis of representation by the Vendor is marketing the products of the
                                    Vendor on
                                    the shopping portal <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com </a></u></b> to enable Vendor to offer its products
                                    for sale through
                                    the said online shopping portal. This representation is the essence of the Contract. It is
                                    expressly agreed
                                    by the vendor that the Firm shall under no circumstances be liable or responsible for any loss,
                                    injury or
                                    damage to the Vendor, customer or any other party whomsoever, arising on account of any
                                    transaction
                                    under this Agreement or as a result of the Products being in any way damaged, defective, in
                                    unfit
                                    condition, infringing/ violating any laws/ regulations/ intellectual property rights of any
                                    third party. The
                                    Vendor agrees and acknowledges that it shall be solely liable for any claims, damages,
                                    allegation arising
                                    out of the Products offered for sale through online shopping portal <b><u><a href="https://evyapari.com" style={{ color: '#1623A9' }} target="_blank">www.evyapari.com
                                    </a></u></b>
                                    (including but not limited to quality, quantity, price, merchantability, use for a particular
                                    purpose, or any
                                    other related claim) and shall hold the Firm harmless and indemnified against all such claims
                                    and
                                    damages. Further the Firm shall not be liable for any claims, damages arising out of any
                                    negligence,
                                    misconduct or misrepresentation by the Vendor or any of its representatives.<br /><br />
                                    8.2 The Firm under no circumstances will be liable to the Vendor for loss and/or anticipated
                                    loss of
                                    profits, or for any direct or indirect, incidental, consequential, special or exemplary damages
                                    arising
                                    from the subject matter of this Agreement, regardless of the type of claim and even if the
                                    Vendor has
                                    been advised of the possibility of such damages, such as, but not limited to loss of revenue or
                                    anticipated profits or loss business, unless such loss or damages is proven by the Vendor to
                                    have been
                                    deliberately caused by the Firm.<br /><br />
                                    <b><i>9 Termination and effects of Termination</i></b><br /><br />
                                    9.1 This Agreement may be terminated by the Firm forthwith in the event<br /><br />
                                    9.1.1 Vendor commits a material breach of any representation, obligations, covenant, warranty or
                                    term
                                    of this agreement and the same is not cured within 30 days after written notice given by the
                                    Firm.<br /><br />
                                    9.1.2 If a Petition for insolvency is filed against the Vendor.<br /><br />
                                    9.1.3 If the Vendor is in infringement of the third party rights including intellectual property
                                    rights.<br /><br />
                                    <b><i>9.2 Effect of Termination:</i></b><br /><br />
                                    9.2.1 In the event of termination of this Agreement, the Firm shall remove the Links and shall
                                    discontinue display of the Products on online shopping portal www.grabthis.in with immediate
                                    effect.<br /><br />
                                    9.2.2 Firm shall not be liable for any loss or damages (direct, indirect or inconsequential)
                                    incurred by the
                                    Vendor by virtue of termination of this agreement.<br /><br />
                                    9.2.3 During the period under notice both the parties shall be bound to perform its obligations
                                    incurred
                                    under this agreement and this sub-clause shall survive the termination of this
                                    agreement.<br /><br />
                                    <b><i>10. Jurisdiction, governing law and ex-parte Orders</i></b><br /><br />
                                    10.1 This agreement is subject to exclusive jurisdiction of competent Courts of law at NADAUN
                                    only.<br /><br />
                                    10.2 The laws of Republic of India, as are in force, shall be applicable to present
                                    agreement.<br /><br />
                                    10.3 The Firm is entitled to obtain ex-parte ad- interim injunction orders restraining the
                                    Vendor to
                                    prevent any loss/anticipated loss either in material terms or in terms of intellectual property
                                    or causing
                                    damage/loss/harm to reputation/goodwill of the Firm by the Vendor, its representatives,
                                    associates or
                                    assigns.<br /><br />
                                    <b><i>11. Notices</i></b><br /><br />
                                    11.1 All notices and other communication under this Agreement shall be in writing, in
                                    English/Hindi
                                    language and shall be caused to be delivered by hand or sent by telephone, fax, email or courier
                                    in each
                                    case to the addresses as set out at the beginning of this Agreement.<br /><br />
                                    <b><i>12. Intellectual Property Rights</i></b><br /><br />
                                    12.1 The Vendor expressly authorizes the Firm to use its trade marks/copy rights/ designs /logos
                                    and
                                    other intellectual property owned and/or licensed by it for the purpose of reproduction on the
                                    website
                                    and at such other places as the Firm may deem necessary. It is expressly agreed and clarified
                                    that,
                                    except as specified agreed in this Agreement, each Party shall retain all right, title and
                                    interest in their
                                    respective trademarks and logos and that nothing contained in this Agreement, nor the use of the
                                    trademark / logos on the publicity, advertising, promotional or other material in relation to
                                    the services
                                    shall be construed as giving to any Party any right, title or interest of any nature whatsoever
                                    to any of
                                    the other Party’s trademarks and / or logos.<br /><br />
                                    <b><i>13 Entire Agreement</i></b><br /><br />
                                    13.1 This Agreement embodies the entire agreement and understanding of the Parties and
                                    supersedes
                                    any and all other prior and contemporaneous agreements, correspondence, arrangements and
                                    understandings (whether written or oral) between the Parties with respect to its subject
                                    matter.<br /><br />
                                    <b><i>14 Assignment</i></b><br /><br />
                                    14.1 Neither this Agreement nor any part of it is assignable, transferable, sub-licensable,
                                    subcontract
                                    able or conveyable by Vendor, either by operation of law or otherwise, without the express,
                                    prior,
                                    written consent of the Firm signed by an authorized representative of such Party. The Firm is at
                                    liberty
                                    to refuse such consent.<br /><br />
                                    <b><i>15 Confidentiality</i></b><br /><br />
                                    15.1 The contents of the agreement and any information passed on by the Firm to the Vendor is
                                    highly
                                    confidential in nature and the Vendor agrees and undertakes to maintain the confidentiality of
                                    the
                                    information and user/customer data disclosed, generated or made available to Vendor under this
                                    Agreement. The said information shall not be used by the Vendor or its agents, servants,
                                    representatives
                                    or any person acting through or claiming through the Vendor for any purpose other than for the
                                    performance of its obligations under this Agreement. The Vendor agrees that the unauthorized
                                    disclosure or use of such information would cause irreparable harm and significant injury, the
                                    degree of
                                    which may be difficult to ascertain. Accordingly, Vendor agrees that the Firm shall have the
                                    right to
                                    obtain an immediate injunction from any court of law ensuing breach of this Agreement and/or
                                    disclosure of the Confidential Information. The Firm shall also have the right to pursue any
                                    other rights
                                    or remedies available at law or equity for such a breach.<br /><br />
                                    <b><i>16 Relationships of Parties</i></b><br /><br />
                                    16.1 Nothing in this Agreement will be construed as creating a relationship of partnership,
                                    joint venture,
                                    agency or employment between the Parties. The Firm shall not be responsible for the acts or
                                    omissions
                                    of the Vendor and Vendor shall not represent the Firm, neither has any power or authority to
                                    speak for,
                                    represent, bind or assume any obligation on behalf of the Firm.<br /><br />
                                    <b><i>17 Waivers</i></b><br /><br />
                                    17.1 No waiver of any breach of any provision of this Agreement constitutes a waiver of any
                                    prior,
                                    concurrent or subsequent breach of the same or any other provisions, and will not be effective
                                    unless
                                    made in writing and signed by an authorized representative of the waiving Party.
                                    <b><i>18 Force Majeure</i></b><br /><br />
                                    18.1 Neither Party shall be responsible or liable for any delay or failure to perform its
                                    obligations (other
                                    than an obligation to make payment) under this Agreement due to unforeseen circumstances or any
                                    event which is beyond that Party's reasonable control and without its fault or negligence,
                                    but not limited
                                    to, acts of God, war, riots, embargoes, strikes, lockouts, acts of any Government authority,
                                    delays in
                                    obtaining licenses or rejection of applications under the Statutes, failure of telephone
                                    connections or
                                    power failure, fire or floods.<br /><br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <TermsConditions />
                <Copyright />
            </footer>

        </>
    )
}

export default Footer