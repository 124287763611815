import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const OrderItem = (props) => {
    const { srno, invoice_number, order_total, order_date, grand_total, order_status, tracking_status } = props;
    const navigate = useNavigate();
    return (
        <>
            <div className="border p-4 mb-3 bg-white b-shadow">
                <div className="row">
                    <div className="col-sm-1">
                        <div className="my-orders-img-contain mx-auto">
                            <h5>{srno}. </h5>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <p className="mt-3 mt-sm-0">{invoice_number}</p>
                        {
                            order_status === 1 ?
                                // <button onClick={() => navigate(`/placeorder/${invoice_number}`)} className="btn btn-pink px-4">Place Order Now</button>
                                <button onClick={() => navigate(`/myschool`)} className="btn btn-pink px-4">Retry Now</button>
                                :
                                <button onClick={() => navigate('/orderdetails', { state: { invoice_number: invoice_number } })} className="btn btn-outline-pink px-4">Order Details</button>
                        }


                    </div>
                    <div className="col-sm-3">
                        <div className='mt-4 mt-sm-0 text-center'>
                            <p className="text-sm-center">₹{grand_total}</p>
                            {/* <div className="">
                                {
                                    (() => {
                                        switch (order_status) {
                                            case 1:
                                                return (<div>
                                                    <div className="status-dot bg-warning d-inline-block me-1"></div>
                                                    <span className="s-font2 fw-bold">Payment Failed</span>
                                                    <p className="s-font1">Your order is Pending For Payment</p>
                                                </div>);
                                            case 2:
                                                return (<div>
                                                    <div className="status-dot bg-success d-inline-block me-1"></div>
                                                    <span className="s-font2 fw-bold">Placed</span>
                                                    <p className="s-font1">Your order is Placed</p>
                                                </div>);
                                            case 3:
                                                return (<div>
                                                    <div className="status-dot bg-info d-inline-block me-1"></div>
                                                    <span className="s-font2 fw-bold">In Process</span>
                                                    <p className="s-font1">Your order is in Process</p>
                                                </div>);
                                            case 4:
                                                return (<div>
                                                    <div className="status-dot bg-success d-inline-block me-1"></div>
                                                    <span className="s-font2 fw-bold">Delivered</span>
                                                    <p className="s-font1">Your order is Delivered</p>
                                                </div>);
                                            case 5:
                                                return (<div>
                                                    <div className="status-dot bg-danger d-inline-block me-1"></div>
                                                    <span className="s-font2 fw-bold">Cancelled</span>
                                                    <p className="s-font1">Your order is Cancelled</p>
                                                </div>);
                                            default:
                                                return null;
                                        }
                                    })()
                                }
                            </div> */}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div dangerouslySetInnerHTML={{ __html: tracking_status }} />
                    </div>
                    {/* <div className="col-sm-2">
                        <div>Order Date <br /> {order_date}</div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default OrderItem