import React from 'react'
import Data from '../data/Data.json'
import ProfileCards from '../Components/ProfileCards'
import Header from '../Components/Header'

const MyProfile = () => {
   const { profiledata } = Data;
   return (
      <>
         <Header />
         <main id='main'>
            <section id="profile-bnr">
               <h2 className="text-center pt-4">Your Account</h2>
            </section>

            <section id="myprofile" className="py-4">
               <div className="container">
                  <div className="row g-4">
                     {
                        profiledata.map((card, i) => {
                           return (
                              <ProfileCards
                                 key={i}
                                 imgsrc={card.image}
                                 title={card.title}
                                 text={card.text}
                                 link={card.link}
                              />
                           )
                        })
                     }
                  </div>
               </div>
            </section>
         </main>
      </>
   )
}

export default MyProfile