import React from 'react'

const SchoolCard = ({ school_name, school_image, school_banner }) => {
    return (
        <>
            <div className="item">
                <div className="p-2">
                    <div className="school-img-contain">
                        {
                            school_banner !== null ?
                                <img src={school_image} className="object-fit-fill" width='100%' height='100%' alt="school_banner" />
                                :
                                <img src={require('../assets/images/school_banner_2.jpeg')} className="object-fit-fill" width='100%' height='100%' alt="school_banner" />
                        }
                    </div>
                    <h6 className="text-center text-white mt-3">{school_name}</h6>
                </div>
            </div>
        </>
    )
}

export default SchoolCard