
import React, { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function Jspdf2() {
    const contentRef = useRef(null);

    const handleDownload = () => {
        const contentElement = contentRef.current;

        // Capture the content as an image using html2canvas
        html2canvas(contentElement)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');

                // Create PDF document
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

                // Add image to PDF
                pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

                // Save the PDF
                pdf.save('download.pdf');
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };


    return (
        <>
            <div className="container py-3" ref={contentRef}>
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="d-flex justify-content-between shadow p-3 mb-3 bg-white rounded">
                            <div>
                                <p className='fs-10'>
                                    <span className="fw-bold">Shipping Address </span> <br />
                                    Sachin Kumar Home<br />
                                    9817475121<br />
                                    Nadaun, Near Jain Dhramshala<br />
                                    Pincode: 177033, Post Office: Nadaun<br />
                                    Nadaun, Hamirpur, Himachal Pradesh
                                </p>
                            </div>
                            <div>
                                <span className="fw-bold ">Invoice Number: </span>
                                20240301094413-LOXBNXKAC1293-F1I6LUL1889<br />
                                <span className="fw-bold ">Order Date: </span>
                                2024-03-01 09:44:13<br />
                                <span className="fw-bold ">Order Status: </span>
                                Placed<br />
                                <span className="fw-bold ">Transaction Id: </span>
                                20240301011090000970342401677731445<br />
                            </div>
                        </div>

                        <table className="table table-bordered text-center mt-3">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Product</th>
                                    <th>Size/Class</th>
                                    <th>Category</th>
                                    <th>Unit Price</th>
                                    <th>Total </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Conversation Book-01</td>
                                    <td>1st</td>
                                    <td> <del>₹75.00</del> ₹75</td>
                                    <td>1</td>
                                    <td>75</td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="col-12">
                            <div className="d-flex justify-content-end">
                                <div className="border col-4 p-2">
                                    <div>
                                        <p><span className="fw-bold pe-5">Subtotal (1 items, qty 1):</span> <span className="float-end">₹75.00</span></p>
                                        <p><span className="fw-bold pe-5">Delivery Charges(Total):</span> <span className="float-end">+ ₹0.00</span></p>
                                        <p><span className="fw-bold pe-5">Total Discount:</span> <span className="float-end">- ₹0.00</span></p>
                                        <p><span className="fw-bold border-top pe-5">Grand Total:</span> <span className="float-end border-top">₹75.00</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-3 mb-3 bg-white rounded">
                            <div className="text-center mt-3">
                                <h4 className="link-primary">www.evyapari.com</h4>
                            </div>
                            <p className="font-weight-bold">Registered Name & Address <br />
                                <span className="font-weight-bold">V4 Masters, e-vyapari,</span> Bhagat Complex Main Bazar Nadaun, Distt. Hamirpur 177033 (H.P), GSTN: 02IKOPS0284N1ZH, Email: evyapari7@gmail.com<br />
                                Goods once sold are not returnable. All guarantees & warranty subject to seller.
                            </p>
                        </div>
                        {/* <button className="btn btn-primary mr-2 mt-3" onClick={handleDownload}>Download as PDF</button> */}
                    </div>
                </div>


            </div>

            <button className="btn btn-primary mr-2 mt-3 ms-5 " onClick={handleDownload}>Download as PDF</button>

        </>
    );
}

export default Jspdf2;





