import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { AddressValidation } from './FormValidation';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../Components/Header';
import { useGlobalContext } from '../contexts/GlobalContext';
import Loading from '../Components/Loading';
import Copyright from '../Components/Copyright';

const EditAddress = () => {
    const { isLoading, setIsLoading, api_url, handleStateChange, stateData, districts, navigate } = useGlobalContext();
    const [addressErrors, setaddressErrors] = useState({});
    const params = useParams();


    const [addressData, setAddressData] = useState({
        "user_id": sessionStorage.getItem('email'),
        "address_id": params.address_id,
        "address_type": 1,
        "name": "",
        "phone_no": "",
        "alternate_phone": "",
        "village": "",
        "city": "",
        "district": "",
        "post_office": "",
        "pincode": "",
        "address": "",
        "state": ""
    })

    const handleInputs = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        setAddressData(prev => ({ ...prev, [key]: value }));
    }

    const handleState = (e) => {
        setAddressData(prev => ({ ...prev, [e.target.name]: e.target.value }))
        handleStateChange(e);
    }

    // View Address By address Id
    useEffect(() => {
        setIsLoading(true);
        axios.post(`${api_url}/getAddressById`, { address_id: params.address_id })
            .then((res) => {
                setIsLoading(false);
                setAddressData(res.data.data);
                handleStateChange(null, res.data.data.state);
            })
            .catch((err) => {
                console.log("Address Not Found");
            })
    }, [])

    const handleAddressUpdate = (event) => {
        event.preventDefault();
        let errors = AddressValidation(addressData);
        setaddressErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            axios.post(`${api_url}/updateAddress`,
                addressData,
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            )
                .then(res => {
                    setIsLoading(false)
                    toast.success(res.data.message, {
                        autoClose: 2000,
                    });
                })
                .catch(err => {
                    toast.error('Axios Error 😭😭', {
                        autoClose: 2000,
                    });
                    setIsLoading(false)
                });
        }
    };

    return (
        <>
            {isLoading ? <Loading /> : null}
            <Header />
            <main id='main'>
                <section id="new_address" className="py-lg-5 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="h-100 d-flex align-items-center">
                                    <img src={require("../assets/images/address1.jpg")} className="d-block img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="border f-shadow">
                                    <div className="mx-auto">
                                        <p className="h4 p-3 bg-pink text-white m-0">Update Your Address</p>
                                    </div>
                                    <form action="" className="mx-auto p-4">
                                        <fieldset>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" name='name' onChange={handleInputs} value={addressData.name} id="username" placeholder="username" />
                                                        <label htmlFor="username">Name<span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="tel" className="form-control" name='phone_no' onChange={handleInputs} value={addressData.phone_no} id="phone" placeholder="phone" />
                                                        <label htmlFor="phone">Phone Number<span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.phone_no}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="tel" className="form-control" name='alternate_phone' onChange={handleInputs} value={addressData.alternate_phone} id="phone2" placeholder="phone" />
                                                        <label htmlFor="phone2">Alternate Phone Number<span className="text-danger"></span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.alternate_phone && addressErrors.alternate_phone}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <select className="form-select py-3 mb-3" onChange={(e) => handleState(e)} name='state' value={addressData.state} aria-label="Default select example">
                                                            <option value="Select State">--Select State--</option>
                                                            {
                                                                stateData.map((state, i) => {
                                                                    return (
                                                                        <option key={i} value={state.state_name}>{state.state_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <div className='text-danger s-font2'>{addressErrors.state}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <select name='district' value={addressData.district} onChange={handleInputs} className="form-select py-3" aria-label="Default select example">
                                                            <option >--Select District--</option>
                                                            {
                                                                districts.map((district, i) => {
                                                                    return (
                                                                        <option key={i} value={district}>{district}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <div className='text-danger s-font2'>{addressErrors.district}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" name='post_office' onChange={handleInputs} value={addressData.post_office} id="postoffice" placeholder="postoffice" />
                                                        <label htmlFor="postoffice">Post Office <span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.post_office}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" name='address' onChange={handleInputs} value={addressData.address} id="landmark" placeholder="landmark" />
                                                        <label htmlFor="landmark">Land Mark/House no./Building Name <span className="text-danger"></span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.address}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" name='village' onChange={handleInputs} value={addressData.village} id="villa-no" placeholder="villa-no" />
                                                        <label htmlFor="villa-no">Village/Ward No. <span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.village}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-floating mb-3">
                                                        <input type="text" className="form-control" name='city' onChange={handleInputs} value={addressData.city} id="city" placeholder="city" />
                                                        <label htmlFor="city">City <span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.city}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-floating mb-4">
                                                        <input type="text" className="form-control" name='pincode' onChange={handleInputs} value={addressData.pincode} id="pincode" placeholder="pincode" />
                                                        <label htmlFor="pincode">Pincode <span className="text-danger">*</span></label>
                                                        <div className='text-danger s-font2'>{addressErrors.pincode}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="d-flex justify-content-between">
                                            <span onClick={() => navigate(-1)} className="btn btn-outline-pink px-3 px-sm-4">Go Back</span>
                                            <button onClick={handleAddressUpdate} className="btn btn-pink px-4 px-sm-5">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Copyright />
            <ToastContainer />
        </>
    )
}
export default EditAddress