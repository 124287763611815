import React from 'react'
import { useGlobalContext } from '../contexts/GlobalContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const WishItem = (props) => {
    const { product_name, product_id, image, alt, discounted_price, mrp } = props;
    const { handleAddToCart, api_url, userId,setCartLength,setIsLoading,sessionType} = useGlobalContext();

    const moveToCart = async (e, pid) => {
        const cartData = {
            "user_id": userId,
            "product_id": pid,
            "session_type": sessionType,
            "qty": 1,
            "move_to_cart": 1,
        };

        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Removing"

        try {
            setIsLoading(true);
            const res = await axios.post(`${api_url}/addCartProduct`, cartData);
            if (res.data.success === 1) {
                toast.success(res.data.message, {
                    autoClose: 2000,
                })
                setCartLength((prev) => prev + 1);
                thisClicked.closest("#wishlist_item").remove();
            }
            else {
                toast.error(res.data.message, {
                    autoClose: 3000,
                })
                thisClicked.innerText = "Add to Cart"
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }
    const removeWishlistItem = async (e, product_id) => {
        const data = {
            user_id:userId,
            product_id:product_id
        }
        e.preventDefault();
        const thisClicked = e.currentTarget;
        thisClicked.innerText = "Removing"
        try {
            const res = await axios.post(`${api_url}/removeWishlistItem`,data);
            if (res.data.success === 1) {
                toast.warning(res.data.message, {
                    autoClose: 3000,
                    theme: 'colored'
                })
                thisClicked.closest("#wishlist_item").remove();
            } else {
                toast.error(res.data.message, {
                    autoClose: 3000
                })
                thisClicked.innerText = "Remove From List"
            }
        } catch (error) {
            toast.error('Axios Error', {
                autoClose: 3000
            });
        }
    }

    return (
        <>
            <div id='wishlist_item' className="mb-5">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-2 col-md-3">
                                <div className="wishlist-img-contain">
                                    <img src={image} className="d-block img-fluid mx-auto" alt={alt} />
                                </div>
                            </div>
                            <div className="col-10 col-md-6">
                                <div className="ms-5">
                                    <Link to={`/seeallfeature/${product_id}`} className="text-decoration-none text-dark"><h4>{product_name}</h4></Link>
                                    <p className='me-3'><del className='h5 text-secondary'>₹{mrp}</del><span className='h4'> ₹{discounted_price}</span></p>
                                    <button className="btn btn-pink rounded-5 px-5 mb-3 mt-4 mt-sm-0 w-100" onClick={(e) => moveToCart(e,product_id)}>Add to Cart</button>
                                    <button className="btn btn-outline-pink rounded-5 px-5 w-100" onClick={(e) => removeWishlistItem(e, product_id)}>Remove From List</button>
                                    {/* <p className="d-none d-lg-block">{description}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-lg-3">
                        <button className="btn btn-pink rounded-5 px-5 mb-3 mt-4 mt-sm-0 w-100" onClick={()=>handleAddToCart(product_id)}>Add to Cart</button>
                        <button className="btn btn-outline-pink rounded-5 px-5 w-100" onClick={() => removeWishlistItem(product_id)}>Remove from list</button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default WishItem