import React, { useEffect } from 'react'
import WishItem from '../Components/WishItem'
import Header from '../Components/Header'
import { useGlobalContext } from '../contexts/GlobalContext'
import Loading from '../Components/Loading'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'

const MyWishlist = () => {
  const { img_url, convertToPlain, getAllWishlistItems, wishlistData } = useGlobalContext();

  useEffect(() => {
    getAllWishlistItems();
  }, []);

  return (
    <>
      {/* {isLoading?<Loading/>:null} */}
      <Header />
      <main id='main'>
        <section id="wishlist">
          <div className="container-fluid px-sm-5">
            <div className='mt-2'>
              <Link to="/myprofile" className='text-decoration-none color-pink'>My Profile</Link>
              <span> <i class="bi bi-chevron-right f-sm"></i> My Wishlist</span>
            </div>
            <h4 className="my-4">Your wishlist</h4>
            {wishlistData.length !== 0 ?
              wishlistData.map((item, i) => {
                const { id, product_name, mrp, description, image, folder, alt } = item
                return (
                  <WishItem
                    key={i}
                    product_id={id}
                    image={`${img_url}/${folder}/${image}`}
                    alt={alt}
                    product_name={product_name}
                    mrp={mrp}
                    discounted_price={343}
                    description={convertToPlain(description)}
                  />
                )
              })
              :
              <div>
                {/* <div className='color-pink h5 mt-3'>Your Wishlist is Empty</div> */}
                <div className='row'>
                  <div className="col-md-9">
                    <img src={require('../assets/images/empty_wishlist.png')} className='img-fluid d-block mx-auto' alt="" />
                    <div className='text-center'>
                      <Link to={'/marketplace'} className='btn btn-pink py-2 px-3'>Add Items to Wishlist</Link>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
      </main>
      {/* <Footer/> */}
    </>
  )
}

export default MyWishlist