import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useGlobalContext } from '../contexts/GlobalContext';
import axios from 'axios';
import { Link, useLocation, useParams } from 'react-router-dom';
import Review from './Review';
import { toast } from 'react-toastify';
import { useOrderContext } from '../contexts/OrderContext';


const ProductDetails = ({ inventoryDetails, product_id, productReviews }) => {
    const { api_url, img_url, convertToHtml } = useGlobalContext();
    
    const { orders, getMyOrders } = useOrderContext();


    console.log("product id Product details: ", product_id)

    useEffect(() => {
        getMyOrders();
    }, []);
    
    // console.log("pdf", inventoryDetails.book_pdf)


    return (
        <>
            <section>
                <div className="container p-0">
                    <Tabs defaultIndex={0}>
                        <TabList>
                            <Tab id='tab_description'>Overview</Tab>
                            <Tab>Table of content</Tab>
                            <Tab>Additional information</Tab>
                            <Tab>Reviews ({productReviews.length})</Tab>
                            {
                                    inventoryDetails.book_pdf !==null && (
                                        <Tab>Book Pdf</Tab>
                                    )
                            }
                        </TabList>
                        <TabPanel>
                            <div className="px-3">
                                <div className="fs-5 mb-3" id="pdetails">Product Description</div>
                                {
                                    inventoryDetails && (
                                        inventoryDetails.description !== null && (<div dangerouslySetInnerHTML={{ __html: inventoryDetails.description }} />)
                                    )
                                }
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='px-3'>
                                <h6>Product Details</h6>
                                <ul>
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.author !== null && (<li>Author : {inventoryDetails.author}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.book_format !== null && (<li>Book Format: {inventoryDetails.book_format}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.stream !== null && (<li>Stream: {inventoryDetails.stream}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.printer_details !== null && (<li>Printer Details: {inventoryDetails.printer_details}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.publish_year !== null && (<li>Publish Year: {inventoryDetails.publish_year}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.brand_title !== null && (<li>Brand: {inventoryDetails.brand_title}</li>)
                                        )
                                    }
                                    {
                                        inventoryDetails && (
                                            inventoryDetails.youtube_url !== null && (<li>Youtube Url: {inventoryDetails.youtube_url}</li>)
                                        )
                                    }
                                </ul>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="px-3">
                                <table className="table table-striped">
                                    <tbody>
                                        {
                                            inventoryDetails && (
                                                inventoryDetails.class_title !== null && (
                                                    <tr>
                                                        <th scope="row">Classes</th>
                                                        <td>Class {inventoryDetails.class_title}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.isbn !== null && (
                                                    <tr>
                                                        <th scope="row">ISBN</th>
                                                        <td>{inventoryDetails.isbn}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.author !== null && (
                                                    <tr>
                                                        <th scope="row">Author</th>
                                                        <td>{inventoryDetails.author}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.edition !== null && (
                                                    <tr>
                                                        <th scope="row">Edition</th>
                                                        <td>{inventoryDetails.edition}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.net_weight !== null && (
                                                    <tr>
                                                        <th scope="row">Net Weight</th>
                                                        <td>{inventoryDetails.net_weight} grams</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {/* {
                                            inventoryDetails && (
                                                inventoryDetails.qty !== null && (
                                                    <tr>
                                                        <th scope="row">Quantity</th>
                                                        <td>{inventoryDetails.qty}</td>
                                                    </tr>
                                                )
                                            )
                                        } */}

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.min_qyt !== null && (
                                                    <tr>
                                                        <th scope="row">Min Quantity</th>
                                                        <td>{inventoryDetails.min_qyt}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.qty_unit !== null && (
                                                    <tr>
                                                        <th scope="row">Quantity Unit</th>
                                                        <td>{inventoryDetails.qty_unit}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.hsncode !== null && (
                                                    <tr>
                                                        <th scope="row">HSN Code</th>
                                                        <td>{inventoryDetails.hsncode}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.barcode !== null && (
                                                    <tr>
                                                        <th scope="row">Barcode</th>
                                                        <td>{inventoryDetails.barcode}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.mrp !== null && (
                                                    <tr>
                                                        <th scope="row">Mrp</th>
                                                        <td>₹{inventoryDetails.mrp}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.discount !== null && (
                                                    <tr>
                                                        <th scope="row">Discount %</th>
                                                        <td>{inventoryDetails.discount}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.discounted_price !== null && (
                                                    <tr>
                                                        <th scope="row">Discounted Price</th>
                                                        <td>₹{inventoryDetails.discounted_price}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.sales_price !== null && (
                                                    <tr>
                                                        <th scope="row">Sales Price</th>
                                                        <td>₹{inventoryDetails.sales_price}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.gst_title !== null && (
                                                    <tr>
                                                        <th scope="row">GST %</th>
                                                        <td>{inventoryDetails.gst_title}</td>
                                                    </tr>
                                                )
                                            )
                                        }

                                        {
                                            inventoryDetails && (
                                                inventoryDetails.shipping_charges !== null && (
                                                    <tr>
                                                        <th scope="row">Shipping Charges</th>
                                                        <td>₹{inventoryDetails.shipping_charges}</td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            {
                                productReviews.length !== 0 ?
                                    <div className="border rounded p-3">
                                        <div className="card-title fs-normal fs-5 mb-3">Product Ratings and Reviews</div>
                                        <hr />
                                        <p>Real Images and videos from customers</p>
                                        <div className="d-flex flex-wrap gap-2 justify-content-start">
                                            <div className="review-img-contain">
                                                <img src="../assets/images/review_images/img1.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                            </div>
                                            <div className="review-img-contain">
                                                <img src="../assets/images/review_images/img2.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                            </div>
                                            <div className="review-img-contain">
                                                <img src="../assets/images/review_images/img3.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                            </div>
                                            <div className="review-img-contain">
                                                <img src="../assets/images/review_images/img4.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                            </div>
                                            <div className="review-img-contain">
                                                <img src="../assets/images/review_images/img5.jpg" className="d-block img-fluid mx-auto rounded" alt="" />
                                            </div>
                                        </div>
                                        <hr />

                                        {
                                            productReviews.slice(0, 2).map((review, i) => {
                                                return (
                                                    <Review
                                                        key={i}
                                                        img_url = {img_url}
                                                        review_image = {review.image}
                                                        rating={review.rating}
                                                        user_name={review.user_name}
                                                        review_comment={review.review_comment}
                                                    />
                                                )
                                            })
                                        }
                                        <div className="mt-2 view-all-products"><Link to="" className="fs-5 text-decoration-none" data-bs-toggle="offcanvas" data-bs-target="#review-offcanvas">View All Reviews <i className="bi bi-chevron-right"></i></Link></div>
                                    </div>
                                    :
                                    <>
                                        <div className='text-danger h4 p-3'>No Reviews Yet</div>
                                        {
                                            orders?.order_status === 4 &&
                                            <Link to={'/givereview'} state={{ 'product_id': inventoryDetails.product_id, 'item_type': inventoryDetails.item_type, 'vendor_id': inventoryDetails.vendor_id }} className='px-3'><button className='btn btn-danger'>Give Review</button></Link>
                                        }
                                    </>
                            }
                            {/* <!-- offcanva --> */}
                            <div className="offcanvas offcanvas-end" id="review-offcanvas">
                                <div className="offcanvas-header">
                                    <h4>Product Reviews</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
                                </div>
                                <div className="offcanvas-body">
                                    {
                                        productReviews.map((review, i) => {
                                            return (
                                                <Review
                                                    key={i}
                                                    rating={review.rating}
                                                    user_name={review.user_name}
                                                    review_comment={review.review_comment}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </TabPanel>
                        {
                            inventoryDetails && (
                                inventoryDetails.book_pdf !== null &&
                                
                                <TabPanel>
                                    <div className="mt-4">
                                        <div className="card-title fs-normal fs-5 mb-3 mt-4">PDF View of the book</div>
                                        <div>
                                            <iframe title='pdf review' toolbar="0" src={`${img_url}/products/${inventoryDetails.book_pdf}`} width="100%" height="600"></iframe>
                                        </div>
                                    </div>
                                </TabPanel>
                            )
                        }
                    </Tabs>
                </div>
            </section>
        </>
    )
}

export default ProductDetails