import React from 'react'
import { Link} from 'react-router-dom'

const ProfileCards = (props) => {
    const { imgsrc, title, text, link } = props;
    return (
        <>
            <div className="col-md-4">
                <Link to={`/${link}`} className="text-decoration-none text-dark"> 
                    <div className="zoom-2 zoom-2 b-shadow rounded p-3 h-100">
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <img src={require(`../assets/images/account_img/${imgsrc}`)} className="d-block img-fluid me-2" height="100" width="100" alt="" />
                                </div>
                            </div>
                            <div className="col-9">
                                <p className="h4">{title}</p>
                                <p>{text}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default ProfileCards